import React from "react";
import { FirestoreCollection } from "react-firestore";

import AdminWrapper from "../../../components/admin/AdminWrapper";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

import { LinkContainer } from "react-router-bootstrap";

import FirebaseAuth from "../../misc/FirebaseAuth";
import Error from "../../misc/Error";
import updatePage from "../../../actions/pages/updatePage";
import PageForm from "./PageForm";

const PageEdit = ({ match, history }) => (
  <AdminWrapper>
    <Row className="admin-page-header">
      <Col md={10} className="admin-page-title">
        <h1>Edit page</h1>
      </Col>
      <Col md={2} className="admin-action-buttons">
        <LinkContainer to="#">
          <Button variant="danger">Delete</Button>
        </LinkContainer>
      </Col>
    </Row>
    <Row>
      <Col md={12}>
        <FirestoreCollection
          path={"pages"}
          filter={["slug", "==", match.params.slug]}
        >
          {({ error, isLoading, data }) => {
            if (error) {
              return <Error error={error} />;
            }

            if (isLoading) {
              return <p>loading...</p>;
            }

            if (data.length === 0) {
              return <Error />;
            }

            const page = data[0];

            return (
              <PageForm
                page={page}
                onSubmit={(values) =>
                  updatePage(page.id, values).then(() =>
                    history.push(`/admin/pages/`)
                  )
                }
              />
            );
          }}
        </FirestoreCollection>
      </Col>
    </Row>
  </AdminWrapper>
);

export default PageEdit;
