import React from "react";
import { FirestoreCollection, Firestore } from "react-firestore";
import storage from "../../../index";

import { BrowserView, MobileView } from "react-device-detect";

import { connect } from "react-redux";
import { showProduct } from "../../../actions/shoppingCartActions";

import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Badge from "react-bootstrap/Badge";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShoppingCart, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

import Error from "../../../views/misc/Error";

class ProductListContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showCategory: null,
    };
  }

  getImage = (productId, imageName) => {
    let image;

    if (imageName) {
      image = imageName;
      let fileFormat = image.slice(image.length - 4, image.length);
      image = image.slice(0, image.length - 4);
      image += "_512x512" + fileFormat;
    } else {
      image = "default-product-image_512x512.jpg";
    }

    storage
      .ref(`images/thumbs/${image}`)
      .getDownloadURL()
      .then(function (url) {
        if (url != null) {
          var img = document.getElementById(productId);
          img.src = url;
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  handleProductModalShow = (product) => {
    this.props.showProduct(product);
  };

  compareValues(key, order = "asc") {
    // nice function that allows sorting of arrays of objects, either text or number based and ascending or descending results.
    // source: https://www.sitepoint.com/sort-an-array-of-objects-in-javascript/#:~:text=Basic%20Array%20Sorting,in%20Unicode%20code%20point%20order.
    return function innerSort(a, b) {
      if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
        // property doesn't exist on either object
        return 0;
      }

      const varA = typeof a[key] === "string" ? a[key].toUpperCase() : a[key];
      const varB = typeof b[key] === "string" ? b[key].toUpperCase() : b[key];

      let comparison = 0;
      if (varA > varB) {
        comparison = 1;
      } else if (varA < varB) {
        comparison = -1;
      }
      return order === "desc" ? comparison * -1 : comparison;
    };
  }

  changeCategory = (event) => {
    this.setState({
      showCategory: event.target.value,
    });
  };

  render() {
    return (
      <Container className="website-content pb-5">
        <FirestoreCollection path={"webshop_categories"} sort={"order"}>
          {({ error, isLoading, data }) => {
            if (error) {
              return <Error error={error} />;
            }

            if (isLoading) {
              return <p>loading...</p>;
            }

            if (data.length === 0) {
              return <p>No products yet!</p>;
            }

            let navcount = 0;
            let tabcount = 0;

            return (
              <Firestore
                render={({ firestore }) => {
                  return (
                    <>
                      <BrowserView>
                        <Tab.Container
                          id="webshop-tabs"
                          defaultActiveKey="category-0"
                        >
                          <Row>
                            <Col>
                              <Nav variant="pills">
                                {data.map((category) => (
                                  <Nav.Item
                                    key={category.id}
                                    className="public-webshop-category mx-1"
                                  >
                                    <Nav.Link
                                      eventKey={"category-" + navcount++}
                                    >
                                      {category.title}
                                    </Nav.Link>
                                  </Nav.Item>
                                ))}
                              </Nav>
                            </Col>
                            <Col className="text-right">
                              <Button
                                onClick={() => this.props.showCart()}
                                className="nav-button public-webshop-shopping-cart-button"
                              >
                                <span className="px-1">
                                  <FontAwesomeIcon icon={faShoppingCart} />
                                </span>
                                <span className="px-1">
                                  <Badge variant="light">
                                    {this.props.items}
                                  </Badge>
                                </span>
                              </Button>
                            </Col>
                          </Row>
                          <Row>
                            <Tab.Content className="w-100 mt-3">
                              {data
                                .sort(this.compareValues("order"))
                                .map((category) => (
                                  <FirestoreCollection
                                    key={category.id}
                                    path={"webshop_products"}
                                    filter={[
                                      "category",
                                      "==",
                                      firestore
                                        .collection("webshop_categories")
                                        .doc(category.id),
                                    ]}
                                  >
                                    {({ error, isLoading, data }) => {
                                      if (error) {
                                        return <Error error={error} />;
                                      }

                                      if (isLoading) {
                                        return <p>loading...</p>;
                                      }

                                      if (data.length === 0) {
                                        return <p>Geen producten in deze</p>;
                                      }

                                      return (
                                        <Tab.Pane
                                          eventKey={"category-" + tabcount++}
                                        >
                                          <Container>
                                            <Row>
                                              {data
                                                .sort(
                                                  this.compareValues("order")
                                                )
                                                .map((product) =>
                                                  product.published ? (
                                                    <Col
                                                      md={4}
                                                      key={product.id}
                                                    >
                                                      <a
                                                        style={
                                                          product.stocked &&
                                                          product.stock < 1
                                                            ? null
                                                            : {
                                                                cursor:
                                                                  "pointer",
                                                              }
                                                        }
                                                        onClick={
                                                          product.stocked &&
                                                          product.stock < 1
                                                            ? null
                                                            : () =>
                                                                this.handleProductModalShow(
                                                                  product
                                                                )
                                                        }
                                                      >
                                                        <Card
                                                          className={
                                                            product.stocked &&
                                                            product.stock < 1
                                                              ? `public-webshop-card sold-out`
                                                              : `public-webshop-card`
                                                          }
                                                        >
                                                          <Card.Img
                                                            variant="top"
                                                            image={this.getImage(
                                                              product.id,
                                                              product.image
                                                            )}
                                                            id={product.id}
                                                          />

                                                          <Card.Body>
                                                            <Card.Title className="public-webshop-card-title">
                                                              {product.name}
                                                            </Card.Title>
                                                            <Card.Text className="public-webshop-card-description">
                                                              {
                                                                product.description
                                                              }
                                                            </Card.Text>
                                                            <div
                                                              className={
                                                                product.stocked &&
                                                                product.stock <
                                                                  1
                                                                  ? `public-webshop-card-price sold-out`
                                                                  : `public-webshop-card-price`
                                                              }
                                                            >
                                                              {product.stocked &&
                                                              product.stock < 1
                                                                ? `Uitverkocht`
                                                                : `€ 
                                                  ${(
                                                    Math.round(
                                                      product.price * 100
                                                    ) / 100
                                                  ).toFixed(2)}`}
                                                            </div>
                                                          </Card.Body>
                                                        </Card>
                                                      </a>
                                                    </Col>
                                                  ) : /* IF not published, don't show..*/
                                                  null
                                                )}
                                            </Row>
                                          </Container>
                                        </Tab.Pane>
                                      );
                                    }}
                                  </FirestoreCollection>
                                ))}
                            </Tab.Content>
                          </Row>
                        </Tab.Container>
                      </BrowserView>
                      {/*MOBLE VIEW - no tabs - runs on state..*/}
                      <MobileView>
                        <Row>
                          <Col className={`text-right mb-2`}>
                            <Button
                              onClick={() => this.props.showCart()}
                              className="nav-button public-webshop-shopping-cart-button"
                            >
                              <span className="px-1">
                                <FontAwesomeIcon icon={faShoppingCart} />
                              </span>
                              <span className="px-1">
                                <Badge variant="light">
                                  {this.props.items}
                                </Badge>
                              </span>
                              <span className="px-2">
                                {"€"}
                                {this.props.price}
                              </span>
                            </Button>
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col>
                            <Form.Label>Selecteer een categorie:</Form.Label>
                            <Form.Control
                              as="select"
                              custom
                              onChange={this.changeCategory}
                            >
                              {data.map((category) => (
                                <option key={category.id} value={category.id}>
                                  {category.title}
                                </option>
                              ))}
                            </Form.Control>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <FirestoreCollection
                              path={"webshop_products"}
                              filter={[
                                "category",
                                "==",
                                firestore
                                  .collection("webshop_categories")
                                  .doc(
                                    this.state.showCategory
                                      ? this.state.showCategory
                                      : data[0].id
                                  ),
                              ]}
                            >
                              {({ error, isLoading, data }) => {
                                if (error) {
                                  return <Error error={error} />;
                                }

                                if (isLoading) {
                                  return <p>loading...</p>;
                                }

                                if (data.length === 0) {
                                  return <p>Geen producten in deze</p>;
                                }

                                return (
                                  <Container>
                                    <Row>
                                      {data
                                        .sort(this.compareValues("order"))
                                        .map((product) =>
                                          product.published ? (
                                            <Col md={4} key={product.id}>
                                              <a
                                                style={
                                                  product.stocked &&
                                                  product.stock < 1
                                                    ? null
                                                    : {
                                                        cursor: "pointer",
                                                      }
                                                }
                                                onClick={
                                                  product.stocked &&
                                                  product.stock < 1
                                                    ? null
                                                    : () =>
                                                        this.handleProductModalShow(
                                                          product
                                                        )
                                                }
                                              >
                                                <Card
                                                  className={
                                                    product.stocked &&
                                                    product.stock < 1
                                                      ? `public-webshop-card sold-out`
                                                      : `public-webshop-card`
                                                  }
                                                >
                                                  <Card.Img
                                                    variant="top"
                                                    image={this.getImage(
                                                      product.id,
                                                      product.image
                                                    )}
                                                    id={product.id}
                                                  />

                                                  <Card.Body>
                                                    <Card.Title className="public-webshop-card-title">
                                                      {product.name}
                                                    </Card.Title>
                                                    <Card.Text className="public-webshop-card-description">
                                                      {product.description}
                                                    </Card.Text>
                                                    <div
                                                      className={
                                                        product.stocked &&
                                                        product.stock < 1
                                                          ? `public-webshop-card-price sold-out`
                                                          : `public-webshop-card-price`
                                                      }
                                                    >
                                                      {product.stocked &&
                                                      product.stock < 1
                                                        ? `Uitverkocht`
                                                        : `€ 
                                                  ${(
                                                    Math.round(
                                                      product.price * 100
                                                    ) / 100
                                                  ).toFixed(2)}`}
                                                    </div>
                                                  </Card.Body>
                                                </Card>
                                              </a>
                                            </Col>
                                          ) : /* IF not published, don't show..*/
                                          null
                                        )}
                                    </Row>
                                  </Container>
                                );
                              }}
                            </FirestoreCollection>
                          </Col>
                        </Row>
                      </MobileView>
                    </>
                  );
                }}
              />
            );
          }}
        </FirestoreCollection>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {};

const mapDispatchToProps = (dispatch) => {
  return {
    showProduct: (product) => {
      dispatch(showProduct(product));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductListContainer);
