import React from "react";
import { FirestoreCollection } from "react-firestore";
import { LinkContainer } from "react-router-bootstrap";

import AdminWrapper from "../../../../components/admin/AdminWrapper";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
import Badge from "react-bootstrap/Badge";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrashAlt,
  faEllipsisH,
  faEye,
  faEyeSlash,
  faLongArrowAltLeft,
} from "@fortawesome/free-solid-svg-icons";

import Error from "../../../misc/Error";
import updateProduct from "../../../../actions/webshop/product/updateProduct";
import deleteProduct from "../../../../actions/webshop/product/deleteProduct";
import ProductForm from "./ProductForm";

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <Button
    variant={`light`}
    className="more-btn"
    href=""
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
    <FontAwesomeIcon icon={faEllipsisH} />
  </Button>
));

const ProductEdit = ({ match, history }) => (
  <AdminWrapper>
    <FirestoreCollection
      path={"webshop_products"}
      filter={["slug", "==", match.params.slug]}
    >
      {({ error, isLoading, data }) => {
        if (error) {
          return <Error error={error} />;
        }

        if (isLoading) {
          return <p>loading...</p>;
        }

        if (data.length === 0) {
          return <Error />;
        }

        const product = data[0];

        return (
          <>
            <Row className="admin-page-header">
              <Col md={10} className="admin-page-title text-left">
                <Row>
                  <Col md="auto" className="d-flex align-items-center">
                    <Button variant="light" onClick={() => history.goBack()}>
                      <FontAwesomeIcon icon={faLongArrowAltLeft} />
                    </Button>
                  </Col>
                  <Col>
                    <h1>Edit Product</h1>
                  </Col>
                </Row>
              </Col>
              <div className="d-flex align-content-center flex-wrap">
                <Col className="text-right d-flex align-content-center flex-wrap">
                  {product.published ? (
                    <Badge variant="success">Published</Badge>
                  ) : (
                    <Badge variant="danger">Hidden</Badge>
                  )}
                </Col>
                <Col className="admin-action-buttons text-right">
                  <Dropdown>
                    <Dropdown.Toggle
                      as={CustomToggle}
                      id="dropdown-custom-components"
                    ></Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() =>
                          deleteProduct(product).then(() =>
                            history.push(`../products`)
                          )
                        }
                      >
                        <FontAwesomeIcon icon={faTrashAlt} /> Delete
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>
              </div>
            </Row>
            <Row>
              <Col md={12}>
                <ProductForm
                  context="edit"
                  product={product}
                  onSubmit={(values) =>
                    updateProduct(product.id, values).then(() =>
                      history.push(`../../products/`)
                    )
                  }
                />
              </Col>
            </Row>
          </>
        );
      }}
    </FirestoreCollection>
  </AdminWrapper>
);

export default ProductEdit;
