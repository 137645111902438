import Firebase from "firebase/app";
import ReactGA from "react-ga";

import { prepareDocForUpdate } from "../../helpers/firestoreHelpers";

const updateCategory = (categoryId, values) => {
  ReactGA.event({
    category: "Webshop - Categories",
    action: "Update category"
  });

  return Firebase.firestore()
    .collection("webshop_categories")
    .doc(categoryId)
    .update(prepareDocForUpdate(values))
    .catch(error => {
      alert(`Whoops, couldn't edit the category: ${error.message}`);
    });
};

export default updateCategory;
