import React from "react";

import PublicWrapper from "../../../components/public/PublicWrapper";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const Disclaimer = () => {
  return (
    <PublicWrapper>
      <div id="disclaimer" className="page-background">
        <Container className="website-content">
          <Row className="legal-content">
            <Col></Col>
            <Col xs={10}>
              <h1>DISCLAIMER</h1>
              <p>
                Laatst bijgewerkt: <strong>03 juni 2020</strong>
              </p>
              <p>
                Op het gebruik van deze website (www.visbarbeet.nl) zijn
                onderstaande gebruiksvoorwaarden van toepassing. Door gebruik te
                maken van deze website, wordt u geacht kennis te hebben genomen
                van de gebruiksvoorwaarden en deze te hebben aanvaard.
              </p>
              <h4>Gebruik van informatie</h4>
              <p>
                Visbar Beet streeft ernaar op deze website altijd juiste en
                actuele informatie aan te bieden. Hoewel deze informatie met de
                grootst mogelijke zorgvuldigheid is samengesteld, staat Visbar
                Beet niet in voor de volledigheid, juistheid of actualiteit van
                de informatie. De juridische informatie op de website is van
                algemene aard en kan niet worden beschouwd als een vervanging
                van juridisch advies.
              </p>
              <p>
                Aan de informatie kunnen geen rechten worden ontleend. Visbar
                Beet aanvaardt geen aansprakelijkheid voor schade die
                voortvloeit uit het gebruik van de informatie of de website en
                evenmin voor het niet goed functioneren van de website.
                <br /> Op basis van het verzenden en ontvangen van informatie
                via de website of via e-mail kan niet zonder meer een relatie
                tussen Visbar Beet en de gebruiker van de website ontstaan.
              </p>
              <h4>E-mail</h4>
              <p>
                Visbar Beet garandeert niet dat aan haar gezonden e-mails
                (tijdig) worden ontvangen of verwerkt, omdat tijdige ontvangst
                van e-mails niet kan worden gegarandeerd. Ook de veiligheid van
                het e-mailverkeer kan niet volledig worden gegarandeerd door de
                hieraan verbonden veiligheidsrisico&rsquo;s. Door zonder
                encryptie of wachtwoordbeveiliging per e-mail met Visbar Beet te
                corresponderen, accepteert u dit risico.
              </p>
              <h4>Hyperlinks</h4>
              <p>
                Deze website kan hyperlinks bevatten naar websites van derden.
                Visbar Beet heeft geen invloed op websites van derden en is niet
                verantwoordelijk voor de beschikbaarheid of inhoud daarvan.
                Visbar Beet aanvaardt dan ook geen aansprakelijkheid voor schade
                die voortvloeit uit het gebruik van websites van derden.
              </p>
              <h4>Intellectuele eigendomsrechten</h4>
              <p>
                Alle publicaties en uitingen van Visbar Beet zijn beschermd door
                auteursrecht en andere intellectuele eigendomsrechten. Behalve
                voor persoonlijk en niet commercieel gebruik, mag niets uit deze
                publicaties en uitingen op welke manier dan ook verveelvoudigd,
                gekopieerd of op een andere manier openbaar worden gemaakt,
                zonder dat Visbar Beet daar vooraf schriftelijke toestemming
                voor heeft gegeven.
              </p>
            </Col>
            <Col></Col>
          </Row>
        </Container>
      </div>
    </PublicWrapper>
  );
};

export default Disclaimer;
