import React from "react";
import { FirestoreCollection } from "react-firestore";

import AdminWrapper from "../../../components/admin/AdminWrapper";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Error from "../../misc/Error";

const Page = ({ match }) => (
  <AdminWrapper>
    <Row>
      <Col md={12}>
        <FirestoreCollection
          path={"pages"}
          filter={["slug", "==", match.params.slug]}
        >
          {({ error, isLoading, data }) => {
            if (error) {
              return <Error error={error} />;
            }

            if (isLoading) {
              return <p>loading...</p>;
            }

            if (data.length === 0) {
              return <Error />;
            }

            const page = data[0];

            return (
              <div>
                <h1>{page.title}</h1>
                <p>{page.description}</p>
              </div>
            );
          }}
        </FirestoreCollection>
      </Col>
    </Row>
  </AdminWrapper>
);

export default Page;
