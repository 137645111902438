import {
  STEP_BACK,
  SUBMIT_ADDRESS,
  SUBMIT_DELIVERY,
  SUBMIT_PAYMENT,
} from "./action-types/checkout-actions";

export const stepBack = () => {
  return {
    type: STEP_BACK,
  };
};

export const submitAddress = (client, invoiceAddress, shippingAddress) => {
  return {
    type: SUBMIT_ADDRESS,
    client,
    invoiceAddress,
    shippingAddress,
  };
};

export const submitDelivery = (delivery, location) => {
  return {
    type: SUBMIT_DELIVERY,
    delivery,
    location,
  };
};

export const submitPaymentMethod = (paymentMethod) => {
  return {
    type: SUBMIT_PAYMENT,
    paymentMethod,
  };
};
