import Firebase from "firebase/app";
import ReactGA from "react-ga";
import slugify from "slugify";

import { prepareDocForCreate } from "../../helpers/firestoreHelpers";

const createSupplement = (values) => {
  ReactGA.event({
    supplement: "Webshop - Supplements",
    action: "Create supplement",
  });

  values.slug = slugify(values.name, { lower: true });

  return Firebase.firestore()
    .collection("webshop_supplements")
    .add(prepareDocForCreate(values))
    .then(() => values)
    .catch((error) => {
      alert(`Whoops, couldn't create the page: ${error.message}`);
    });
};

export default createSupplement;
