import React from "react";
import { LinkContainer } from "react-router-bootstrap";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

class CheckoutConfirmForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      waitingForPayment: false,
      agreedToTerms: false,
    };
  }

  componentDidMount() {
    const stripe = window.Stripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
    const paymentBtn = document.getElementById("stripe-payment-btn");
    var sessionId;

    if (!this.props.order.length < 1) {
      paymentBtn.addEventListener("click", () => {
        var body = {
          delivery: this.props.delivery,
          location: this.props.location,
          client: this.props.client,
          invoiceAddress: this.props.invoiceAddress,
          shippingAddress: this.props.shippingAddress,
          order: this.props.order,
          paymentMethod: this.props.paymentMethod,
        };

        this.setState({
          waitingForPayment: true,
        });

        fetch(
          `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URL}/checkoutSession/`,
          {
            //mode: `cors`,
            method: "POST",
            body: JSON.stringify(body),
          }
        )
          .then((response) => response.json())
          .then((data) => (sessionId = data.id))
          .then((sessionId) => {
            stripe
              .redirectToCheckout({
                sessionId: sessionId,
              })
              .then(function (result) {
                this.setState({
                  waitingForPayment: false,
                });
                return result.error.message;
              });
          });
      });
    }
  }

  paymentMethodText = (paymentMethod) => {
    switch (paymentMethod) {
      case `ideal`: {
        return "Ideal";
      }
      case `card`: {
        return "Creditcard";
      }
    }
  };

  agreedToTerms = () => {
    this.setState({
      agreedToTerms: !this.state.agreedToTerms,
    });
  };

  render() {
    return (
      <Form>
        <Card.Body>
          <Row>
            <Col md={4} className="text-right">
              <strong>Type bestelling:</strong>
            </Col>
            <Col md={8}>
              {this.props.delivery ? "Thuis bezorgen" : "Ophalen"}
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md={4} className="text-right">
              <strong>Jouw gegevens:</strong>
            </Col>
            <Col md={8}>
              <ul className="list-unstyled">
                <li>
                  {this.props.client.firstname && this.props.client.lastname
                    ? `${this.props.client.firstname} ${this.props.client.lastname}`
                    : null}
                </li>
                <li>
                  {this.props.client.email
                    ? `${this.props.client.email}`
                    : null}
                </li>
                <li>
                  {this.props.client.phone
                    ? `${this.props.client.phone}`
                    : null}
                </li>
              </ul>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md={4} className="text-right">
              <strong>Factuur adres:</strong>
            </Col>
            <Col md={8}>
              <ul className="list-unstyled">
                <li>
                  {this.props.invoiceAddress.street &&
                  this.props.invoiceAddress.number
                    ? `${this.props.invoiceAddress.street} ${this.props.invoiceAddress.number} ${this.props.invoiceAddress.numberAddon}`
                    : null}
                </li>
                <li>
                  {this.props.invoiceAddress.postcode &&
                  this.props.invoiceAddress.city
                    ? `${this.props.invoiceAddress.postcode} ${this.props.invoiceAddress.city}`
                    : null}
                </li>
              </ul>
            </Col>
          </Row>
          {this.props.delivery ? (
            <Row className="mt-3">
              <Col md={4} className="text-right">
                <strong>Bezorg adres:</strong>
              </Col>
              <Col md={8}>
                <ul className="list-unstyled">
                  <li>
                    {this.props.shippingAddress.street &&
                    this.props.shippingAddress.number
                      ? `${this.props.shippingAddress.street} ${this.props.shippingAddress.number} ${this.props.shippingAddress.numberAddon}`
                      : null}
                  </li>
                  <li>
                    {this.props.shippingAddress.postcode &&
                    this.props.shippingAddress.city
                      ? `${this.props.shippingAddress.postcode} ${this.props.shippingAddress.city}`
                      : null}
                  </li>
                </ul>
              </Col>
            </Row>
          ) : (
            <Row className="mt-3">
              <Col md={4} className="text-right">
                <strong>Ophalen:</strong>
              </Col>
              <Col md={8}>
                {this.props.location === "beet" ? (
                  <p>
                    Visbar Beet <br />
                    Dapperstraat 26, Amsterdam
                  </p>
                ) : (
                  <p>
                    Bar Baarsch <br />
                    Jan Evertsenstraat 91, Amsterdam
                  </p>
                )}
              </Col>
            </Row>
          )}
          <Row className="mt-3">
            <Col md={4} className="text-right">
              <strong>Betaalmethode:</strong>
            </Col>
            <Col md={8}>{this.paymentMethodText(this.props.paymentMethod)}</Col>
          </Row>
        </Card.Body>
        <Card.Footer className="checkout-footer">
          <Row>
            <Col className="text-right mb-2">
              <Form.Check
                type="checkbox"
                inline
                className="mr-2"
                onClick={this.agreedToTerms}
              />
              {"Ik ga akkoord met de "}
              <LinkContainer to="/terms-and-conditions" target="_blank">
                <a>algemene voorwaarden</a>
              </LinkContainer>
              {" en "}
              <LinkContainer to="/privacy" target="_blank">
                <a>privacy voorwaarden</a>
              </LinkContainer>
            </Col>
          </Row>

          <Row>
            <Col className="text-left">
              <Button
                variant="light"
                onClick={this.props.back}
                disabled={this.state.waitingForPayment}
              >
                Vorige
              </Button>
            </Col>

            <Col className="text-right">
              {this.props.order.length < 1 ? (
                <p className="text-danger">Niets in winkelmand.</p>
              ) : (
                <Button
                  id="stripe-payment-btn"
                  className="payment-btn"
                  disabled={
                    this.state.waitingForPayment ||
                    !this.state.agreedToTerms ||
                    this.props.order.length < 1
                  }
                >
                  {this.state.waitingForPayment ? (
                    <FontAwesomeIcon icon={faSpinner} spin />
                  ) : (
                    `Betalen`
                  )}
                </Button>
              )}
            </Col>
          </Row>
        </Card.Footer>
      </Form>
    );
  }
}

export default CheckoutConfirmForm;
