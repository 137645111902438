import React from "react";
import { FirestoreCollection } from "react-firestore";

import AdminWrapper from "../../../../components/admin/AdminWrapper";

import { LinkContainer } from "react-router-bootstrap";

import updateOrderStatus from "../../../../actions/updateOrderStatus";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Badge from "react-bootstrap/Badge";
import Modal from "react-bootstrap/Modal";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import Table from "react-bootstrap/Table";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTruck,
  faStore,
  faPrint,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";

import Error from "../../../misc/Error";

const OrderTypeElement = (order) => {
  if (order.type == "delivery") {
    return <FontAwesomeIcon icon={faTruck} />;
  } else {
    return <FontAwesomeIcon icon={faStore} />;
  }
};

const OrderStatusElement = (order) => {
  switch (order.status) {
    case "open": {
      return <Badge variant="warning">open</Badge>;
    }
    case "in-progress": {
      return <Badge variant="primary">in behandeling</Badge>;
    }
    case "done": {
      return <Badge variant="success">voltooid</Badge>;
    }
    default: {
      return null;
    }
  }
};

const OrderCard = (order) => {
  return (
    <Card.Body>
      <Row className="">
        <Col className="">
          <h5 className="order-id">
            <Badge variant="secondary">{order.id}</Badge>
          </h5>
        </Col>
        <Col className="col-auto">{order.date}</Col>
        <Col className="">{order.client}</Col>
        <Col className="">
          <OrderTypeElement type={order.type} />
          {order.type === "delivery"
            ? `  ${order.address.street} ${order.address.number}`
            : order.location != null
            ? order.location === "beet"
              ? "  Visbar Beet"
              : "  Bar Baarsch"
            : "  Visbar Beet"}
        </Col>
        <Col className="col-xs-2 text-right">
          <OrderStatusElement status={order.status} />
        </Col>
      </Row>
    </Card.Body>
  );
};

const OrderModal = (props) => {
  let productCount = 1;
  let orderSumTotal = 0;

  return (
    <Modal
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={props.show}
      onHide={props.handleClose}
    >
      <Modal.Header closeButton={false}>
        <Col>
          <Modal.Title>Order details</Modal.Title>
        </Col>
        <Col className="text-right">
          <Button variant="light" onClick={props.handleClose}>
            <FontAwesomeIcon icon={faTimes} />
          </Button>
        </Col>
        <Modal.Title></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row className="order-basic-info">
            <Col xs={6}>
              <Table className="order-info-table" borderless size="sm">
                <tbody>
                  <tr>
                    <th className="table-header-40">Nummer</th>
                    <td>
                      <Badge className="order-id" variant="secondary">
                        {props.order.id}
                      </Badge>
                    </td>
                  </tr>
                  <tr>
                    <th className="table-header-40">Datum</th>
                    <td>
                      {new Date(
                        props.order.created.seconds * 1000
                      ).toLocaleString()}
                    </td>
                  </tr>
                  <tr>
                    <th className="table-header-40">Status</th>
                    <td>{props.order.orderStatus}</td>
                  </tr>
                  <tr>
                    <th className="table-header-40">Betaalmethode</th>
                    <td>{props.order.paymentMethod}</td>
                  </tr>
                  <tr>
                    <th className="table-header-40">Betaling status</th>
                    <td>
                      {props.order.paymentCompleted ? "Voldaan" : "Mislukt"}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
            <Col xs={6}>
              <Table className="order-info-table" borderless size="sm">
                <tbody>
                  <tr>
                    <th className="table-header-40">Klant</th>
                    <td>
                      {props.order.client.firstname}{" "}
                      {props.order.client.lastname}
                    </td>
                  </tr>
                  <tr>
                    <th className="table-header-40">Email</th>
                    <td>
                      <a href={`mailto:${props.order.client.email}`}>
                        {props.order.client.email}
                      </a>
                    </td>
                  </tr>
                  {props.order.client.phone ? (
                    <tr>
                      <th className="table-header-40">Telefoon</th>
                      <td>
                        <a href={`tel:${props.order.client.phone}`}>
                          {props.order.client.phone}
                        </a>
                      </td>
                    </tr>
                  ) : null}
                  <tr>
                    <th className="table-header-40">Factuur adres</th>
                    <td>
                      {props.order.invoiceAddress.street}{" "}
                      {props.order.invoiceAddress.number}{" "}
                      {props.order.invoiceAddress.numberAddon} <br />
                      {props.order.invoiceAddress.postcode}{" "}
                      {props.order.invoiceAddress.city}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
          <hr />
          <Row className="order-type-info">
            <Col xs={6}>
              <Table className="order-info-table" borderless size="sm">
                <tbody>
                  <tr>
                    <th className="table-header-40">Type bestelling</th>
                    <td>
                      {props.order.type === `delivery`
                        ? `Bezorging`
                        : props.order.location != null
                        ? props.order.location === "beet"
                          ? "Ophalen @ Visbar Beet"
                          : "Ophalen @ Bar Baarsch"
                        : "Ophalen @ Visbar Beet"}
                    </td>
                  </tr>
                  {props.order.type === `delivery` ? (
                    <tr>
                      <th className="table-header-40">Bezorg adres</th>
                      <td>
                        {props.order.shippingAddres.street}{" "}
                        {props.order.shippingAddres.number}{" "}
                        {props.order.shippingAddres.numberAddon} <br />
                        {props.order.shippingAddres.postcode}{" "}
                        {props.order.shippingAddres.city}
                      </td>
                    </tr>
                  ) : null}
                </tbody>
              </Table>
            </Col>
          </Row>
          <hr />
          <Row className="order-content-info">
            <Col xs={12}>
              <Table className="order-info-table" borderless size="sm">
                <thead>
                  <tr>
                    <th>#</th>
                    <th className="table-header-80">Product</th>
                    <th>Prijs</th>
                  </tr>
                </thead>
                <tbody>
                  {props.order.order.map((order) => {
                    orderSumTotal += order.sumTotal;
                    return (
                      <OrderItem
                        id={productCount++}
                        product={order.name}
                        supplements={order.supplements}
                        sumTotal={order.sumTotal}
                      />
                    );
                  })}
                  {props.order.type === "delivery" ? (
                    <tr>
                      <td></td>
                      <td>Bezorgkosten</td>
                      <td>
                        <strong>{`€ 5.00`}</strong>
                      </td>
                    </tr>
                  ) : null}

                  <tr>
                    <td></td>
                    <td className="text-right">Totaal</td>
                    <td>
                      {props.order.type === "delivery" ? (
                        <strong>{`€ ${Number(orderSumTotal + 5).toFixed(
                          2
                        )}`}</strong>
                      ) : (
                        <strong>{`€ ${Number(orderSumTotal).toFixed(
                          2
                        )}`}</strong>
                      )}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button disabled variant="secondary" onClick={props.handleClose}>
          <FontAwesomeIcon icon={faPrint} /> Print order
        </Button>
        <DropdownButton id="dropdown-basic-button" title="Status wijzigen">
          {props.order.orderStatus != "open" ? (
            <Dropdown.Item
              onClick={() => updateOrderStatus(props.order.id, "open")}
            >
              Open
            </Dropdown.Item>
          ) : null}
          {props.order.orderStatus != "in-progress" ? (
            <Dropdown.Item
              onClick={() => updateOrderStatus(props.order.id, "in-progress")}
            >
              In behandeling
            </Dropdown.Item>
          ) : null}
          {props.order.orderStatus != "done" ? (
            <Dropdown.Item
              onClick={() => updateOrderStatus(props.order.id, "done")}
            >
              Voltooid
            </Dropdown.Item>
          ) : null}
        </DropdownButton>
      </Modal.Footer>
    </Modal>
  );
};

const OrderItem = (props) => {
  return (
    <tr>
      <td>{props.id}</td>
      <td>
        <Row>
          <Col>{props.product}</Col>
        </Row>
        {props.supplements
          ? props.supplements.map((supp) => {
              return (
                <OrderItemSupplements name={supp.name} count={supp.count} />
              );
            })
          : null}
      </td>
      <td>
        <strong>{`€ ${Number(props.sumTotal).toFixed(2)}`}</strong>
      </td>
    </tr>
  );
};

const OrderItemSupplements = (props) => {
  return (
    <Row className="text-secondary pl-3">
      <Col className="col-auto">{`${props.count} x`}</Col>
      <Col>{props.name}</Col>
    </Row>
  );
};

class OrderList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showOrderDetails: null,
    };
  }

  handleOrderClick = (order) => {
    this.setState({
      showOrderDetails: order,
    });
  };

  handleOrderClose = () => {
    this.setState({
      showOrderDetails: null,
    });
  };

  render() {
    return (
      <AdminWrapper>
        <Row className="admin-page-header">
          <Col md={10} className="admin-page-title">
            <h1>Orders</h1>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="order-list">
            <FirestoreCollection
              path={"orders"}
              //filter={["paymentCompleted", "==", true]}
              /* regrettably this plugin cannot filter AND sort*/
              sort={"created:desc"}
            >
              {({ error, isLoading, data }) => {
                if (error) {
                  return <Error error={error} />;
                }

                if (isLoading) {
                  return <p>loading..</p>;
                }

                if (data.length === 0) {
                  return <p>No orders yet!</p>;
                }

                return (
                  <Row>
                    <Col>
                      {data.map((order) =>
                        order.paymentCompleted ? (
                          <>
                            <Card
                              key={`order-${order.id}`}
                              className="order-list-item my-2"
                              onClick={() => this.handleOrderClick(order.id)}
                            >
                              <OrderCard
                                id={order.id}
                                status={order.orderStatus}
                                date={new Date(
                                  order.created.seconds * 1000
                                ).toLocaleDateString()}
                                type={order.type}
                                location={order.location}
                                client={`${order.client.firstname} ${order.client.lastname}`}
                                address={order.shippingAddres}
                              />
                            </Card>
                            <OrderModal
                              order={order}
                              show={
                                this.state.showOrderDetails == order.id
                                  ? true
                                  : false
                              }
                              handleClose={this.handleOrderClose}
                            />
                          </>
                        ) : null
                      )}
                    </Col>
                  </Row>
                );
              }}
            </FirestoreCollection>
          </Col>
        </Row>
      </AdminWrapper>
    );
  }
}

export default OrderList;
