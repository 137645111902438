import React from "react";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Container } from "react-bootstrap";

class CheckOutDeliveryForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      delivery: false,
      location: "",
    };
  }

  componentDidMount() {
    if (this.props.delivery === true) {
      this.setState({
        delivery: true,
        location: this.props.location,
      });
    }
  }

  onSubmit = (event) => {
    event.preventDefault();

    this.props.submit(this.state.delivery, this.state.location);
  };

  onDeliveryChange = (event) => {
    let result;
    if (event.target.name === "delivery") {
      result = {
        delivery: true,
        location: event.target.name,
      };
    } else {
      result = {
        delivery: false,
        location: event.target.name,
      };
    }
    this.setState({
      delivery: result.delivery,
      location: result.location,
    });
  };

  render() {
    return (
      <Form onSubmit={this.onSubmit}>
        <Card.Body>
          <Container>
            <Row>
              <Col>
                <Form.Label>
                  Wil je het laten bezorgen of kom je het ophalen?
                </Form.Label>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group controlId="pickup">
                  <Form.Check
                    type="radio"
                    name="beet"
                    onChange={this.onDeliveryChange}
                    checked={this.state.location == "beet"}
                    label="Ophalen Visbar Beet, Dapperstraat 26, Amsterdam (Oost)"
                  />

                  <Form.Check
                    type="radio"
                    name="baarsch"
                    onChange={this.onDeliveryChange}
                    checked={this.state.location == "baarsch"}
                    label="Ophalen Bar Baarsch, Jan Evertsenstraat 91, Amsterdam (West)"
                  />

                  <Form.Check
                    type="radio"
                    name="delivery"
                    onChange={this.onDeliveryChange}
                    checked={this.state.delivery}
                    label="Thuisbezorgen in Amsterdam (+€5.00)"
                    //label="Thuisbezorgen in Amsterdam, Eindhoven of Nuenen (+€5.00)"
                  />
                </Form.Group>
              </Col>
            </Row>
          </Container>
        </Card.Body>
        <Card.Footer className="checkout-footer">
          <Container>
            <Row>
              <Col className="text-right">
                <Button type="submit">Volgende</Button>
              </Col>
            </Row>
          </Container>
        </Card.Footer>
      </Form>
    );
  }
}

export default CheckOutDeliveryForm;
