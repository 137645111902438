import Firebase from "firebase/app";
import ReactGA from "react-ga";
import { prepareDocForUpdate } from "./helpers/firestoreHelpers";

const updateOrderStatus = (orderId, status) => {
  ReactGA.event({
    category: "Order",
    action: "Update status",
  });

  return Firebase.firestore()
    .collection("orders")
    .doc(orderId)
    .update({ orderStatus: status })
    .catch((error) => {
      alert(`Whoops, couldn't update the order: ${error.message}`);
    });
};

export default updateOrderStatus;
