import React from "react";
import { Route } from "react-router-dom";
import logIn from "../../actions/logIn";
import FirebaseAuth from "../misc/FirebaseAuth";

import AdminWrapper from "../../components/admin/AdminWrapper";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

import Error from "../misc/Error";
import PrivateRoute from "../misc/PrivateRoute";
import Login from "../account/Login";
import Account from "../account/Account";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

const Admin = () => (
  <FirebaseAuth>
    {({ isLoading, error, auth }) => {
      if (isLoading) {
        return (
          <AdminWrapper>
            <Row>
              <Col md={12} className="text-center mt-5">
                <FontAwesomeIcon size="6x" icon={faSpinner} spin />
              </Col>
            </Row>
          </AdminWrapper>
        );
      }

      if (error) {
        return <Error error={error} />;
      }

      if (!auth) {
        return <Route component={Login} />;
      }

      return <PrivateRoute component={Account} />;
    }}
  </FirebaseAuth>
);

export default Admin;
