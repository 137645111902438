import {
  ADD_TO_CART,
  REMOVE_FROM_CART,
  SHOW_CART,
  HIDE_CART,
  SHOW_PRODUCT,
  HIDE_PRODUCT,
} from "../../actions/action-types/shopping-cart-actions";

const initialState = {
  selectedItems: [],
  totalItems: 0,
  totalPrice: 0,
  productPrice: 0,
  productId: "0",
  shoppingCartDialog: false,
};

const shoppingCartReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_TO_CART: {
      let selectedProduct = {
        id: action.productId,
        supplements: action.supplements,
        orderItemId: state.totalItems,
      };

      let totalItems = state.selectedItems.push(selectedProduct);
      let productPrice = Number(action.price);
      let totalPrice = state.totalPrice + productPrice;

      return {
        ...state,
        totalItems: totalItems,
        totalPrice: totalPrice,
      };
    }
    case REMOVE_FROM_CART: {
      let updatedItems = state.selectedItems;
      let removeAtIndex = state.selectedItems.findIndex(
        (item) => item.orderItemId === action.orderItemId
      );

      updatedItems.splice(removeAtIndex, 1);

      let totalItems = updatedItems.length;
      let totalPrice = state.totalPrice - action.reducePrice;

      if (totalPrice <= 0) {
        totalPrice = 0;
      }

      return {
        ...state,
        totalItems: totalItems,
        totalPrice: totalPrice,
        selectedItems: updatedItems,
      };
    }
    case SHOW_PRODUCT: {
      return {
        ...state,
        productPrice: action.product.price,
        productId: action.product.id,
      };
    }
    case HIDE_PRODUCT: {
      return {
        ...state,
        productId: null,
        productPrice: null,
      };
    }
    case SHOW_CART: {
      return {
        ...state,
        shoppingCartDialog: true,
      };
    }
    case HIDE_CART: {
      return {
        ...state,
        shoppingCartDialog: false,
      };
    }
    default: {
      return state;
    }
  }
};
export default shoppingCartReducer;
