import React from "react";

import { connect } from "react-redux";
import {
  stepBack,
  submitAddress,
  submitDelivery,
  submitPaymentMethod,
} from "../../../actions/checkoutActions";

import CheckoutDeliveryForm from "./CheckOutDeliveryForm";
import CheckoutAddressForm from "./CheckoutAddressForm";
import CheckoutPaymentForm from "./CheckoutPaymentForm";
import CheckoutConfirmForm from "./CheckoutConfirmForm";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";

class CheckoutForm extends React.Component {
  constructor(props) {
    super(props);
  }

  showHeader(checkoutStep) {
    let headerTitle = null;
    let headerStepNumber = null;

    switch (checkoutStep) {
      case 0: {
        headerTitle = `Bezorgen of ophalen?`;
        headerStepNumber = 1;
        break;
      }
      case 1: {
        headerTitle = `Jouw gegevens`;
        headerStepNumber = 2;
        break;
      }
      case 2: {
        headerTitle = `Betaalmethode`;
        headerStepNumber = 3;
        break;
      }
      case 3: {
        headerTitle = `Bevestigen`;
        headerStepNumber = 4;
        break;
      }
    }

    return (
      <Card.Header className="font-weight-bold header">
        <Row>
          <Col>{headerTitle}</Col>
          <Col className="text-right">{`Stap ${headerStepNumber} / 4`}</Col>
        </Row>
      </Card.Header>
    );
  }

  showForm(checkoutStep) {
    switch (checkoutStep) {
      case 0: {
        return (
          <CheckoutDeliveryForm
            delivery={this.props.delivery}
            submit={this.props.submitDelivery}
          />
        );
      }
      case 1: {
        return (
          <CheckoutAddressForm
            client={this.props.client ? this.props.client : null}
            invoiceAddress={
              this.props.invoiceAddress ? this.props.invoiceAddress : null
            }
            shippingAddress={
              this.props.shippingAddress ? this.props.shippingAddress : null
            }
            delivery={this.props.delivery}
            submit={this.props.submitAddress}
            back={this.props.stepBack}
          />
        );
      }
      case 2: {
        return (
          <CheckoutPaymentForm
            paymentMethod={
              this.props.paymentMethod ? this.props.paymentMethod : null
            }
            submit={this.props.submitPaymentMethod}
            back={this.props.stepBack}
          />
        );
      }
      case 3: {
        return (
          <CheckoutConfirmForm
            delivery={this.props.delivery}
            client={this.props.client}
            delivery={this.props.delivery}
            location={this.props.location}
            invoiceAddress={this.props.invoiceAddress}
            shippingAddress={this.props.shippingAddress}
            paymentMethod={this.props.paymentMethod}
            order={this.props.selectedItems}
            back={this.props.stepBack}
          />
        );
      }
      default: {
        return <p>Oeps! Er is iets mis gegaan..</p>;
      }
    }
  }

  render() {
    return (
      <Col className="">
        <Card className="public-checkout-form">
          {this.showHeader(this.props.checkoutStep)}
          {this.showForm(this.props.checkoutStep)}
        </Card>
      </Col>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    checkoutStep: state.checkout.checkoutStep,
    delivery: state.checkout.delivery,
    location: state.checkout.location,
    client: state.checkout.client,
    invoiceAddress: state.checkout.invoiceAddress,
    shippingAddress: state.checkout.shippingAddress,
    paymentMethod: state.checkout.payment_method_type[0],
    selectedItems: state.shoppingCart.selectedItems,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    stepBack: () => {
      dispatch(stepBack());
    },
    submitDelivery: (deliveryType, deliveryLocation) => {
      dispatch(submitDelivery(deliveryType, deliveryLocation));
    },
    submitAddress: (client, invoiceAddress, shippingAddress) => {
      dispatch(submitAddress(client, invoiceAddress, shippingAddress));
    },
    submitPaymentMethod: (paymentMethod) => {
      dispatch(submitPaymentMethod(paymentMethod));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutForm);
