import Firebase from "firebase/app";
import ReactGA from "react-ga";
import slugify from "slugify";

import { prepareDocForCreate } from "../../helpers/firestoreHelpers";

const createProduct = async (values) => {
  ReactGA.event({
    category: "Webshop - Product",
    action: "Create product",
  });

  values.slug = slugify(values.name, { lower: true });

  var category_refence = Firebase.firestore()
    .collection("webshop_categories")
    .doc(values.category);
  values.category = category_refence;

  var priceNumber = Number(values.price);
  values.price = priceNumber.toFixed(2);

  const productsCollection = Firebase.firestore()
    .collection("webshop_products")
    .where("categoryTitle", "==", values.category);

  await productsCollection.get().then((querySnapshot) => {
    values.order = querySnapshot.size;
  });

  try {
    await Firebase.firestore()
      .collection("webshop_products")
      .add(prepareDocForCreate(values));
    return values;
  } catch (error) {
    alert(`Whoops, couldn't create the product: ${error.message}`);
  }
};

export default createProduct;
