import React from "react";
import { FirestoreCollection } from "react-firestore";

import AdminWrapper from "../../../../components/admin/AdminWrapper";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

import FirebaseAuth from "../../../misc/FirebaseAuth";
import Error from "../../../misc/Error";
import logIn from "../../../../actions/logIn";
import createProduct from "../../../../actions/webshop/product/createProduct";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLongArrowAltLeft } from "@fortawesome/free-solid-svg-icons";

import ProductForm from "./ProductForm";

const ProductNew = ({ history }) => (
  <AdminWrapper>
    <Row className="admin-page-header">
      <Col md={10} className="admin-page-title text-left">
        <Row>
          <Col md="auto" className="d-flex align-items-center">
            <Button variant="light" onClick={() => history.goBack()}>
              <FontAwesomeIcon icon={faLongArrowAltLeft} />
            </Button>
          </Col>
          <Col>
            <h1>New Product</h1>
          </Col>
        </Row>
      </Col>
      <Col md={2} className="admin-action-buttons"></Col>
    </Row>
    <Row>
      <Col md={12}>
        <FirebaseAuth>
          {({ isLoading, error, auth }) => {
            if (error) {
              return <Error error={error} />;
            }

            if (isLoading) {
              return <div>loading...</div>;
            }

            if (!auth) {
              return (
                <div>
                  <p>You must be logged in to add products</p>
                  <button onClick={logIn}>log in</button>
                </div>
              );
            }

            return (
              <ProductForm
                context="create"
                onSubmit={(values) =>
                  createProduct(values).then((page) =>
                    history.push(`../products`)
                  )
                }
              />
            );
          }}
        </FirebaseAuth>
      </Col>
    </Row>
  </AdminWrapper>
);

export default ProductNew;
