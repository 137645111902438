// the main routes of our app are defined here using react-router
// https://reacttraining.com/react-router/web/example/basic

import React from "react";
import { Switch, Route } from "react-router-dom";
import PrivateRoute from "./misc/PrivateRoute";

import Home from "./public/home/Home";

import About from "./public/about/About";
import Menu from "./public/menu/Menu";

import Webshop from "./public/webshop/Webshop";
import CheckOut from "./public/webshop/CheckOut";
import CompletedOrder from "./public/webshop/CompletedOrder";

import TermsAndConditions from "./public/legal/TermsAndConditions";
import Cookies from "./public/legal/Cookies";
import Privacy from "./public/legal/Privacy";
import Disclaimer from "./public/legal/Disclaimer";

import Admin from "./admin/Admin";

import ConfigPage from "./admin/config/ConfigPage";

import Page from "./admin/pages/Page";
import PageList from "./admin/pages/PageList";
import PageNew from "./admin/pages/PageNew";
import PageEdit from "./admin/pages/PageEdit";

import OrderList from "./admin/webshop/order/OrderList";
import Orders from "./admin/webshop/order/Orders";

import CategoryListContainer from "./admin/webshop/category/CategoryList";
import CategoryNew from "./admin/webshop/category/CategoryNew";
import CategoryEdit from "./admin/webshop/category/CategoryEdit";

import ProductCategoriesList from "./admin/webshop/product/ProductCategoriesList";
import ProductNew from "./admin/webshop/product/ProductNew";
import ProductEdit from "./admin/webshop/product/ProductEdit";

import SupplementList from "./admin/webshop/supplement/SupplementList";
import SupplementNew from "./admin/webshop/supplement/SupplementNew";
import SupplementEdit from "./admin/webshop/supplement/SupplementEdit";

import Account from "./account/Account";
import Login from "./account/Login";
import Logout from "./account/Logout";
import Error from "./misc/Error";

//this.props.admin.find((config) => config.id === "admin");

class Routes extends React.Component {
  constructor(props) {
    super(props);
  }

  AdminRoutes(adminConfig) {}

  PagesAdminRoutes() {
    return [
      <PrivateRoute exact path="/admin/pages" component={PageList} />,
      <PrivateRoute path="/admin/pages/new" component={PageNew} />,
      <PrivateRoute path="/admin/pages/:slug/edit" component={PageEdit} />,
      <PrivateRoute path="/admin/pages/:slug" component={Page} />,
    ];
  }

  WebshopAdminRoutes() {
    return [
      //orders
      <PrivateRoute exact path="/admin/webshop" component={OrderList} />,
      <PrivateRoute path="/admin/webshop/orders" component={OrderList} />,
      <PrivateRoute path="/admin/webshop/orderstest" component={Orders} />,
      //categories
      <PrivateRoute
        exact
        path="/admin/webshop/categories/"
        component={CategoryListContainer}
      />,
      <PrivateRoute
        path="/admin/webshop/categories/new/"
        component={CategoryNew}
      />,
      <PrivateRoute
        path="/admin/webshop/categories/:slug/edit"
        component={CategoryEdit}
      />,
      //products
      <PrivateRoute
        exact
        path="/admin/webshop/products/"
        component={ProductCategoriesList}
      />,
      <PrivateRoute
        path="/admin/webshop/products/new/"
        component={ProductNew}
      />,
      <PrivateRoute
        path="/admin/webshop/products/:slug/edit"
        component={ProductEdit}
      />,
      //supplements
      <PrivateRoute
        path="/admin/webshop/new-supplement"
        component={SupplementNew}
      />,
      <PrivateRoute
        path="/admin/webshop/supplement/:slug/edit"
        component={SupplementEdit}
      />,
      <PrivateRoute
        exact
        path="/admin/webshop/supplements/"
        component={SupplementList}
      />,
      <PrivateRoute
        path="/admin/webshop/supplements/new/"
        component={SupplementNew}
      />,
      <PrivateRoute
        path="/admin/webshop/supplements/:slug/edit"
        component={SupplementEdit}
      />,
    ];
  }

  render() {
    return (
      <Switch>
        {/* Public pages - PrivateRoutes, need auth to be true*/}
        <Route exact path="/" component={Home} />
        <Route exact path="/over-ons" component={About} />
        <Route exact path="/impressie" component={About} />
        <Route exact path="/menu" component={Menu} />

        {this.props.public.webshop ? (
          <Route path="/webshop" component={Webshop} />
        ) : null}

        {this.props.public.webshop ? (
          <Route path="/checkout" component={CheckOut} />
        ) : null}

        {this.props.public.webshop ? (
          <Route path="/order/:id" component={CompletedOrder} />
        ) : null}

        {/* Legal pages with terms and conditions etc. */}
        <Route path="/terms-and-conditions" component={TermsAndConditions} />
        <Route path="/cookies" component={Cookies} />
        <Route path="/disclaimer" component={Disclaimer} />
        <Route path="/privacy" component={Privacy} />

        {/* Admin pages - PrivateRoutes, need auth to be true*/}
        <Route exact path="/admin" component={Admin} />
        <PrivateRoute exact path="/admin/config" component={ConfigPage} />

        <PrivateRoute path="/admin/account" component={Account} />
        <Route path="/login" component={Login} />
        <Route exact path="/admin/logout" component={Logout} />

        {this.props.admin.webshop ? this.WebshopAdminRoutes() : null}
        {this.props.admin.pages ? this.PagesAdminRoutes() : null}
        <Route component={Error} />
      </Switch>
    );
  }
}

export default Routes;
