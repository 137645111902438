import React from "react";

import PublicWrapper from "../../../components/public/PublicWrapper";

import { FirestoreDocument } from "react-firestore";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

class CompletedOrder extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <PublicWrapper>
        <div id="order" className="page-background">
          <Container className="website-content mb-5 px-0">
            <Row>
              <Col md={2}></Col>
              <Col>
                <Card>
                  <Card.Body className="text-center text-dark py-5">
                    <FirestoreDocument
                      path={`orders/${this.props.match.params.id}`}
                      render={({ isLoading, data }) => {
                        return isLoading ? (
                          <p>Loading..</p>
                        ) : (
                          <div>
                            <h1>Bestelling bevestigd!</h1>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              className="public-order-checkCircle mt-3 mb-5"
                              size="6x"
                              color="green"
                            />
                            <ul class="list-inline">
                              <li class="list-inline-item">
                                <strong>Jouw order nummer:</strong>
                              </li>
                              <li class="list-inline-item">{data.id}</li>
                            </ul>
                            <p>
                              Je ontvangt z.s.m. een email met meer informatie.
                            </p>
                          </div>
                        );
                      }}
                    />
                  </Card.Body>
                </Card>
              </Col>
              <Col md={2}></Col>
            </Row>
          </Container>
        </div>
      </PublicWrapper>
    );
  }
}

export default CompletedOrder;
