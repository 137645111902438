import Firebase from "firebase/app";
import ReactGA from "react-ga";

import { prepareDocForUpdate } from "../../helpers/firestoreHelpers";

const updateProduct = (productId, values) => {
  ReactGA.event({
    category: "Webshop - Product",
    action: "Update product",
  });

  return Firebase.firestore()
    .collection("webshop_products")
    .doc(productId)
    .update(prepareDocForUpdate(values))
    .catch((error) => {
      alert(`Whoops, couldn't edit the product: ${error.message}`);
    });
};

export default updateProduct;
