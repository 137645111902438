import Firebase from "firebase/app";
import ReactGA from "react-ga";

const logIn = () => {
  ReactGA.event({
    category: "User",
    action: "Log in",
  });

  let provider = new Firebase.auth.GoogleAuthProvider();

  Firebase.auth()
    .signInWithRedirect(provider)
    .then((res) => {
      Firebase.auth()
        .getRedirectResult()
        .then((result) => {
          var user = result.user;
        });
    });
};

export default logIn;
