import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { FirestoreCollection } from "react-firestore";

import AdminWrapper from "../../../../components/admin/AdminWrapper";

import { LinkContainer } from "react-router-bootstrap";

import DeleteConfirmationModal from "../../../../components/admin/DeleteConfirmationModal";
import deleteSupplement from "../../../../actions/webshop/supplement/deleteSupplement";

import WebshopPageContainer from "../../../../components/admin/webshop/WebshopPageContainer";
import SupplementListItem from "./SupplementListItem";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import Fade from "react-bootstrap/Fade";

import Error from "../../../misc/Error";

class SupplementList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      alert: [false, "info", "", true],
      showDeleteConfirmation: false,
      toBeDeletedSupplement: null,
    };
  }

  componentDidMount() {}

  hideAlert = () => {
    this.setState({
      alert: [false, "info", "", true],
    });
  };

  showDeleteConfirmation = (id, name) => {
    this.setState({
      showDeleteConfirmation: true,
      toBeDeletedSupplement: [id, name],
    });
  };

  hideDeleteConfirmation = () => {
    this.setState({
      showDeleteConfirmation: false,
      toBeDeletedSupplement: null,
    });
  };

  handeleDeleteSupplement = (toBeDeletedSupplementId) => {
    this.setState({
      showDeleteConfirmation: false,
      toBeDeletedSupplement: null,
      alert: [true, "success", "Supplement was deleted", true],
    });

    setTimeout(() => {
      this.hideAlert();
    }, 2000);
  };

  render() {
    const actionButtons = (
      <LinkContainer to="supplements/new">
        <Button>New Supplement</Button>
      </LinkContainer>
    );

    return (
      <WebshopPageContainer title={`Supplements`} buttons={actionButtons}>
        <Container className="mt-3">
          <div className="w-100 d-flex justify-content-center">
            <Fade in={this.state.alert[0]} timeout={2000} unmountOnExit>
              <Alert
                className="admin-alert"
                show={this.state.alert[0]}
                variant={this.state.alert[1]}
                dismissible={this.state.alert[3]}
                onClose={() => this.hideAlert()}
              >
                {this.state.alert[2]}
              </Alert>
            </Fade>
          </div>

          <Row>
            <Col>
              {this.props.supplements.map((supplement, index) => (
                <Card className={`admin-list-item my-2`} key={index}>
                  <Card.Body>
                    <Row>
                      <SupplementListItem
                        id={supplement.id}
                        name={supplement.name}
                        slug={supplement.slug}
                        showDeleteModal={() =>
                          this.showDeleteConfirmation(
                            supplement.id,
                            supplement.name
                          )
                        }
                      />
                    </Row>
                  </Card.Body>
                </Card>
              ))}
            </Col>
          </Row>

          <DeleteConfirmationModal
            object={`supplement`}
            name={
              this.state.toBeDeletedSupplement !== null
                ? this.state.toBeDeletedSupplement[1]
                : null
            } // get title from state
            hide={() => this.hideDeleteConfirmation()}
            show={this.state.showDeleteConfirmation}
            delete={
              () =>
                deleteSupplement(
                  this.state.toBeDeletedSupplement !== null
                    ? this.state.toBeDeletedSupplement[0]
                    : null
                ).then(
                  this.handeleDeleteSupplement(
                    this.state.toBeDeletedSupplement[0]
                  )
                ) // get the id from the state as well
            }
          />
        </Container>
      </WebshopPageContainer>
    );
  }
}

class SupplementListContainer extends React.Component {
  render() {
    return (
      <FirestoreCollection path={"webshop_supplements"} sort="createdOn">
        {({ error, isLoading, data }) => {
          if (error) {
            return <Error error={error} />;
          }

          if (isLoading) {
            return <p>loading...</p>;
          }

          if (data.length === 0) {
            return <p>No supplements yet!</p>;
          }
          return <SupplementList supplements={data} />;
        }}
      </FirestoreCollection>
    );
  }
}

export default SupplementListContainer;
