import Firebase from "firebase/app";
import ReactGA from "react-ga";

const updateProductsOrder = (productsNewOrder) => {
  ReactGA.event({
    product: "Webshop - Products",
    action: "Update order of products in a category",
  });

  const batch = Firebase.firestore().batch();

  return new Promise((resolve) => {
    productsNewOrder.forEach((product) => {
      let docRef = Firebase.firestore()
        .collection("webshop_products")
        .doc(product.id);
      batch.update(docRef, {
        order: product.order,
        updatedBy: Firebase.auth().currentUser
          ? Firebase.auth().currentUser.uid
          : null,
        updatedOn: Firebase.firestore.Timestamp.now(),
      });
    });

    batch.commit().then(function () {
      resolve(`Order of products has been updated!`);
    });
  });
};

export default updateProductsOrder;
