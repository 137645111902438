import React from "react";

import PublicWrapper from "../../../components/public/PublicWrapper";

import ProductListContainer from "../../../components/public/webshop/ProductListContainer";
import ProductModalContainer from "../../../components/public/webshop/ProductModalContainer";

import ShoppingCartDialog from "../../../components/public/webshop/ShoppingCartDialog";

import { connect } from "react-redux";
import { showCart, hideCart } from "../../../actions/shoppingCartActions";

class Webshop extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <PublicWrapper>
        <div id="webshop" className="page-background">
          <ProductListContainer
            items={this.props.totalItems}
            price={
              this.props.totalPrice ? this.props.totalPrice.toFixed(2) : 0.0
            }
            showCart={() => this.props.showCart()}
          />
          <ProductModalContainer />
          <ShoppingCartDialog
            ShoppingCartDialog={this.props.shoppingCartDialog}
            hideCart={this.props.hideCart}
          />
        </div>
      </PublicWrapper>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    shoppingCartDialog: state.shoppingCart.shoppingCartDialog,
    selectedItems: state.shoppingCart.selectedItems,
    totalItems: state.shoppingCart.totalItems,
    totalPrice: state.shoppingCart.totalPrice,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showCart: () => {
      dispatch(showCart());
    },
    hideCart: () => {
      dispatch(hideCart());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Webshop);
