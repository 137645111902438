import React from "react";
import { Route, Redirect } from "react-router-dom";
import { FirestoreCollection } from "react-firestore";
import FirebaseAuth from "./FirebaseAuth";
import logOut from "../../actions/logOut";
import Error from "../misc/Error";

const PrivateRoute = ({ component: Component, ...rest }) => {
  // Show the component only when the user is logged in
  // Otherwise, redirect the user to /signin page

  return (
    <FirebaseAuth>
      {({ isLoading, error, auth }) => {
        if (isLoading) {
          return <p>loading...</p>;
        }

        if (error) {
          return <Error error={error} />;
        }

        if (!auth) {
          return (
            <Route {...rest} render={(props) => <Redirect to="/admin" />} />
          );
        } else {
          return (
            <FirestoreCollection path={"admins"}>
              {({ error, isLoading, data }) => {
                if (isLoading) {
                  return <p>loading...</p>;
                }

                if (data.length === 0) {
                  return console.log("no admins registered");
                }

                const adminUser = data.filter(
                  (admin) => admin.email === auth.email
                );

                return adminUser[0].email === auth.email ? (
                  <Route
                    {...rest}
                    render={(props) => <Component {...props} />}
                  />
                ) : (
                  <Route
                    {...rest}
                    render={(props) => <Redirect to="/admin/logout/" />}
                  />
                );
              }}
            </FirestoreCollection>
          );
        }
      }}
    </FirebaseAuth>
  );
};

export default PrivateRoute;
