import React from "react";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

import Select from "react-select";

class CheckoutPaymentForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedPaymentMethod: null,
      paymentMethods: [],
    };
  }

  componentDidMount() {
    this.setState({
      paymentMethods: [
        { value: `ideal`, label: "iDEAL" },
        { value: `card`, label: "Creditcard" },
      ],
    });
  }

  onSubmit = (event) => {
    event.preventDefault();
    if (this.state.selectedPaymentMethod) {
      this.props.submit(this.state.selectedPaymentMethod);
    }
  };

  onChange = (paymentMethod) => {
    this.setState({
      selectedPaymentMethod: paymentMethod.value,
    });
  };

  render() {
    return (
      <Form onSubmit={this.onSubmit}>
        <Card.Body>
          <Row>
            <Col>
              <Form.Label>Betaalmethode</Form.Label>
            </Col>
          </Row>
          <Row>
            <Col>
              <Select
                name="payment"
                placeholder="Kies een betaalmethode"
                defaultValue={this.state.paymentMethods[`ideal`]}
                onChange={this.onChange}
                options={this.state.paymentMethods}
              />
            </Col>
          </Row>
        </Card.Body>
        <Card.Footer className="checkout-footer">
          <Row>
            <Col className="text-left">
              <Button variant="light" onClick={this.props.back}>
                Vorige
              </Button>
            </Col>
            <Col className="text-right">
              <Button
                type="submit"
                disabled={this.state.selectedPaymentMethod == null}
              >
                Volgende
              </Button>
            </Col>
          </Row>
        </Card.Footer>
      </Form>
    );
  }
}

export default CheckoutPaymentForm;
