import {
  SUBMIT_ADDRESS,
  SUBMIT_DELIVERY,
  STEP_BACK,
  SUBMIT_PAYMENT,
} from "../../actions/action-types/checkout-actions";

const initialState = {
  checkoutStep: 0,
  delivery: null,
  deliveryLocation: null,
  client: null,
  invoiceAddress: null,
  shippingAddress: null,
  orderId: null,
  payment_method_type: [],
  lineItems: [],
};

const checkoutReducer = (state = initialState, action) => {
  switch (action.type) {
    case STEP_BACK: {
      return {
        ...state,
        checkoutStep: state.checkoutStep - 1,
      };
    }
    case SUBMIT_DELIVERY: {
      let delivery = action.delivery;
      let location = action.location;

      return {
        ...state,
        delivery: delivery,
        location: location,
        checkoutStep: state.checkoutStep + 1,
      };
    }
    case SUBMIT_ADDRESS: {
      return {
        ...state,
        client: action.client,
        invoiceAddress: action.invoiceAddress,
        shippingAddress: action.shippingAddress,
        checkoutStep: state.checkoutStep + 1,
      };
    }
    case SUBMIT_PAYMENT: {
      let paymentMethod = [];
      paymentMethod.push(action.paymentMethod);

      return {
        ...state,
        payment_method_type: paymentMethod,
        checkoutStep: state.checkoutStep + 1,
      };
    }

    default: {
      return state;
    }
  }
};

export default checkoutReducer;
