import React from "react";

import PublicWrapper from "../../../components/public/PublicWrapper";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const TermsAndConditions = () => {
  return (
    <PublicWrapper>
      <div id="terms-and-conditions" className="page-background">
        <Container className="website-content">
          <Row className="legal-content">
            <Col></Col>
            <Col xs={10}>
              <h1>ALGEMENE VOORWAARDEN</h1>
              <p>
                Laatst bijgewerkt: <strong>03 juni 2020</strong>
              </p>
              <h4>Artikel 1: Definities </h4>
              <ol>
                <li>
                  Visbar Beet, gevestigd te Amsterdam, KvK-nummer 65428978,
                  wordt in deze algemene voorwaarden aangeduid als verkoper.
                </li>
                <li>
                  De wederpartij van verkoper wordt in deze algemene voorwaarden
                  aangeduid als koper.
                </li>
                <li>Partijen zijn verkoper en koper samen.</li>
                <li>
                  Met de overeenkomst wordt bedoeld de koopovereenkomst tussen
                  partijen.
                </li>
              </ol>
              <h4>Artikel 2: Toepasselijkheid algemene voorwaarden</h4>
              <ol>
                <li>
                  Deze voorwaarden zijn van toepassing op alle offertes,
                  aanbiedingen, overeenkomsten en leveringen van diensten of
                  goederen door of namens verkoper.
                </li>
                <li>
                  Afwijken van deze voorwaarden kan alleen als dat uitdrukkelijk
                  &eacute;n schriftelijk door partijen is overeengekomen.
                </li>
              </ol>
              <h4>Artikel 3: Betaling</h4>
              <ol>
                <li>
                  De volledige koopsom wordt altijd meteen in de winkel voldaan.
                  Bij reserveringen wordt in sommige gevallen een aanbetaling
                  verwacht. In dat geval krijgt de koper een bewijs van de
                  reservering en de vooruitbetaling.
                </li>
                <li>
                  Betaalt koper niet op tijd, dan is hij in gebreke. Blijft
                  koper in gebreke, dan is verkoper gerechtigd de verplichtingen
                  op te schorten totdat koper aan zijn betalingsverplichting
                  heeft voldaan.
                </li>
                <li>
                  Blijft koper in gebreke, dan zal verkoper tot invordering
                  overgaan. De kosten met betrekking tot die invordering komen
                  voor rekening van de koper. Deze incassokosten worden berekend
                  aan de hand van het Besluit vergoeding voor
                  buitengerechtelijke incassokosten.
                </li>
                <li>
                  In geval van liquidatie, faillissement, beslag of surseance
                  van betaling van de koper zijn de vorderingen van verkoper op
                  de koper onmiddellijk opeisbaar.
                </li>
                <li>
                  Weigert koper zijn medewerking aan de uitvoering van de
                  opdracht door verkoper, dan is hij nog steeds verplicht de
                  afgesproken prijs aan verkoper te betalen.
                </li>
              </ol>
              <h4>Artikel 4: Aanbiedingen, offertes en prijs</h4>
              <ol>
                <li>
                  Aanbiedingen zijn vrijblijvend, tenzij in het aanbod een
                  termijn van aanvaarding is genoemd. Wordt het aanbod niet
                  binnen die gestelde termijn aanvaard, dan vervalt het aanbod.
                </li>
                <li>
                  Levertijden in offertes zijn indicatief en geven koper bij
                  overschrijding daarvan geen recht op ontbinding of
                  schadevergoeding, tenzij partijen uitdrukkelijk &eacute;n
                  schriftelijk anders zijn overeengekomen.
                </li>
                <li>
                  Aanbiedingen en offertes gelden niet automatisch voor
                  nabestellingen. Partijen moeten dit uitdrukkelijk &eacute;n
                  schriftelijk overeenkomen.
                </li>
                <li>
                  De op aanbiedingen, offertes en facturen genoemde prijs
                  bestaat uit de koopprijs inclusief de verschuldigde btw en
                  eventuele andere overheidsheffingen.
                </li>
              </ol>
              <h4>Artikel 5: Herroepingsrecht</h4>
              <ol>
                <li>
                  De consument krijgt het recht om na ontvangst van de
                  bestelling binnen14 dagen de overeenkomst zonder opgave van
                  redenen te ontbinden (herroepingsrecht). De termijn begint te
                  lopen vanaf het moment dat de (gehele) bestelling door de
                  consument is ontvangen.
                </li>
                <li>
                  Er bestaat geen herroepingsrecht wanneer de producten volgens
                  zijn specificaties op maat zijn gemaakt of slechts kort
                  houdbaar zijn.
                </li>
                <li>
                  De consument kan een herroepingsformulier van verkoper
                  gebruiken. Verkoper is gehouden dit terstond na de vraag van
                  koper aan koper ter beschikking te stellen.
                </li>
                <li>
                  Tijdens de bedenktijd zal de consument zorgvuldig omgaan met
                  het product en de verpakking. Hij zal het product slechts in
                  die mate uitpakken of gebruiken voor zover dat nodig is om te
                  kunnen beoordelen of hij het product wenst te behouden. Indien
                  hij van zijn herroepingsrecht gebruik maakt, zal hij het
                  ongebruikte en onbeschadigde product met alle geleverde
                  toebehoren en - indien redelijkerwijze mogelijk - in de
                  originele verzendverpakking aan verkoper retourneren, conform
                  de door de ondernemer verstrekte redelijke en duidelijke
                  instructies.
                </li>
              </ol>
              <h4>Artikel 6: Wijziging van de overeenkomst</h4>
              <ol>
                <li>
                  Indien tijdens de uitvoering van de overeenkomst blijkt dat
                  het voor een behoorlijke uitvoering van de opdracht
                  noodzakelijk is om de te verrichten werkzaamheden te wijzigen
                  of aan te vullen, passen partijen tijdig en in onderling
                  overleg de overeenkomst dienovereenkomstig aan.
                </li>
                <li>
                  Indien partijen overeenkomen dat de overeenkomst wordt
                  gewijzigd of aangevuld, kan het tijdstip van voltooiing van de
                  uitvoering daardoor worden be&iuml;nvloed. Verkoper stelt
                  koper hiervan zo spoedig mogelijk op de hoogte.
                </li>
                <li>
                  Indien de wijziging van of aanvulling op de overeenkomst
                  financi&euml;le en/of kwalitatieve consequenties heeft, licht
                  verkoper koper hierover vooraf schriftelijk in.
                </li>
                <li>
                  Indien partijen een vaste prijs zijn overeengekomen, geeft
                  verkoper daarbij aan in hoeverre de wijziging of aanvulling
                  van de overeenkomst een overschrijding van deze prijs tot
                  gevolg heeft.
                </li>
                <li>
                  In afwijking van het bepaalde in het derde lid van dit artikel
                  kan verkoper geen meerkosten in rekening brengen indien de
                  wijziging of aanvulling het gevolg is van omstandigheden die
                  aan hem kunnen worden toegerekend.
                </li>
              </ol>
              <h4>Artikel 7: Oplevering en risico-overgang</h4>
              <ol>
                <li>
                  Zodra het gekochte door de koper in ontvangst is genomen, gaat
                  het risico over van verkoper naar koper.
                </li>
              </ol>
              <h4>Artikel 8: Onderzoek, reclames</h4>
              <ol>
                <li>
                  Koper is gehouden het geleverde op het moment van
                  (af)levering, maar in ieder geval binnen zo kort mogelijke
                  termijn te (doen) onderzoeken. Daarbij behoort koper te
                  onderzoeken of kwaliteit en kwantiteit van het geleverde
                  overeenstemmen met hetgeen partijen zijn overeengekomen,
                  althans dat kwaliteit en kwantiteit voldoen aan de eisen die
                  daaraan in het normale (handels)verkeer gelden.
                </li>
                <li>
                  Reclames met betrekking tot beschadigingen, tekorten of
                  verlies van geleverde goederen moeten binnen 10 werkdagen na
                  de dag van aflevering van de goederen door koper schriftelijk
                  bij verkoper worden ingediend.
                </li>
                <li>
                  Bij gegrondverklaring van de klacht binnen de gestelde termijn
                  heeft verkoper het recht om &oacute;f te herstellen, &oacute;f
                  opnieuw te leveren, &oacute;f om van levering af te zien en
                  koper een creditnota te sturen voor dat deel van de koopprijs.
                </li>
                <li>
                  Geringe en/of in de branche gebruikelijke afwijkingen en
                  verschil in kwaliteit, aantal, maat of afwerking kunnen niet
                  aan verkoper worden tegengeworpen.
                </li>
                <li>
                  Klachten met betrekking tot een bepaald product hebben geen
                  invloed op andere producten dan wel onderdelen behorende bij
                  diezelfde overeenkomst.
                </li>
                <li>
                  Na het verwerken van de goederen bij koper worden geen
                  reclames meer geaccepteerd.
                </li>
              </ol>
              <h4>Artikel 9: Monsters en modellen</h4>
              <ol>
                <li>
                  Is aan koper een monster of model getoond of verstrekt, dan
                  wordt het vermoed slechts als aanduiding te zijn verstrekt
                  zonder dat de te leveren zaak daaraan behoeft te beantwoorden.
                  Dit is anders als partijen uitdrukkelijk zijn overeengekomen
                  dat de te leveren zaak wel daarmee zal overeenstemmen.
                </li>
                <li>
                  Bij overeenkomsten ter zake van een onroerende zaak wordt
                  vermelding van de oppervlakte of andere afmetingen en
                  aanduidingen eveneens vermoed slechts als aanduiding bedoeld
                  te zijn, zonder dat de te leveren zaak daaraan behoeft te
                  beantwoorden.
                </li>
              </ol>
              <h4>Artikel 10: Levering</h4>
              <ol>
                <li>
                  Levering geschiedt &lsquo;af fabriek/winkel/magazijn&rsquo;.
                  Dit houdt in dat alle kosten voor koper zijn.
                </li>
                <li>
                  Koper is verplicht de zaken af te nemen op het moment dat
                  verkoper deze bij hem aflevert of doet afleveren, dan wel op
                  het moment waarop deze zaken hem volgens de overeenkomst ter
                  beschikking worden gesteld.
                </li>
                <li>
                  Indien koper afname weigert of nalatig is in het verstrekken
                  van informatie of instructies die noodzakelijk zijn voor de
                  levering, is verkoper gerechtigd de zaak voor rekening en
                  risico van koper op te slaan.
                </li>
                <li>
                  Indien de zaken worden bezorgd, is verkoper gerechtigd
                  eventuele bezorgkosten in rekening te brengen.
                </li>
                <li>
                  Indien verkoper gegevens van koper nodig heeft voor de
                  uitvoering van de overeenkomst, vangt de levertijd aan nadat
                  koper deze gegevens aan verkoper ter beschikking heeft
                  gesteld.
                </li>
                <li>
                  Een door verkoper opgegeven termijn voor levering is
                  indicatief. Dit is nooit een fatale termijn. Bij
                  overschrijding van de termijn moet koper verkoper schriftelijk
                  in gebreke stellen.
                </li>
                <li>
                  Verkoper is gerechtigd de zaken in gedeelten te leveren,
                  tenzij partijen dit anders schriftelijk zijn overeengekomen of
                  aan deellevering geen zelfstandige waarde toekomt. Verkoper is
                  bij levering in delen gerechtigd deze delen afzonderlijk te
                  factureren.
                </li>
              </ol>
              <h4>Artikel 11: Overmacht</h4>
              <ol>
                <li>
                  Kan verkoper niet, niet tijdig of niet behoorlijk aan zijn
                  verplichtingen uit de overeenkomst voldoen door overmacht, dan
                  is hij niet aansprakelijk voor door koper geleden schade.
                </li>
                <li>
                  Onder overmacht verstaan partijen in ieder geval iedere
                  omstandigheid waarmee verkoper ten tijde van het aangaan van
                  de overeenkomst geen rekening kon houden en ten gevolge
                  waarvan de normale uitvoering van de overeenkomst
                  redelijkerwijs niet door koper kan worden verlangd zoals
                  bijvoorbeeld ziekte, oorlog of oorlogsgevaar, burgeroorlog en
                  oproer, molest, sabotage, terrorisme, energiestoring,
                  overstroming, aardbeving, brand, bedrijfsbezetting,
                  werkstakingen, werkliedenuitsluiting, gewijzigde
                  overheidsmaatregelen, transportmoeilijkheden, en andere
                  storingen in het bedrijf van verkoper.
                </li>
                <li>
                  Voorts verstaan partijen onder overmacht de omstandigheid dat
                  toeleveringsbedrijven waarvan verkoper afhankelijk is voor de
                  uitvoering van de overeenkomst, niet aan de contractuele
                  verplichtingen jegens verkoper voldoen, tenzij zulks aan
                  verkoper te verwijten is.
                </li>
                <li>
                  Indien zich een situatie als hiervoor bedoeld voordoet als
                  gevolg waarvan verkoper niet aan zijn verplichtingen jegens
                  koper kan voldoen, dan worden die verplichtingen opgeschort
                  zolang verkoper niet aan zijn verplichtingen kan voldoen.
                  Indien de in de vorige zin bedoelde situatie 30 kalenderdagen
                  heeft geduurd, hebben partijen het recht de overeenkomst
                  schriftelijk geheel of gedeeltelijk te ontbinden.
                </li>
                <li>
                  Ingeval de overmacht langer dan drie maanden voortduurt, heeft
                  koper het recht de overeenkomst met onmiddellijke ingang te
                  ontbinden. Ontbinding kan alleen via een aangetekende brief.
                </li>
              </ol>
              <h4>Artikel 12: Overdracht van rechten</h4>
              <ol>
                <li>
                  Rechten van een partij uit deze overeenkomst kunnen niet
                  worden overgedragen zonder de voorafgaande schriftelijke
                  instemming van de andere partij. Deze bepaling geldt als een
                  beding met goederenrechtelijke werking zoals bedoeld in
                  artikel 3:83, tweede lid, Burgerlijk Wetboek.
                </li>
              </ol>
              <h4>Artikel 13: Eigendomsvoorbehoud en retentierecht</h4>
              <ol>
                <li>
                  De bij verkoper aanwezige zaken en geleverde zaken en
                  onderdelen blijven eigendom van verkoper totdat koper de
                  gehele afgesproken prijs heeft betaald. Tot die tijd kan
                  verkoper zich beroepen op zijn eigendomsvoorbehoud en de zaken
                  terugnemen.
                </li>
                <li>
                  Indien de overeengekomen vooruit te betalen bedragen niet of
                  niet op tijd worden voldaan, heeft verkoper het recht om de
                  werkzaamheden op te schorten totdat het overeengekomen deel
                  alsnog is voldaan. Er is dan sprake van schuldeisersverzuim.
                  Een verlate levering kan in dat geval niet aan verkoper worden
                  tegengeworpen.
                </li>
                <li>
                  Verkoper is niet bevoegd de onder zijn eigendomsvoorbehoud
                  vallende zaken te verpanden noch op enige andere wijze te
                  bezwaren.
                </li>
                <li>
                  Verkoper verplicht zich de onder eigendomsvoorbehoud aan koper
                  geleverde zaken te verzekeren en verzekerd te houden tegen
                  brand, ontploffings- en waterschade alsmede tegen diefstal en
                  de polis op eerste verzoek ter inzage te geven.
                </li>
                <li>
                  Indien zaken nog niet zijn geleverd, maar de overeengekomen
                  vooruitbetaling of prijs niet conform afspraak is voldaan,
                  heeft verkoper het recht van retentie. De zaak wordt dan niet
                  geleverd totdat koper volledig en conform afspraak heeft
                  betaald.
                </li>
                <li>
                  In geval van liquidatie, insolventie of surseance van betaling
                  van koper zijn de verplichtingen van koper onmiddellijk
                  opeisbaar.
                </li>
              </ol>
              <h4>Artikel 14: Aansprakelijkheid</h4>
              <ol>
                <li>
                  Iedere aansprakelijkheid voor schade, voortvloeiende uit of
                  verband houdende met de uitvoering van een overeenkomst, is
                  steeds beperkt tot het bedrag dat in het desbetreffende geval
                  door de gesloten aansprakelijkheidsverzekering(en) wordt
                  uitbetaald. Dit bedrag wordt vermeerderd met het bedrag van
                  het eigen risico volgens de desbetreffende polis.
                </li>
                <li>
                  Niet uitgesloten is de aansprakelijkheid van verkoper voor
                  schade die het gevolg is van opzet of bewuste roekeloosheid
                  van verkoper of zijn leidinggevende ondergeschikten.
                </li>
              </ol>
              <h4>Artikel 15: Klachtplicht</h4>
              <ol>
                <li>
                  Koper is verplicht klachten over de verrichte werkzaamheden
                  direct te melden aan verkoper. De klacht bevat een zo
                  gedetailleerd mogelijke omschrijving van de tekortkoming,
                  zodat verkoper in staat is hierop adequaat te reageren.
                </li>
                <li>
                  Is een klacht gegrond, dan is verkoper gehouden het goed te
                  herstellen en eventueel te vervangen.
                </li>
              </ol>
              <h4>Artikel 16: Garanties</h4>
              <ol>
                <li>
                  Indien in de overeenkomst garanties zijn opgenomen, geldt het
                  hiernavolgende. Verkoper garandeert dat het verkochte aan de
                  overeenkomst beantwoordt, dat het zonder gebreken zal
                  functioneren en dat het geschikt is voor het gebruik dat koper
                  voornemens is ervan te maken. Deze garantie geldt voor een
                  periode van twee kalenderjaren na ontvangst van het verkochte
                  door koper.
                </li>
                <li>
                  De bedoelde garantie strekt ertoe om tussen verkoper en koper
                  een zodanige risicoverdeling tot stand te brengen dat de
                  gevolgen van een inbreuk op een garantie steeds volledig voor
                  rekening en risico van verkoper komen en dat verkoper zich ter
                  zake een inbreuk op een garantie nooit kan beroepen op artikel
                  6:75 BW. Het bepaalde in de vorige zin geldt ook als de
                  inbreuk bij koper bekend was of bekend had kunnen zijn door
                  het verrichten van onderzoek.
                </li>
                <li>
                  De genoemde garantie geldt niet wanneer het gebrek is ontstaan
                  als gevolg van onoordeelkundig of oneigenlijk gebruik of
                  wanneer - zonder toestemming - koper of derden wijzigingen
                  hebben aangebracht dan wel geprobeerd hebben aan te brengen of
                  het gekochte hebben gebruikt voor doeleinden waarvoor het niet
                  bestemd is.
                </li>
                <li>
                  Indien de door verkoper verstrekte garantie betrekking heeft
                  op een door een derde geproduceerde zaak is de garantie
                  beperkt tot de garantie die door die producent wordt
                  verstrekt.
                </li>
              </ol>
              <h4>Artikel 17: Toepasselijk recht en bevoegde rechter</h4>
              <ol>
                <li>
                  Op iedere overeenkomst tussen partijen is uitsluitend het
                  Nederlands recht van toepassing.
                </li>
                <li>
                  De Nederlandse rechter in het arrondissement waar Visbar Beet
                  is gevestigd/praktijk houdt/kantoor houdt is exclusief bevoegd
                  om kennis te nemen van eventuele geschillen tussen partijen,
                  tenzij de wet dwingend anders voorschrijft.
                </li>
                <li>
                  De toepasselijkheid van het Weens Koopverdrag is uitgesloten.
                </li>
                <li>
                  Wanneer in een gerechtelijke procedure &eacute;&eacute;n of
                  meerdere bepalingen van deze algemene voorwaarden als
                  onredelijk bezwarend worden aangemerkt, dan blijven de overige
                  bepalingen onverminderd van kracht.{" "}
                </li>
              </ol>
            </Col>
            <Col></Col>
          </Row>
        </Container>
      </div>
    </PublicWrapper>
  );
};

export default TermsAndConditions;
