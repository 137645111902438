import React from "react";
import bsCustomFileInput from "bs-custom-file-input";

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";

class ImageFileInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedFile: null,
      file: null
    };
  }

  componentDidMount() {
    bsCustomFileInput.init();
  }

  componentDidUpdate() {
    if (this.props.onChange) {
      this.props.onChange();
    }
  }

  onUpdate = event => {
    this.setState({
      selectedFile: event.target.files[0],
      file: URL.createObjectURL(event.target.files[0])
    });
  };

  render() {
    return (
      <Container>
        <Form.Group controlId={this.props.content.name}>
          <Row>
            <Form.Label>{this.props.content.label}</Form.Label>
          </Row>
          <Row>
            <Col md={4}>
              <div className="admin-image-preview">
                <img className="admin-image-preview" src={this.state.file} />
              </div>
            </Col>
            <Col>
              <Form.File id={this.props.content.name}>
                <Row>
                  <Col md={11}>
                    <Form.File.Input onChange={this.onUpdate} />
                  </Col>
                  <Col md={1}>
                    <Button variant="danger">
                      <FontAwesomeIcon icon={faTrashAlt} />
                    </Button>
                  </Col>
                </Row>
              </Form.File>
            </Col>
          </Row>
        </Form.Group>
      </Container>
    );
  }
}

export default ImageFileInput;
