import React from "react";
import { FirestoreDocument } from "react-firestore";

import PublicWrapper from "../../../components/public/PublicWrapper";

import { LinkContainer } from "react-router-bootstrap";

import { BrowserView, MobileView } from "react-device-detect";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faPhoneAlt,
  faMapMarkerAlt,
} from "@fortawesome/free-solid-svg-icons";

import { ReactComponent as Logo } from "../../../assets/logo.svg";
import Video from "../../../assets/visbarbeet.mp4";
import Image from "../../../assets/video-background.jpg";
import BravourLogo from "../../../assets/bravourlogo.png";

import Error from "../../misc/Error";

const Home = () => (
  <PublicWrapper>
    <BrowserView>
      <>
        <video
          id='myVideo'
          poster={Image}
          loop
          muted
          autoPlay
          src={Video}
          type='video/mp4'
        />

        <div id='' className='page-background-video'>
          <Container className='website-content px-0'>
            <Row className='mt-1 mt-md-1 mr-0'>
              <Col xs={false} md={2}></Col>
              <Col md={8} className='text-center mb-5'>
                <Logo
                  className='home-logo'
                  style={{ height: `30%`, width: `30%` }}
                />
                <p className='home-text text-left mb-4 mt-4'>
                  Welkom op de website van Visbar Beet!
                </p>

                <p className='home-text text-left mb-4'>
                  Wij hebben tijdens de tweede corona lockdown besloten om de
                  deuren definitief te sluiten.
                  <br />
                  Vijf jaar hebben wij met veel passie en plezier Visbar Beet
                  gedraaid. Maar nu is het tijd voor een nieuw hoofdstuk.
                </p>

                <p className='home-text text-left mb-4'>
                  {" "}
                  Wij heten u dan ook heel graag welkom bij ons totaal
                  vernieuwde restaurant op de vertrouwde Dapperstraat 26.
                </p>

                <p className='home-text text-left mb-4'>
                  Lees snel meer over Bar Bistro Bravour op onze website.
                  <br />
                  <br />
                  <a href='https://www.barbistrobravour.nl'>
                    <Button className='nav-button public-webshop-shopping-cart-button'>
                      <span className='px-1'>www.barbistrobravour.nl</span>
                    </Button>
                  </a>
                </p>

                <p className='home-text text-left mb-4'>
                  Wij hopen jullie daar snel te mogen ontvangen. <br />
                  Liefs, Team Bravour
                </p>

                <img src={BravourLogo} />

                {/* 
                <Button
                  className="nav-button public-webshop-shopping-cart-button"
                  onClick={() => window.FT.widgets.get().toggle()}
                >
                  <span className="px-1">Reserveren</span>
                </Button>
                */}
              </Col>
              <Col xs={false} md={2}></Col>
            </Row>
            {/*
            <Container className='footer'>
              <FirestoreDocument path={"config/general"}>
                {({ error, isLoading, data }) => {
                  if (isLoading) {
                    return null;
                  }
                  if (error) {
                    return null;
                  }

                  return (
                    <Row>
                      <Col md={8} className='info d-none d-lg-block'>
                        <Row>
                          {data.emailAddress[0] ? (
                            <Col xs={"auto"}>
                              <FontAwesomeIcon icon={faEnvelope} />
                              <a
                                className='ml-2 text-light'
                                href={`mailto:${data.emailAddress[2]}`}
                              >
                                {data.emailAddress[1]}
                              </a>
                            </Col>
                          ) : null}
                          {data.phoneNumber[0] ? (
                            <Col xs={"auto"}>
                              <FontAwesomeIcon icon={faPhoneAlt} />
                              <a
                                className='ml-2 text-light'
                                href={`tel:${data.phoneNumber[2]}`}
                              >
                                {data.phoneNumber[1]}
                              </a>
                            </Col>
                          ) : null}
                          {data.address[0] ? (
                            <Col xs={"auto"}>
                              <FontAwesomeIcon icon={faMapMarkerAlt} />
                              <a
                                className='ml-2 text-light'
                                href={data.address[2]}
                              >
                                {data.address[1]}
                              </a>
                            </Col>
                          ) : null}
                        </Row>
                      </Col>
                      <Col className='legal d-block d-lg-none text-left'>
                        <DropdownButton
                          className='footer-dropdown'
                          id='dropdown-menu-align-right'
                          title='Contact'
                        >
                          <Dropdown.Item href='mailto:info@visbarbeet.nl'>
                            <FontAwesomeIcon
                              className='mr-2'
                              icon={faEnvelope}
                            />{" "}
                            info@visbarbeet.nl
                          </Dropdown.Item>
                          <Dropdown.Item href='tel:+31203348998'>
                            <FontAwesomeIcon
                              className='mr-2'
                              icon={faPhoneAlt}
                            />
                            020-3348998
                          </Dropdown.Item>
                          <Dropdown.Item href='https://www.google.com/maps/place/Visbar+Beet/@52.3641937,4.9263532,15z/data=!4m5!3m4!1s0x0:0x6c443d780bf9503f!8m2!3d52.3641937!4d4.9263532'>
                            <FontAwesomeIcon
                              className='mr-2'
                              icon={faMapMarkerAlt}
                            />{" "}
                            Dapperstraat 26, Amsterdam
                          </Dropdown.Item>
                        </DropdownButton>
                      </Col>
                      <Col className='legal text-right'>
                        <DropdownButton
                          className='footer-dropdown'
                          id='dropdown-menu-align-right'
                          title='Voorwaarden'
                        >
                          <LinkContainer to='/terms-and-conditions'>
                            <Dropdown.Item>Algemene voorwaarden</Dropdown.Item>
                          </LinkContainer>
                          <LinkContainer to='/privacy'>
                            <Dropdown.Item>Privacy statement</Dropdown.Item>
                          </LinkContainer>
                          <LinkContainer to='/cookies'>
                            <Dropdown.Item>Cookie statement</Dropdown.Item>
                          </LinkContainer>
                          <LinkContainer to='/disclaimer'>
                            <Dropdown.Item>Disclaimer</Dropdown.Item>
                          </LinkContainer>
                        </DropdownButton>
                      </Col>
                    </Row>
                  );
                }}
              </FirestoreDocument>
            </Container>
                */}
          </Container>
        </div>
      </>
    </BrowserView>
    <MobileView>
      <div id='home' className='page-background-home'>
        <Container className='website-content px-0'>
          <Row className='mt-1 mr-0'>
            <Col xs={false} md={6}></Col>
            <Col md={6} className='text-center mb-5'>
              <Logo
                className='home-logo'
                style={{ height: `20%`, width: `50%` }}
              />
              <p className='home-text text-left mb-4 mt-4'>
                Welkom op de website van Visbar Beet!
              </p>

              <p className='home-text text-left mb-4'>
                Wij hebben tijdens de tweede corona lockdown besloten om de
                deuren definitief te sluiten.
                <br />
                Vijf jaar hebben wij met veel passie en plezier Visbar Beet
                gedraaid. Maar nu is het tijd voor een nieuw hoofdstuk.
              </p>

              <p className='home-text text-left mb-4'>
                {" "}
                Wij heten u dan ook heel graag welkom bij ons totaal vernieuwde
                restaurant op de vertrouwde Dapperstraat 26.
              </p>

              <p className='home-text text-left mb-4'>
                Lees snel meer over Bar Bistro Bravour op onze website.
                <br />
                <br />
                <a href='https://www.barbistrobravour.nl'>
                  <Button className='nav-button public-webshop-shopping-cart-button'>
                    <span className='px-1'>www.barbistrobravour.nl</span>
                  </Button>
                </a>
              </p>

              <p className='home-text text-left mb-4'>
                Wij hopen jullie daar snel te mogen ontvangen. <br />
                Liefs, Team Bravour
              </p>

              {/* 
                <Button
                  className="nav-button public-webshop-shopping-cart-button"
                  onClick={() => window.FT.widgets.get().toggle()}
                >
                  <span className="px-1">Reserveren</span>
                </Button>
                */}
            </Col>
          </Row>
          {/* 
          <Container className='footer'>
            <Row>
              <Col md={8} className='info d-none d-lg-block'>
                <Row>
                  <Col xs={"auto"}>
                    <FontAwesomeIcon icon={faEnvelope} />
                    <a
                      className='ml-2 text-light'
                      href='mailto:info@visbarbeet.nl'
                    >
                      info@visbarbeet.nl
                    </a>
                  </Col>
                  <Col xs={"auto"}>
                    <FontAwesomeIcon icon={faPhoneAlt} />
                    <a className='ml-2 text-light' href='tel:+31203348998'>
                      020-3348998
                    </a>
                  </Col>
                  <Col xs={"auto"}>
                    <FontAwesomeIcon icon={faMapMarkerAlt} />
                    <a
                      className='ml-2 text-light'
                      href='https://www.google.com/maps/place/Visbar+Beet/@52.3641937,4.9263532,15z/data=!4m5!3m4!1s0x0:0x6c443d780bf9503f!8m2!3d52.3641937!4d4.9263532'
                    >
                      Dapperstraat 26, Amsterdam
                    </a>
                  </Col>
                </Row>
              </Col>
              <Col className='legal d-block d-lg-none text-left'>
                <DropdownButton
                  className='footer-dropdown'
                  id='dropdown-menu-align-right'
                  title='Contact'
                >
                  <Dropdown.Item href='mailto:info@visbarbeet.nl'>
                    <FontAwesomeIcon className='mr-2' icon={faEnvelope} />{" "}
                    info@visbarbeet.nl
                  </Dropdown.Item>
                  <Dropdown.Item href='tel:+31203348998'>
                    <FontAwesomeIcon className='mr-2' icon={faPhoneAlt} />
                    020-3348998
                  </Dropdown.Item>
                  <Dropdown.Item href='https://www.google.com/maps/place/Visbar+Beet/@52.3641937,4.9263532,15z/data=!4m5!3m4!1s0x0:0x6c443d780bf9503f!8m2!3d52.3641937!4d4.9263532'>
                    <FontAwesomeIcon className='mr-2' icon={faMapMarkerAlt} />{" "}
                    Dapperstraat 26, Amsterdam
                  </Dropdown.Item>
                </DropdownButton>
              </Col>
              <Col className='legal text-right'>
                <DropdownButton
                  className='footer-dropdown'
                  id='dropdown-menu-align-right'
                  title='Voorwaarden'
                >
                  <LinkContainer to='/terms-and-conditions'>
                    <Dropdown.Item>Algemene voorwaarden</Dropdown.Item>
                  </LinkContainer>
                  <LinkContainer to='/privacy'>
                    <Dropdown.Item>Privacy statement</Dropdown.Item>
                  </LinkContainer>
                  <LinkContainer to='/cookies'>
                    <Dropdown.Item>Cookie statement</Dropdown.Item>
                  </LinkContainer>
                  <LinkContainer to='/disclaimer'>
                    <Dropdown.Item>Disclaimer</Dropdown.Item>
                  </LinkContainer>
                </DropdownButton>
              </Col>
            </Row>
          </Container>
       */}
        </Container>
      </div>
    </MobileView>
  </PublicWrapper>
);

export default Home;
