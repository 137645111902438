import React from "react";
import { FirestoreCollection } from "react-firestore";
import { LinkContainer } from "react-router-bootstrap";

import AdminWrapper from "../../../../components/admin/AdminWrapper";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

import Error from "../../../misc/Error";
import updateCategory from "../../../../actions/webshop/category/updateCategory";
import deleteCategory from "../../../../actions/webshop/category/deleteCategory";
import CategoryForm from "./CategoryForm";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLongArrowAltLeft } from "@fortawesome/free-solid-svg-icons";

const CategoryEdit = ({ match, history }) => (
  <AdminWrapper>
    <FirestoreCollection
      path={"webshop_categories"}
      filter={["slug", "==", match.params.slug]}
    >
      {({ error, isLoading, data }) => {
        if (error) {
          return <Error error={error} />;
        }

        if (isLoading) {
          return <p>loading...</p>;
        }

        if (data.length === 0) {
          return <Error />;
        }

        const category = data[0];

        return (
          <>
            <Row className="admin-page-header">
              <Col md={10} className="admin-page-title text-left">
                <Row>
                  <Col md="auto" className="d-flex align-items-center">
                    <Button variant="light" onClick={() => history.goBack()}>
                      <FontAwesomeIcon icon={faLongArrowAltLeft} />
                    </Button>
                  </Col>
                  <Col>
                    <h1>Edit Category</h1>
                  </Col>
                </Row>
              </Col>
              <Col md={2} className="admin-action-buttons">
                <Button
                  onClick={() =>
                    deleteCategory(category).then(() =>
                      history.push(`../categories/`)
                    )
                  }
                  variant="danger"
                >
                  Delete
                </Button>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <CategoryForm
                  context="edit"
                  category={category}
                  onSubmit={(values) =>
                    updateCategory(category.id, values).then(() =>
                      history.push(`../../categories`)
                    )
                  }
                />
              </Col>
            </Row>
          </>
        );
      }}
    </FirestoreCollection>
  </AdminWrapper>
);

export default CategoryEdit;
