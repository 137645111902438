import React from "react";
import { FirestoreCollection } from "react-firestore";

import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import FormControl from "react-bootstrap/FormControl";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";

import ImageFileInput from "../../../components/admin/formcontrols/ImageFileInput";

import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import Error from "../../misc/Error";

class PageForm extends React.Component {
  state = {
    title_valid: [true, ""],
    description_valid: [true, ""],
    changes_made: false,
    show_validation: false,
    editorState: EditorState.createEmpty(),
    editorHasFocus: false,
  };

  componentDidMount() {
    if (this.props.page) {
      this.setState({
        editorState: EditorState.createWithContent(
          convertFromRaw(this.props.page.description)
        ),
      });
    } else {
      this.setState({
        title_valid: [false, "Please provide a value for title."],
        description_valid: [false, "Please provide a value for title."],
      });
    }
  }

  onContentChange = () => {
    console.log("It worked!!");
  };

  onChange = (event) => {
    this.fieldValitation(event.target.name, event.target.value.length);
    this.setState({ changes_made: true, show_validation: false });
  };

  onEditorChange = (editorState) => {
    this.setState({
      editorState: editorState,
      changes_made: true,
    });
  };

  fieldValitation = (fieldname, fieldvalue_length) => {
    switch (fieldname) {
      case "title":
        if (fieldvalue_length <= 3) {
          this.setState({
            title_valid: [
              false,
              "Title needs to be at least 3 characters long.",
            ],
          });
          break;
        } else if (fieldvalue_length > 24) {
          this.setState({
            title_valid: [
              false,
              "Jikes! That's too long for a title, keep it under 24 characters..",
            ],
          });
          break;
        } else {
          this.setState({
            title_valid: [true, ""],
          });
          break;
        }
      default:
        break;
    }
  };

  formValidation = () => {
    if (this.state.title_valid[0] == false || this.state.description_valid[0]) {
      return false;
    } else {
      return true;
    }
  };

  onSubmit = (event) => {
    event.preventDefault();

    if (this.formValidation()) {
      const { title } = event.target.elements;
      const values = {
        title: title.value,
        description: convertToRaw(this.state.editorState.getCurrentContent()),
      };
      this.props.onSubmit(values);
    } else {
      this.setState({ show_validation: true });
    }
  };

  getAdminPageContentFormInput = (content) => {
    switch (content.type) {
      case "image": {
        return null;
        /*
          <ImageFileInput
            content={content}
            onChange={this.onContentChange}
          ></ImageFileInput>
          */
      }
      case "text": {
        return <p>Show some text inputs</p>;
      }
      case "button": {
        return <p>Show some button inputs</p>;
      }
      default:
        return <p>{content.type}</p>;
    }
  };

  render() {
    return (
      <Form onSubmit={this.onSubmit}>
        <Tabs
          defaultActiveKey="general"
          id="uncontrolled-tab-example"
          className="admin-tabs"
        >
          <Tab eventKey="general" title="General">
            <Container className="admin-form-container">
              <Form.Group controlId="pageTitle">
                <Form.Label>Page title</Form.Label>
                <Form.Control
                  name="title"
                  onChange={this.onChange}
                  defaultValue={this.props.page ? this.props.page.title : null}
                />
                <Form.Control.Feedback
                  type="invalid"
                  style={
                    this.state.show_validation ? { display: "inline" } : {}
                  }
                >
                  {this.state.title_valid[0] ? "" : this.state.title_valid[1]}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <Form.Label>Page Description</Form.Label>
                <Editor
                  editorState={this.state.editorState}
                  toolbarClassName="editor-toolbar"
                  wrapperClassName="editor-wrapper"
                  editorClassName={
                    this.state.hasFocus ? "form-control-focus" : "form-control"
                  }
                  toolbar={{
                    options: ["inline", "emoji", "history"],
                    inline: {
                      options: ["bold", "italic", "underline"],
                    },
                  }}
                  onEditorStateChange={this.onEditorChange}
                  onFocus={() => this.setState({ editorHasFocus: true })}
                  onBlur={() => this.setState({ editorHasFocus: false })}
                  stripPastedStyles={true}
                />
                {this.state.description_valid[0] ? null : (
                  <Form.Control.Feedback
                    type="invalid"
                    style={
                      this.state.show_validation ? { display: "inline" } : {}
                    }
                  >
                    {this.state.description_valid[1]}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Container>
          </Tab>
          <Tab eventKey="content" title="Content">
            <Container className="admin-form-container">
              <FirestoreCollection
                path={"pages/" + this.props.page.id + "/content"}
              >
                {({ error, isLoading, data }) => {
                  if (error) {
                    return <Error error={error} />;
                  }

                  if (isLoading) {
                    return <p>loading...</p>;
                  }

                  if (data.length === 0) {
                    return <p>No content..</p>;
                  }

                  return (
                    <div>
                      {data.map((content) => (
                        <div key={content.name}>
                          {this.getAdminPageContentFormInput(content)}
                        </div>
                      ))}
                    </div>
                  );
                }}
              </FirestoreCollection>
            </Container>
          </Tab>
        </Tabs>
        <Button variant="primary" type="submit">
          Save
        </Button>
      </Form>
    );
  }
}

export default PageForm;
