export const Emojis = [
  "😀",
  "😁",
  "😂",
  "😃",
  "😉",
  "😋",
  "😎",
  "😍",
  "😗",
  "🤗",
  "🤔",
  "😣",
  "😫",
  "😴",
  "😌",
  "🤓",
  "😛",
  "😜",
  "😠",
  "😇",
  "😷",
  "😈",
  "👻",
  "😺",
  "😸",
  "😹",
  "😻",
  "😼",
  "😽",
  "🙀",
  "🙈",
  "🙉",
  "🙊",
  "👼",
  "👮",
  "🕵",
  "💂",
  "👳",
  "🎅",
  "👸",
  "👰",
  "👲",
  "🙍",
  "🙇",
  "🚶",
  "🏃",
  "💃",
  "⛷",
  "🏂",
  "🏌",
  "🏄",
  "🚣",
  "🏊",
  "⛹",
  "🏋",
  "🚴",
  "👫",
  "💪",
  "👈",
  "👉",
  "👉",
  "👆",
  "🖕",
  "👇",
  "🖖",
  "🤘",
  "🖐",
  "👌",
  "👍",
  "👎",
  "✊",
  "👊",
  "👏",
  "🙌",
  "🙏",
  "🐵",
  "🐶",
  "🐇",
  "🐥",
  "🐸",
  "🐌",
  "🐛",
  "🐜",
  "🐝",
  "🍉",
  "🍄",
  "🍔",
  "🍤",
  "🍨",
  "🍪",
  "🎂",
  "🍰",
  "🍾",
  "🍷",
  "🍸",
  "🍺",
  "🌍",
  "🚑",
  "⏰",
  "🌙",
  "🌝",
  "🌞",
  "⭐",
  "🌟",
  "🌠",
  "🌨",
  "🌩",
  "⛄",
  "🔥",
  "🎄",
  "🎈",
  "🎉",
  "🎊",
  "🎁",
  "🎗",
  "🏀",
  "🏈",
  "🎲",
  "🔇",
  "🔈",
  "📣",
  "🔔",
  "🎵",
  "🎷",
  "💰",
  "🖊",
  "📅",
  "✅",
  "❎",
  "💯",
  "✌",
  "😂",
  "😝",
  "😁",
  "😱",
  "👉",
  "🙌",
  "🍻",
  "🔥",
  "🌈",
  "☀",
  "🎈",
  "🌹",
  "💄",
  "🎀",
  "⚽",
  "🎾",
  "🏁",
  "😡",
  "👿",
  "🐻",
  "🐶",
  "🐬",
  "🐟",
  "🍀",
  "👀",
  "🚗",
  "🍎",
  "💝",
  "💙",
  "👌",
  "❤",
  "😍",
  "😉",
  "😓",
  "😳",
  "💪",
  "💩",
  "🍸",
  "🔑",
  "💖",
  "🌟",
  "🎉",
  "🌺",
  "🎶",
  "👠",
  "🏈",
  "⚾",
  "🏆",
  "👽",
  "💀",
  "🐵",
  "🐮",
  "🐩",
  "🐎",
  "💣",
  "👃",
  "👂",
  "🍓",
  "💘",
  "💜",
  "👊",
  "💋",
  "😘",
  "😜",
  "😵",
  "🙏",
  "👋",
  "🚽",
  "💃",
  "💎",
  "🚀",
  "🌙",
  "🎁",
  "⛄",
  "🌊",
  "⛵",
  "🏀",
  "🎱",
  "💰",
  "👶",
  "👸",
  "🐰",
  "🐷",
  "🐍",
  "🐫",
  "🔫",
  "👄",
  "🚲",
  "🍉",
  "💛",
  "💚",
];
