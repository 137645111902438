import Firebase from "firebase/app";
import ReactGA from "react-ga";
import slugify from "slugify";

import { prepareDocForCreate } from "../helpers/firestoreHelpers";

const createPage = values => {
  ReactGA.event({
    category: "Page",
    action: "Create page"
  });

  values.slug = slugify(values.title, { lower: true });
  //values.slug = slugify(values.description, { lower: true });

  return Firebase.firestore()
    .collection("pages")
    .add(prepareDocForCreate(values))
    .then(() => values)
    .catch(error => {
      alert(`Whoops, couldn't create the page: ${error.message}`);
    });
};

export default createPage;
