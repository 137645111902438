import React from "react";

class PublicWrapper extends React.Component {
  componentDidMount() {
    //hiding the Formitable widget on the back-end.
    //this should be moved to an 'admin wrapper' component.
    document.getElementById("formitableWidget").style.visibility = "visible";
  }

  render() {
    return <div id="public">{this.props.children}</div>;
  }
}
export default PublicWrapper;
