import React from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

class DeleteConfirmationModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {}

  render() {
    return (
      <Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        /*dialogClassName="public-webshop-modal"*/
        show={this.props.show}
        onHide={this.props.hide}
      >
        <Modal.Header>
          <Container className="px-0">
            <Row>
              <Col>
                <Modal.Title /*className="public-webshop-modal-title"*/>
                  Delete {this.props.object}
                </Modal.Title>
              </Col>
              <Col className="text-right">
                <Button variant="light" onClick={this.props.hide}>
                  <FontAwesomeIcon icon={faTimes} />
                </Button>
              </Col>
            </Row>
          </Container>
        </Modal.Header>
        <Modal.Body>
          <p>
            Are you sure your want to delete the {this.props.object}{" "}
            <strong>{this.props.name}?</strong>
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.props.hide}>
            Cancel
          </Button>
          <Button variant="danger" onClick={this.props.delete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default DeleteConfirmationModal;
