import React from "react";

import AdminWrapper from "../AdminWrapper";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Error from "../../../views/misc/Error";

class WebshopPageContainer extends React.Component {
  render() {
    return (
      <AdminWrapper>
        <Row className="admin-page-header">
          <Col className="admin-page-title">
            <h1>Webshop / {this.props.title}</h1>
          </Col>
          <Col md={4} className="admin-action-buttons">
            {this.props.buttons}
          </Col>
        </Row>
        <Row>{this.props.children}</Row>
      </AdminWrapper>
    );
  }
}

export default WebshopPageContainer;
