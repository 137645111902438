import Firebase from "firebase/app";
import ReactGA from "react-ga";

const updateCategoriesOrder = (categoriesNewOrder) => {
  ReactGA.event({
    category: "Webshop - Categories",
    action: "Update order of categories",
  });

  const batch = Firebase.firestore().batch();

  return new Promise((resolve) => {
    categoriesNewOrder.forEach((category) => {
      let docRef = Firebase.firestore()
        .collection("webshop_categories")
        .doc(category.id);
      batch.update(docRef, {
        order: category.order,
        updatedBy: Firebase.auth().currentUser
          ? Firebase.auth().currentUser.uid
          : null,
        updatedOn: Firebase.firestore.Timestamp.now(),
      });
    });

    batch.commit().then(function () {
      resolve(`Order of categories has been updated!`);
    });
  });
};

export default updateCategoriesOrder;
