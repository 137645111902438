import {
  ADD_TO_CART,
  REMOVE_FROM_CART,
  SHOW_CART,
  HIDE_CART,
  SHOW_PRODUCT,
  HIDE_PRODUCT,
} from "./action-types/shopping-cart-actions";

export const addToCart = (productId, supplements, price) => {
  return {
    type: ADD_TO_CART,
    productId,
    supplements,
    price,
  };
};

export const removeFromCart = (orderItemId, reducePrice) => {
  return {
    type: REMOVE_FROM_CART,
    orderItemId,
    reducePrice,
  };
};

export const showProduct = (product) => {
  return {
    type: SHOW_PRODUCT,
    product,
  };
};

export const hideProduct = () => {
  return {
    type: HIDE_PRODUCT,
  };
};

export const showCart = () => {
  return {
    type: SHOW_CART,
  };
};

export const hideCart = () => {
  return {
    type: HIDE_CART,
  };
};
