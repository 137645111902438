import React from "react";
import { FirestoreCollection } from "react-firestore";
import storage from "../../../index";
import Error from "../../../views/misc/Error";

import { connect } from "react-redux";
import { addToCart, hideProduct } from "../../../actions/shoppingCartActions";

import ProductSupplement from "./ProductSupplement";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShoppingCart, faTimes } from "@fortawesome/free-solid-svg-icons";

class ProductModalContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      supplements: [],
      productSumPrice: null,
    };
  }

  getImage = (productId, imageName) => {
    let image;

    if (imageName) {
      image = imageName;
      let fileFormat = image.slice(image.length - 4, image.length);
      image = image.slice(0, image.length - 4);
      image += "_512x512" + fileFormat;
    } else {
      image = "default-product-image_512x512.jpg";
    }

    storage
      .ref(`images/thumbs/${image}`)
      .getDownloadURL()
      .then(function (url) {
        if (url != null) {
          var imgModal = document.getElementById("modal-" + productId);
          imgModal.src = url;
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  addProductToCart = (product) => {
    const supplements = this.state.supplements;

    const price = this.state.productSumPrice
      ? this.state.productSumPrice
      : this.props.productPrice;

    let productId = product;

    this.props.addToCart(productId, supplements, price);

    this.handleProductModalClose();
  };

  updateSupplementCount = (supplementId, count, sumPriceSupplement) => {
    //getting the supplement data
    let supplement = { id: supplementId, count: count };
    // getting the current supplements
    let supplements = this.state.supplements;

    if (supplements.length === 0) {
      supplements.push(supplement);
      this.setState({ supplements: supplements });
    } else {
      //checking if a supplement is already added and getting back the position in the array.
      let pos = supplements
        .map(function (e) {
          return e.id;
        })
        .indexOf(supplement.id);

      if (pos === -1) {
        //supplement doesn't exist yet.
        supplements.push(supplement);
        this.setState({ supplements: supplements });
      } else {
        //supplement does exist.
        supplements[pos].count = supplement.count;
        this.setState({ supplements: supplements });
      }
    }

    const basePrice = this.state.productSumPrice
      ? Number(this.state.productSumPrice)
      : Number(this.props.productPrice);

    let sumPrice = basePrice + sumPriceSupplement;
    this.setState({ productSumPrice: sumPrice.toFixed(2) });
  };

  handleProductModalClose = () => {
    this.setState({
      supplements: [],
      productSumPrice: null,
    });
    this.props.hideProduct();
  };

  render() {
    return (
      <FirestoreCollection path={"webshop_products"}>
        {({ error, isLoading, data }) => {
          if (error) {
            return <Error error={error} />;
          }

          if (isLoading) {
            return <p>loading...</p>;
          }

          if (data.length === 0) {
            return <p>Geen producten in deze</p>;
          }

          return data.map((product) => (
            <Modal
              key={product.id}
              scrollable={false}
              aria-labelledby="contained-modal-title-vcenter"
              centered
              dialogClassName="public-webshop-modal"
              show={this.props.productId === product.id}
              onHide={this.handleProductModalClose}
            >
              <Modal.Header>
                <Container className="px-0">
                  <Row>
                    <Col xs="10">
                      <Modal.Title className="public-webshop-modal-title">
                        {product.name}
                      </Modal.Title>
                    </Col>
                    <Col xs="2" className="text-right">
                      <Button
                        variant="light"
                        onClick={this.handleProductModalClose}
                      >
                        <FontAwesomeIcon icon={faTimes} />
                      </Button>
                    </Col>
                  </Row>
                </Container>
              </Modal.Header>
              <Modal.Body className="public-webshop-modal-body">
                <img
                  className="public-webshop-modal-image"
                  id={"modal-" + product.id}
                  src={this.getImage(product.id, product.image)}
                />
                <p className="public-webshop-modal-description">
                  {product.description}
                </p>
                {product.supplements !== null ? (
                  product.supplements.length > 0 ? (
                    <FirestoreCollection path={"webshop_supplements"}>
                      {({ error, isLoading, data }) => {
                        if (error) {
                          return <Error error={error} />;
                        }

                        if (isLoading) {
                          return <p>loading...</p>;
                        }

                        if (data.length === 0) {
                          return null;
                        }
                        let supplement;
                        return (
                          <div className="public-webshop-modal-supplement-container">
                            <p>Wil je daar nog iets bij bestellen?</p>

                            {product.supplements.map(
                              (productSupplement) => (
                                (supplement = data.find(
                                  (o) => o.id === productSupplement.value
                                )),
                                (
                                  <ProductSupplement
                                    supplement={supplement}
                                    updateCount={this.updateSupplementCount}
                                  />
                                )
                              )
                            )}
                          </div>
                        );
                      }}
                    </FirestoreCollection>
                  ) : null
                ) : null}
              </Modal.Body>

              <Modal.Footer>
                <Button
                  variant="primary"
                  onClick={() => this.addProductToCart(product.id)}
                >
                  <span className="px-1">
                    <FontAwesomeIcon icon={faShoppingCart} />
                  </span>
                  <span className="px-2">
                    {"€"}
                    {this.state.productSumPrice
                      ? this.state.productSumPrice
                      : this.props.productPrice}
                  </span>
                </Button>
              </Modal.Footer>
            </Modal>
          ));
        }}
      </FirestoreCollection>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    productId: state.shoppingCart.productId,
    productPrice: state.shoppingCart.productPrice,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addToCart: (product, supplements, price) => {
      dispatch(addToCart(product, supplements, price));
    },
    hideProduct: () => {
      dispatch(hideProduct());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductModalContainer);
