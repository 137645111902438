import React from "react";
import AdminWrapper from "../../../../components/admin/AdminWrapper";

import DataList from "../../../../components/admin/DataList";

class Orders extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <AdminWrapper>
        <DataList
          dataName={{ single: `Order`, plural: `Orders` }}
          collectionPath={`orders`}
          defaultSort={[`created`, `desc`]}
          fields={[
            {
              fieldname: `type`,
              label: `Type`,
              type: "string",
              filter: [
                { value: `delivery`, label: `Bezorging` },
                { value: `pick up`, label: `Ophalen` },
              ],
              values: [],
              hidden: false,
            },
            {
              fieldname: `id`,
              size: "xl",
              label: `Ordernummer`,
              type: "string",
              filter: [],
              values: [],
              hidden: false,
            },
            {
              fieldname: `client`,
              size: "xl",
              label: `Klant`,
              type: "map",
              filter: [],
              values: [`firstname`, `lastname`],
              hidden: false,
            },
            {
              fieldname: `created`,
              type: "timestamp",
              label: `Datum`,
              filter: [],
              values: [],
              hidden: false,
            },
            {
              fieldname: `orderStatus`,
              type: "string",
              label: `Status`,
              filter: [],
              values: [],
              hidden: false,
            },
            {
              fieldname: `paymentCompleted`,
              type: "bool",
              label: `Payment`,
              filter: [],
              values: [],
              hidden: true,
            },
            /*
            {
              fieldname: `test`,
              label: `test`,
              size: "xl",
              filter: [],
              values: [],
              hidden: false,
            },
            {
              fieldname: `test`,
              label: `test`,
              filter: [],
              values: [],
              hidden: false,
            },
            {
              fieldname: `test`,
              label: `test`,
              filter: [],
              values: [],
              hidden: false,
            },
            {
              fieldname: `test`,
              label: `test`,
              filter: [],
              values: [],
              hidden: false,
            },
            {
              fieldname: `test`,
              label: `test`,
              filter: [],
              values: [],
              hidden: false,
            },
            {
              fieldname: `test`,
              label: `test`,
              filter: [],
              values: [],
              hidden: false,
            },
            {
              fieldname: `test`,
              label: `test`,
              filter: [],
              values: [],
              hidden: false,
            },
            {
              fieldname: `test`,
              label: `test`,
              filter: [],
              values: [],
              hidden: false,
            },
            {
              fieldname: `test`,
              label: `test`,
              filter: [],
              values: [],
              hidden: false,
            },
            {
              fieldname: `test`,
              label: `test`,
              filter: [],
              values: [],
              hidden: false,
            },
            {
              fieldname: `test`,
              label: `test`,
              filter: [],
              values: [],
              hidden: false,
            },
            {
              fieldname: `test`,
              label: `test`,
              filter: [],
              values: [],
              hidden: false,
            },
            {
              fieldname: `test`,
              label: `test`,
              filter: [],
              values: [],
              hidden: false,
            },
            {
              fieldname: `test`,
              label: `test`,
              filter: [],
              values: [],
              hidden: false,
            },
            {
              fieldname: `test`,
              label: `test`,
              filter: [],
              values: [],
              hidden: false,
            },
            {
              fieldname: `test`,
              label: `test`,
              filter: [],
              values: [],
              hidden: false,
            },
            {
              fieldname: `test`,
              label: `test`,
              filter: [],
              values: [],
              hidden: false,
            },
            */
          ]}
        ></DataList>
      </AdminWrapper>
    );
  }
}

export default Orders;
