import React from "react";
import { withRouter, Link } from "react-router-dom";

import { FirestoreDocument } from "react-firestore";
import FirebaseAuth from "../views/misc/FirebaseAuth";

import { LinkContainer, NavItem } from "react-router-bootstrap";
import { ReactComponent as Logo } from "../assets/logo.svg";
import { ReactComponent as LogoOnly } from "../assets/logo-only.svg";

import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Button from "react-bootstrap/Button";
import NavDropdown from "react-bootstrap/NavDropdown";

import ProfilePicture from "../views/account/ProfilePicture";

import Error from "../views/misc/Error";

class Navigation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      prevScrollpos: window.pageYOffset,
      scroll: false,
    };
  }

  // Adds an event listener when the component is mount.
  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  // Remove the event listener when the component is unmount.
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  // Hide or show the menu.
  handleScroll = () => {
    const { prevScrollpos } = this.state;

    const currentScrollPos = window.pageYOffset;

    const scroll = currentScrollPos > 50;

    this.setState({
      prevScrollpos: currentScrollPos,
      scroll,
    });
  };

  render() {
    return (
      <header>
        {this.props.location.pathname.startsWith("/admin") ||
        this.props.location.pathname.startsWith("/login") ? (
          <NavigationAdmin
            scroll={this.state.scroll}
            activePage={this.props.history.location}
          />
        ) : (
          <NavigationWebsite
            scroll={this.state.scroll}
            activePage={this.props.history.location}
            webshop={this.props.public.webshop}
          />
        )}
      </header>
    );
  }
}

class NavigationWebsite extends React.Component {
  constructor(props) {
    super(props);
  }

  returnWebshopLink = (active, start) => {
    if (active) {
      return start ? (
        <Nav.Link className="active mx-1">Webshop</Nav.Link>
      ) : (
        <LinkContainer to={"/webshop"}>
          <Nav.Link className="mx-1">Webshop</Nav.Link>
        </LinkContainer>
      );
    } else {
      return null;
    }
  };

  render() {
    return (
      <Navbar
        fixed="top"
        expand="md"
        className={this.props.scroll ? "website-menu-scrolled" : "website-menu"}
        variant="dark"
        collapseOnSelect={true}
      >
        <Container
          className={this.props.scroll ? "page-header-scrolled" : "page-header"}
        >
          <Navbar.Brand as={Link} to="/">
            {!this.props.scroll ? <Logo /> : <LogoOnly />}
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse>
            <Nav className="justify-content-end" activeKey="/">
              <LinkContainer to="/over-ons">
                <Nav.Link className="mx-1">Over ons</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/menu">
                <Nav.Link className="mx-1">Menu</Nav.Link>
              </LinkContainer>
              {/*
 {this.returnWebshopLink(
                this.props.webshop,
                this.props.activePage.pathname.startsWith("/webshop")
              )}

              

              <div className="justify-content-end">
                <Button
                  className="nav-button public-webshop-shopping-cart-button"
                  onClick={() => window.FT.widgets.get().toggle()}
                >
                  <span className="px-1">Reserveren</span>
                </Button>
              </div>
                */}

              <div className="justify-content-end">
                <LinkContainer to={"webshop"}>
                  <Button className="nav-button public-webshop-shopping-cart-button">
                    <span className="px-1">Bestellen</span>
                  </Button>
                </LinkContainer>
              </div>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    );
  }
}

class NavigationAdmin extends React.Component {
  constructor(props) {
    super(props);
  }

  onDropdownItemSelect = () => {};

  render() {
    return (
      <Navbar
        className={this.props.scroll ? "admin-menu" : "admin-menu scrolled"}
        fixed="top"
        variant="dark"
      >
        <Container className="page-header">
          <Navbar.Brand as={Link} to="/admin">
            <Logo />
          </Navbar.Brand>

          <FirebaseAuth>
            {({ isLoading, error, auth }) => {
              if (isLoading) {
                return null;
              }
              if (error) {
                return <Error error={error} />;
              }
              if (auth == null) {
                return null;
              }
              return (
                <FirestoreDocument path={"config/admin"}>
                  {({ error, isLoading, data }) => {
                    if (isLoading) {
                      return null;
                    }
                    if (error) {
                      return null;
                    }

                    return (
                      <Nav className="justify-content-end" activeKey="/">
                        {data.pages ? (
                          <LinkContainer to="/admin/pages">
                            <Nav.Link eventKey="0" className="admin-menu">
                              Pages
                            </Nav.Link>
                          </LinkContainer>
                        ) : null}

                        {data.webshop ? (
                          <NavDropdown
                            title="Webshop"
                            id="nav-dropdown"
                            menuRole="menu"
                            eventKey="1.1"
                            active={this.props.activePage.pathname.startsWith(
                              "/admin/webshop"
                            )}
                          >
                            <LinkContainer to="/admin/webshop/orders">
                              <NavDropdown.Item eventKey="1.1">
                                Orders
                              </NavDropdown.Item>
                            </LinkContainer>
                            <NavDropdown.Divider />
                            <LinkContainer to="/admin/webshop/products">
                              <NavDropdown.Item eventKey="1.2">
                                Products
                              </NavDropdown.Item>
                            </LinkContainer>
                            <LinkContainer to="/admin/webshop/categories">
                              <NavDropdown.Item eventKey="1.3">
                                Categories
                              </NavDropdown.Item>
                            </LinkContainer>
                            <LinkContainer to="/admin/webshop/supplements">
                              <NavDropdown.Item eventKey="1.4">
                                Supplements
                              </NavDropdown.Item>
                            </LinkContainer>
                          </NavDropdown>
                        ) : /*
                          <LinkContainer to="/admin/webshop">
                            <Nav.Link className="admin-menu">Webshop</Nav.Link>
                          </LinkContainer>
                          */
                        null}

                        {data.reservations ? (
                          <LinkContainer to="#">
                            <Nav.Link className="admin-menu" eventKey="2">
                              Reservations
                            </Nav.Link>
                          </LinkContainer>
                        ) : null}

                        <LinkContainer to="/admin/config">
                          <Nav.Link className="admin-menu" eventKey="3">
                            Configuration
                          </Nav.Link>
                        </LinkContainer>
                        <div className="justify-content-end">
                          <LinkContainer to="/" target={"_blank"}>
                            <Button>View site</Button>
                          </LinkContainer>
                          <div className="profile-pic">
                            <ProfilePicture auth={auth} />
                          </div>
                        </div>
                      </Nav>
                    );
                  }}
                </FirestoreDocument>
              );
            }}
          </FirebaseAuth>
        </Container>
      </Navbar>
    );
  }
}

export default withRouter(Navigation);
