import Firebase from "firebase/app";
import ReactGA from "react-ga";

const deleteProduct = (product_id) => {
  ReactGA.event({
    category: "Webshop - Product",
    action: "Delete product",
  });

  return Firebase.firestore()
    .collection("webshop_products")
    .doc(product_id)
    .delete()
    .catch((error) => {
      alert(`Whoops, couldn't delete the product: ${error.message}`);
    });
};

export default deleteProduct;
