import React from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { LinkContainer } from "react-router-bootstrap";

import DeleteConfirmationModal from "../../../../components/admin/DeleteConfirmationModal";
import deleteCategory from "../../../../actions/webshop/category/deleteCategory";

import Container from "react-bootstrap/Container";

import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Dropdown from "react-bootstrap/Dropdown";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEllipsisH,
  faPencilAlt,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";

import Error from "../../../misc/Error";

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <Button
    size={`sm`}
    variant={`light`}
    href=""
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
    <FontAwesomeIcon icon={faEllipsisH} />
  </Button>
));

class CategoryListItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <>
        <Col className="align-self-center">{this.props.title}</Col>

        <Col className="text-right align-self-center">
          <Dropdown>
            <Dropdown.Toggle
              as={CustomToggle}
              id="dropdown-custom-components"
            ></Dropdown.Toggle>
            <Dropdown.Menu>
              <LinkContainer
                to={`/admin/webshop/categories/${this.props.slug}/edit`}
              >
                <Dropdown.Item>
                  <FontAwesomeIcon icon={faPencilAlt} /> Edit
                </Dropdown.Item>
              </LinkContainer>

              <Dropdown.Item onClick={this.props.showDeleteModal}>
                <FontAwesomeIcon icon={faTrashAlt} /> Delete
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Col>
      </>
    );
  }
}

export default CategoryListItem;
