import React from "react";

import { LinkContainer } from "react-router-bootstrap";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

import logOut from "../../actions/logOut";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

class Logout extends React.Component {
  componentDidMount() {
    logOut();
  }

  render() {
    return (
      <div id="admin">
        <Container className="admin-content">
          <Row>
            <Col md={12} className="text-center mt-5">
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                size={"7x"}
                color={"orange"}
              />
              <p className="mt-5">
                Je bent geen geregistreerde administrator voor Visbar Beet.
                <br />
                <br />
                Klopt dit niet? Neem dan contact op met Floris Harms (
                <a href="mailto:floris@visbarbeet.nl">floris@visbarbeet.nl</a>)
              </p>
              <LinkContainer to="/">
                <Button>Naar de website</Button>
              </LinkContainer>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Logout;
