import Firebase from "firebase/app";
import ReactGA from "react-ga";

import { prepareDocForUpdate } from "../../helpers/firestoreHelpers";

const updateSupplement = (supplementId, values) => {
  ReactGA.event({
    supplement: "Webshop - Supplements",
    action: "Update supplement",
  });

  return Firebase.firestore()
    .collection("webshop_supplements")
    .doc(supplementId)
    .update(prepareDocForUpdate(values))
    .catch((error) => {
      alert(`Whoops, couldn't edit the supplement: ${error.message}`);
    });
};

export default updateSupplement;
