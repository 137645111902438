import React from "react";
import { FirestoreCollection } from "react-firestore";
import storage from "../../../../index";

import Select from "react-select";

import bsCustomFileInput from "bs-custom-file-input";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

import Error from "../../../misc/Error";

import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class ProductForm extends React.Component {
  state = {
    name_valid: [true, ""],
    description_valid: [true, ""],
    selectedImageFile: null,
    imageFile: null,
    price_valid: [true, ""],
    category_valid: [true, ""],
    selected_category: null,
    selected_supplements: [],
    changes_made: false,
    show_validation: false,
    stock_valid: [true, ""],
    stock_value: false,
    stocked: null,
    progress: 0,
    saving: false,
  };

  componentWillMount() {
    if (this.props.context === "create") {
      this.setState({
        stock_valid: [false, "Please provide a value"],
        stocked: true,
      });
      console.log("create context");
    } else {
      this.setState({
        stock_value: this.props.product.stock,
        stocked: this.props.product.stocked,
      });
      console.log("edit context");
    }
  }

  componentDidMount() {
    if (this.props.context === "create") {
      this.setState({
        name_valid: [false, "Please provide a name"],
        description: [false, "Please provide a description"],
        price_valid: [false, "Please provide a price"],
        category_valid: [false, "Please select a category"],
      });
    }

    if (this.props.product == null) {
      this.setState({ category_valid: [false, "please select a category"] });
    } else {
      this.setState({
        selected_category: this.props.product.category.id,
        selected_supplements: this.props.product.supplements,
      });
    }
    bsCustomFileInput.init();
    if (this.props.product) {
      this.getImage(false);
    } else {
      this.getImage(true);
    }

    this.setState({
      saving: false,
    });
  }

  componentDidUpdate() {
    if (this.state.imageFile) {
      var img = document.getElementById("product-image-preview");
      img.src = this.state.imageFile;
    }
  }

  onCategorySelectChange = (selectedOption) => {
    this.setState({
      selected_category: selectedOption.value,
      category_valid: [true, ""],
      changes_made: true,
      show_validation: false,
    });
  };

  onSupplementSelectChange = (selectedOptions) => {
    this.setState({
      selected_supplements: selectedOptions,
      changes_made: true,
      show_validation: false,
    });
  };

  onStockOnOffChange = () => {
    if (this.state.stocked === false) {
      let stockCount = document.getElementById("productStock");
      stockCount.value = this.state.stock_value;
    } else {
      this.setState({
        stock_valid: [true, ""],
      });
    }

    this.setState({
      stocked: !this.state.stocked,
    });
  };

  onStockValueChange = (event) => {
    this.setState({
      stock_value: Number(event.target.value),
    });

    this.fieldValitation(
      event.target.name,
      event.target.value.length,
      event.target.value
    );

    this.setState({ changes_made: true, show_validation: false });
  };

  onChange = (event) => {
    this.fieldValitation(
      event.target.name,
      event.target.value.length,
      event.target.value
    );

    this.setState({ changes_made: true, show_validation: false });
  };

  onImageUpdate = (event) => {
    this.setState({
      selectedImageFile: event.target.files[0],
      imageFile: URL.createObjectURL(event.target.files[0]),
    });
  };

  getImage = (defaultImage) => {
    let image;

    if (!defaultImage) {
      image = this.props.product.image;
      let fileFormat = image.slice(image.length - 4, image.length);
      image = image.slice(0, image.length - 4);
      image += "_512x512" + fileFormat;
    } else {
      image = "default-product-image_512x512.jpg";
    }

    storage
      .ref(`images/thumbs/${image}`)
      .getDownloadURL()
      .then(function (url) {
        if (url != null) {
          var img = document.getElementById("product-image-preview");
          img.src = url;
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  imageUpload = () => {
    return new Promise((resolve, reject) => {
      const image = this.state.selectedImageFile;
      const uploadTask = storage.ref(`images/${image.name}`).put(image);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // progress function ...
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          this.setState({ progress });
        },
        (error) => {
          // Error function ...
          console.log(error);
        },
        () => {
          if (this.state.progress == 100) {
            resolve(image.name);
          } else {
            reject();
          }
        }
      );
    });
  };

  fieldValitation = (fieldname, fieldvalue_length, fieldvalue) => {
    switch (fieldname) {
      case "name":
        if (fieldvalue_length <= 3) {
          this.setState({
            name_valid: [false, "Name needs to be at least 3 characters long."],
          });
          break;
        } else if (fieldvalue_length > 128) {
          this.setState({
            name_valid: [
              false,
              "Jikes! That's too long for a name, keep it under 128 characters..",
            ],
          });
          break;
        } else {
          this.setState({
            name_valid: [true, ""],
          });
          break;
        }
      case "description":
        if (fieldvalue_length <= 3) {
          this.setState({
            description_valid: [
              false,
              "Description needs to be at least 3 characters long.",
            ],
          });
          break;
        } else if (fieldvalue_length > 512) {
          this.setState({
            description_valid: [
              false,
              "Jikes! That's too long for a description, keep it under 256 characters..",
            ],
          });
          break;
        } else {
          this.setState({
            description_valid: [true, ""],
          });
          break;
        }
      case "price":
        if (fieldvalue == null) {
          this.setState({
            price_valid: [false, "Please provide a price."],
          });
          break;
        } else if (fieldvalue < 0) {
          this.setState({
            price_valid: [false, "Price needs to be a positive number."],
          });
          break;
        } else {
          this.setState({
            price_valid: [true, ""],
          });
          break;
        }
      case "stock":
        if (fieldvalue == 0) {
          this.setState({
            stock_valid: [false, "Please provide a stock value."],
          });
          break;
        } else if (fieldvalue < 0) {
          this.setState({
            stock_valid: [false, "Stock needs to be a positive number."],
          });
          break;
        } else {
          this.setState({
            stock_valid: [true, ""],
          });
          break;
        }
      case "category":
        if (fieldvalue == null) {
          this.setState({
            price_valid: [false, "Please select a category."],
          });
          break;
        } else {
          this.setState({
            price_valid: [true, ""],
          });
          break;
        }
      default:
        break;
    }
  };

  formValidation = () => {
    if (
      this.state.name_valid[0] === false ||
      this.state.description_valid[0] === false ||
      this.state.price_valid[0] === false ||
      this.state.category_valid[0] === false
    ) {
      return false;
    } else {
      return true;
    }
  };

  onSubmit = (event) => {
    event.preventDefault();

    if (this.formValidation()) {
      const { name, description, price, category } = event.target.elements;

      const values = {
        name: name.value,
        description: description.value,
        price: price.value,
        category: category.value,
        supplements: this.state.selected_supplements,
        stocked: this.state.stocked,
      };

      if (this.state.stock_value !== false) {
        values.stock = this.state.stock_value;
      } else {
        //this doesn't work. need to check. Not important enough now..
        values.stock = 0;
      }

      this.imageUpload()
        .then((image) => {
          this.setState({
            saving: true,
          });
          values.image = image;
          this.props.onSubmit(values);
        })
        .catch((error) => {
          this.setState({
            saving: true,
          });
          this.props.onSubmit(values);
        });
    } else {
      this.setState({ show_validation: true });
    }
  };

  getCategory(categories) {
    const product_category = this.state.selected_category;
    let result = null;

    for (let i = 0; i < categories.length; i++) {
      if (categories[i].value == product_category) {
        result = i;
      }
    }
    return result;
  }

  getCategoryOptions(categories) {
    const options = [];
    {
      categories.map((category) =>
        options.push({ value: category.id, label: category.title })
      );
    }
    return options;
  }

  getSupplements(supplements) {
    const product_supplements = this.state.selected_supplements;

    let result = [];
    if (product_supplements > 0) {
      for (let i = 0; i < supplements.length; i++) {
        for (let n = 0; n < product_supplements.length; n++) {
          if (supplements[i].value == product_supplements[n]) {
            result += i;
          }
        }
      }
    }

    return result;
  }

  getSupplementOptions(categories) {
    const options = [];
    {
      categories.map((supplement) =>
        options.push({ value: supplement.id, label: supplement.name })
      );
    }
    return options;
  }

  render() {
    return (
      <Form onSubmit={this.onSubmit}>
        <Container className="admin-form-container">
          {/* [START NAME INPUT] */}
          <Form.Group controlId="productName">
            <Form.Label>Name</Form.Label>
            <Form.Control
              name="name"
              onChange={this.onChange}
              defaultValue={this.props.product ? this.props.product.name : null}
            />
            <Form.Control.Feedback
              type="invalid"
              style={this.state.show_validation ? { display: "inline" } : {}}
            >
              {this.state.name_valid[0] ? "" : this.state.name_valid[1]}
            </Form.Control.Feedback>
          </Form.Group>
          {/* [END NAME INPUT] */
          /* [START DESCRIPTION INPUT] */}
          <Form.Group controlId="productDescription">
            <Form.Label>Description</Form.Label>
            <Form.Control
              name="description"
              onChange={this.onChange}
              defaultValue={
                this.props.product ? this.props.product.description : null
              }
            />
            <Form.Control.Feedback
              type="invalid"
              style={this.state.show_validation ? { display: "inline" } : {}}
            >
              {this.state.description_valid[0]
                ? ""
                : this.state.description_valid[1]}
            </Form.Control.Feedback>
          </Form.Group>
          {/* [END DESCRIPTION INPUT] */
          /* [START IMAGE INPUT] */}
          <Form.Group controlId="productImage">
            <Row>
              <Form.Label>Product Image</Form.Label>
            </Row>
            <Row>
              <Col md={4}>
                <div className="admin-image-preview">
                  <img
                    id="product-image-preview"
                    className="admin-image-preview"
                  />
                </div>
              </Col>
              <Col>
                <Form.File
                  className="position-relative"
                  onChange={this.onImageUpdate}
                  id="image"
                  feedbackTooltip
                />
              </Col>
            </Row>
          </Form.Group>
          {/* [END IMAGE INPUT] */
          /* [START PRICE INPUT] */}
          <Row>
            <Col>
              <Form.Group controlId="productPrice">
                <Form.Label>Price</Form.Label>
                <Form.Control
                  name="price"
                  type="number"
                  step="0.01"
                  onChange={this.onChange}
                  defaultValue={
                    this.props.product ? this.props.product.price : null
                  }
                />
                <Form.Control.Feedback
                  type="invalid"
                  style={
                    this.state.show_validation ? { display: "inline" } : {}
                  }
                >
                  {this.state.price_valid[0] ? "" : this.state.price_valid[1]}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="productStock">
                <Form.Label>Stock</Form.Label>

                <Row className="d-flex align-content-center flex-wrap">
                  <Col md={1} className="align-self-center">
                    <Form.Check
                      className="pl-3"
                      inline
                      disabled={
                        this.props.context === "create"
                          ? false
                          : this.props.product.published
                          ? true
                          : false
                      }
                      checked={this.state.stocked ? true : false}
                      onChange={this.onStockOnOffChange}
                      id="custom-switch"
                      label=""
                    />
                  </Col>
                  <Col md={11}>
                    {this.state.stocked ? (
                      <>
                        <Form.Control
                          defaultValue={this.state.stock_value}
                          name="stock"
                          type="number"
                          min="0"
                          step="1"
                          disabled={
                            this.props.context === "create"
                              ? false
                              : this.props.product.published
                              ? true
                              : false
                          }
                          onChange={this.onStockValueChange}
                        />
                      </>
                    ) : (
                      <Form.Control
                        name="stock-dummy"
                        disabled={true}
                        value={`No stock is tracked.`}
                      />
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col className="text-right">
                    <Form.Control.Feedback
                      type="invalid"
                      style={
                        this.props.context === "create"
                          ? this.state.show_validation
                            ? { display: "inline" }
                            : {}
                          : this.props.product.published
                          ? { display: "inline" }
                          : this.state.show_validation
                          ? { display: "inline" }
                          : {}
                      }
                    >
                      {this.props.context === "create"
                        ? this.state.stock_valid[0]
                          ? ""
                          : this.state.stock_valid[1]
                        : this.props.product.published
                        ? `Can only be updated if product is not published.`
                        : this.state.stock_valid[0]
                        ? ""
                        : this.state.stock_valid[1]}
                    </Form.Control.Feedback>
                  </Col>
                </Row>
              </Form.Group>
            </Col>
          </Row>
          {/* [END PRICE INPUT] */
          /* [START CATEGORIES INPUT] */}
          <FirestoreCollection path={"webshop_categories"}>
            {({ error, isLoading, data }) => {
              if (error) {
                return <Error error={error} />;
              }

              if (isLoading) {
                return <p>loading...</p>;
              }

              if (data.length === 0) {
                return <Error />;
              }

              const productCategories = this.getCategoryOptions(data);
              const selectedCategory = this.getCategory(productCategories);

              return (
                <Form.Group controlId="productCategory">
                  <Form.Label>Category</Form.Label>

                  <Select
                    name="category"
                    defaultValue={
                      this.props.product
                        ? productCategories[selectedCategory]
                        : null
                    }
                    onChange={this.onCategorySelectChange}
                    options={productCategories}
                  />
                  <Form.Control.Feedback
                    type="invalid"
                    style={
                      this.state.show_validation ? { display: "inline" } : {}
                    }
                  >
                    {this.state.category_valid[0]
                      ? ""
                      : this.state.category_valid[1]}
                  </Form.Control.Feedback>
                </Form.Group>
              );
            }}
          </FirestoreCollection>
          {/* [END CATEGORIES INPUT] */
          /* [START SUPPLEMENTS INPUT] */}
          <FirestoreCollection path={"webshop_supplements"}>
            {({ error, isLoading, data }) => {
              if (error) {
                return <Error error={error} />;
              }

              if (isLoading) {
                return <p>loading...</p>;
              }

              if (data.length === 0) {
                return <Error />;
              }

              const productSupplements = this.getSupplementOptions(data);
              const selectedSupplements = this.getSupplements(
                productSupplements
              );

              return (
                <Form.Group controlId="productSupplements">
                  <Form.Label>Supplements</Form.Label>

                  <Select
                    name="supplements"
                    isMulti
                    defaultValue={
                      this.props.product ? this.props.product.supplements : null
                    }
                    onChange={this.onSupplementSelectChange}
                    options={productSupplements}
                  />
                </Form.Group>
              );
            }}
          </FirestoreCollection>
          {/* [END SUPPLEMEMENTS INPUT] */}
          <Button
            variant="primary"
            type="submit"
            disabled={this.state.saving ? true : false}
          >
            {this.state.saving ? (
              <span>
                <FontAwesomeIcon className="mr-3" icon={faSpinner} spin />
                Saving
              </span>
            ) : (
              `Save`
            )}
          </Button>
        </Container>
      </Form>
    );
  }
}

export default ProductForm;
