import React from "react";
import { FirestoreCollection } from "react-firestore";
import { Link } from "react-router-dom";

import AdminWrapper from "../../../components/admin/AdminWrapper";

import { LinkContainer } from "react-router-bootstrap";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";

import draftToHtml from "draftjs-to-html";

import Error from "../../misc/Error";

const PageList = () => (
  <AdminWrapper>
    <Row className="admin-page-header">
      <Col md={10} className="admin-page-title">
        <h1>My pages</h1>
      </Col>
      <Col md={2} className="admin-action-buttons">
        <LinkContainer to="/admin/pages/new">
          <Button>Add new</Button>
        </LinkContainer>
      </Col>
    </Row>
    <Row>
      <Col md={12}>
        <FirestoreCollection path={"pages"}>
          {({ error, isLoading, data }) => {
            if (error) {
              return <Error error={error} />;
            }

            if (isLoading) {
              return <p>loading...</p>;
            }

            if (data.length === 0) {
              return <p>No pages yet!</p>;
            }

            return (
              <Row>
                {data.map((page) => (
                  <Col md={4}>
                    <Card className="admin-page-card">
                      <Card.Body>
                        <Card.Title>{page.title}</Card.Title>
                        <Card.Text>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: draftToHtml(page.description),
                            }}
                          ></span>
                        </Card.Text>
                      </Card.Body>
                      <Card.Footer className="admin-page-card-footer">
                        <LinkContainer to={`/admin/pages/${page.slug}/edit`}>
                          <Button>Edit</Button>
                        </LinkContainer>
                      </Card.Footer>
                    </Card>
                  </Col>
                ))}
              </Row>
            );
          }}
        </FirestoreCollection>
      </Col>
    </Row>
  </AdminWrapper>
);

export default PageList;
