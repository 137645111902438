import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { FirestoreCollection } from "react-firestore";

import { LinkContainer } from "react-router-bootstrap";

import DeleteConfirmationModal from "../../../../components/admin/DeleteConfirmationModal";
import deleteCategory from "../../../../actions/webshop/category/deleteCategory";

import updateCategoriesOrder from "../../../../actions/webshop/category/updateCategoriesOrder";

import WebshopPageContainer from "../../../../components/admin/webshop/WebshopPageContainer";
import CategoryListItem from "./CategoryListItem";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import Fade from "react-bootstrap/Fade";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGripLines, faSpinner } from "@fortawesome/free-solid-svg-icons";

import Error from "../../../misc/Error";

class CategoryList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      categoriesOrder: [],
      newOrder: null,
      orderChanged: false,
      savingChanges: false,
      alert: [false, "info", "", true],
      toBeDeletedCategory: null,
    };
  }

  componentDidMount() {
    const categoriesOrder = [];

    this.props.categories.forEach((category) => {
      let res = {};
      res.id = category.id;
      res.order = category.order;
      res.title = category.title;
      res.slug = category.slug;
      categoriesOrder.push(res);
    });

    this.setState({
      categoriesOrder: categoriesOrder,
      showDeleteConfirmation: false,
    });
  }

  onDragEnd = (result) => {
    const { destination, source, draggableId } = result;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const categoriesNewOrder = Array.from(this.state.categoriesOrder);

    let updatedCategory = this.state.categoriesOrder.find(
      (category) => category.id === draggableId
    );

    categoriesNewOrder.splice(source.index, 1);
    categoriesNewOrder.splice(destination.index, 0, updatedCategory);

    categoriesNewOrder.forEach((category, index) => (category.order = index));

    this.setState({
      categoriesOrder: categoriesNewOrder,
      orderChanged: true,
    });
  };

  saveOrderChanges = () => {
    this.setState({
      savingChanges: true,
    });
    updateCategoriesOrder(this.state.categoriesOrder).then((result) => {
      this.setState({
        savingChanges: false,
        orderChanged: false,
        alert: [true, "success", result, true],
      });

      setTimeout(() => {
        this.hideAlert();
      }, 2000);
    });
  };

  hideAlert = () => {
    this.setState({
      alert: [false, "info", "", true],
    });
  };

  showDeleteConfirmation = (id, name) => {
    this.setState({
      showDeleteConfirmation: true,
      toBeDeletedCategory: [id, name],
    });
  };

  hideDeleteConfirmation = () => {
    this.setState({
      showDeleteConfirmation: false,
      toBeDeletedCategory: null,
    });

    setTimeout(() => {
      this.hideAlert();
    }, 2000);
  };

  handeleDeleteCategory = (toBeDeletedCategoryId) => {
    const categories = this.state.categoriesOrder;

    categories.splice(
      categories.findIndex((category) => category.id === toBeDeletedCategoryId),
      1
    );

    this.setState({
      alert: [true, "success", "Category was deleted", true],
      categoriesOrder: categories,
    });

    this.hideDeleteConfirmation();
  };

  render() {
    const actionButtons = (
      <LinkContainer to="./categories/new">
        <Button>New Category</Button>
      </LinkContainer>
    );

    return (
      <WebshopPageContainer title={`Categories`} buttons={actionButtons}>
        <Container className="mt-3">
          <div className="w-100 d-flex justify-content-center">
            <Fade in={this.state.alert[0]} timeout={2000} unmountOnExit>
              <Alert
                className="admin-alert"
                show={this.state.alert[0]}
                variant={this.state.alert[1]}
                dismissible={this.state.alert[3]}
                onClose={() => this.hideAlert()}
              >
                {this.state.alert[2]}
              </Alert>
            </Fade>
          </div>
          <Row className="text-right">
            <Col>
              <Button
                className={`list-save-btn`}
                variant={!this.state.orderChanged ? `light` : `success`}
                disabled={!this.state.orderChanged || this.state.savingChanges}
                onClick={() => this.saveOrderChanges()}
              >
                {this.state.savingChanges ? (
                  <FontAwesomeIcon icon={faSpinner} spin />
                ) : (
                  `Save`
                )}
              </Button>
            </Col>
          </Row>

          <Row>
            {this.state.savingChanges ? (
              <Col className="text-center">
                <p>Saving..</p>
              </Col>
            ) : (
              <Col>
                <DragDropContext onDragEnd={this.onDragEnd}>
                  <Droppable droppableId="category-list">
                    {(provided, snapshot) => (
                      <div ref={provided.innerRef} {...provided.droppableProps}>
                        {this.state.categoriesOrder.map((category, index) => (
                          <Draggable
                            key={category.id}
                            draggableId={category.id}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <div
                                index={index}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                              >
                                <Card
                                  className={`admin-list-item my-2 ${
                                    snapshot.isDragging ? `dragging` : ``
                                  }`}
                                >
                                  <Card.Body>
                                    <Row>
                                      <Col
                                        className="list-grip align-self-center"
                                        md={"auto"}
                                        {...provided.dragHandleProps}
                                      >
                                        <FontAwesomeIcon
                                          icon={faGripLines}
                                          className="list-grip-icon"
                                        />
                                      </Col>
                                      <CategoryListItem
                                        id={category.id}
                                        title={category.title}
                                        slug={category.slug}
                                        showDeleteModal={() =>
                                          this.showDeleteConfirmation(
                                            category.id,
                                            category.title
                                          )
                                        }
                                      />
                                    </Row>
                                  </Card.Body>
                                </Card>
                              </div>
                            )}
                          </Draggable>
                        ))}

                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </Col>
            )}
          </Row>
          <DeleteConfirmationModal
            object={`category`}
            name={
              this.state.toBeDeletedCategory !== null
                ? this.state.toBeDeletedCategory[1]
                : null
            } // get title from state
            hide={() => this.hideDeleteConfirmation()}
            show={this.state.showDeleteConfirmation}
            delete={
              () =>
                deleteCategory(
                  this.state.toBeDeletedCategory !== null
                    ? this.state.toBeDeletedCategory[0]
                    : null
                ).then(
                  this.handeleDeleteCategory(this.state.toBeDeletedCategory[0])
                ) // get the id from the state as well
            }
          />
        </Container>
      </WebshopPageContainer>
    );
  }
}

class CategoryListContainer extends React.Component {
  render() {
    return (
      <FirestoreCollection path={"webshop_categories"} sort="order">
        {({ error, isLoading, data }) => {
          if (error) {
            return <Error error={error} />;
          }

          if (isLoading) {
            return <p>loading...</p>;
          }

          if (data.length === 0) {
            return <p>No categories yet!</p>;
          }
          return <CategoryList categories={data} />;
        }}
      </FirestoreCollection>
    );
  }
}

export default CategoryListContainer;
