import React from "react";
import { FirestoreCollection } from "react-firestore";

import PublicWrapper from "../../../components/public/PublicWrapper";

import { PDFObject, OpenParams } from "react-pdfobject";
import { BrowserView, MobileView } from "react-device-detect";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faSpinner } from "@fortawesome/free-solid-svg-icons";

import MenuFile from "../../../assets/static-webpages/menu/menukaart.pdf";

import Error from "../../misc/Error";

const PDFParams = {
  view: `Fit`,
  zoom: `scale`,
};

const Menu = () => (
  <PublicWrapper>
    <div id="menu" className="page-background">
      <Container className="website-content">
        <Row className="mt-1 mt-md-5 mr-0">
          <BrowserView viewClassName="col">
            <PDFObject
              url={MenuFile}
              containerId={`pdf-viewer`}
              pdfOpenParams={PDFParams}
            />
          </BrowserView>

          <Col className="">
            <p>
              Op het menu vind je gecertificeerde en lokaal gevangen vis met
              smaak bereid. overwegend visgerechtjes maar ook vlees en
              vegetarische opties.
            </p>
            <MobileView>
              <p>Klik op de knop hieronder om ons menu te bekijken.</p>
              <div className="text-center">
                <Button
                  className="nav-button public-webshop-shopping-cart-button"
                  href={MenuFile}
                  target="_blank"
                >
                  Bekijk het menu
                </Button>
              </div>
            </MobileView>
          </Col>
        </Row>
      </Container>
    </div>
  </PublicWrapper>
);

export default Menu;
