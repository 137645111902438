import Firebase from "firebase/app";
import ReactGA from "react-ga";

const deleteCategory = (category_id) => {
  ReactGA.event({
    category: "Webshop - Categories",
    action: "Delete category",
  });

  return Firebase.firestore()
    .collection("webshop_categories")
    .doc(category_id)
    .delete()
    .catch((error) => {
      alert(`Whoops, couldn't delete the category: ${error.message}`);
    });
};

export default deleteCategory;
