import React from "react";
import Container from "react-bootstrap/Container";

class AdminWrapper extends React.Component {
  componentDidMount() {
    //hiding the Formitable widget on the back-end.
    //this should be moved to an 'admin wrapper' component.
    document.getElementById("formitableWidget").style.visibility = "hidden";
  }

  render() {
    return (
      <div id="admin">
        <Container className="admin-content">{this.props.children}</Container>
      </div>
    );
  }
}
export default AdminWrapper;
