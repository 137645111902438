import React from "react";

import PublicWrapper from "../../../components/public/PublicWrapper";

import { BrowserView, MobileView } from "react-device-detect";

import OrderList from "../../../components/public/checkout/OrderList";
import CheckoutForm from "../../../components/public/checkout/CheckoutForm";

import Accordion from "react-bootstrap/Accordion";
import Button from "react-bootstrap/Button";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

class CheckOut extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showOrderDetails: false,
    };
  }

  buttonClicked = () => {
    this.setState({
      showOrderDetails: !this.state.showOrderDetails,
    });
  };

  render() {
    return (
      <PublicWrapper>
        <div id="about" className="page-background">
          <Container className="website-content pb-5">
            <BrowserView>
              <Row>
                <OrderList />
                <CheckoutForm />
              </Row>
            </BrowserView>

            <MobileView>
              <Accordion defaultActiveKey="">
                <div className="text-center pb-3">
                  <Accordion.Toggle
                    as={Button}
                    variant="light"
                    onClick={this.buttonClicked}
                    eventKey="0"
                    className="text-center"
                  >
                    {this.state.showOrderDetails
                      ? `Verberg details`
                      : `Toon details`}
                  </Accordion.Toggle>
                </div>

                <Accordion.Collapse eventKey="0">
                  <OrderList />
                </Accordion.Collapse>
              </Accordion>

              <CheckoutForm />
            </MobileView>
          </Container>
        </div>
      </PublicWrapper>
    );
  }
}

export default CheckOut;
