import React from "react";
import { FirestoreCollection } from "react-firestore";

import Select from "react-select";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Error from "../../../misc/Error";

class SupplementForm extends React.Component {
  state = {
    name_valid: [true, ""],
    price_valid: [true, ""],
    type_valid: [true, ""],
    type: null,
    changes_made: false,
    show_validation: false,
    saving: false,
  };

  componentDidMount() {
    if (this.props.context === "create") {
      this.setState({
        name_valid: [false, "Please provide a name"],
        price_valid: [false, "Please provide a price"],
        type_valid: [false, "Please select a type"],
      });
    }
    this.setState({
      saving: false,
    });
  }

  onTypeChange = (selectedOption) => {
    this.setState({
      type: selectedOption.value,
      changes_made: true,
      show_validation: false,
    });

    //Technically shit should be async or something. but this will do for now.
    if (this.state.type_valid[0] === false) {
      this.setState({
        type_valid: [true, ""],
      });
    } else {
      this.fieldValitation("type", null, selectedOption.value);
    }
  };

  onChange = (event) => {
    this.fieldValitation(
      event.target.name,
      event.target.value.length,
      event.target.value
    );
    this.setState({ changes_made: true, show_validation: false });
  };

  fieldValitation = (fieldname, fieldvalue_length, fieldvalue) => {
    switch (fieldname) {
      case "name":
        if (fieldvalue_length <= 1) {
          this.setState({
            name_valid: [false, "Name needs to be at least 1 characters long."],
          });
          break;
        } else if (fieldvalue_length > 24) {
          this.setState({
            name_valid: [
              false,
              "Jikes! That's too long for a name, keep it under 24 characters..",
            ],
          });
          break;
        } else {
          this.setState({
            name_valid: [true, ""],
          });
          break;
        }
      case "price":
        if (fieldvalue == null) {
          this.setState({
            price_valid: [false, "Please provide a price now!."],
          });
          break;
        } else if (fieldvalue < 0) {
          this.setState({
            price_valid: [false, "Price needs to be a positive number."],
          });
          break;
        } else {
          this.setState({
            price_valid: [true, ""],
          });
          break;
        }
      case "type":
        console.log("type..");
        if (this.state.type === null) {
          this.setState({
            type_valid: [false, "Please select a type."],
          });
          break;
        } else {
          this.setState({
            type_valid: [true, ""],
          });
          break;
        }
      default:
        break;
    }
  };

  formValidation = () => {
    if (
      this.state.name_valid[0] === false ||
      this.state.price_valid[0] === false ||
      this.state.type_valid[0] === false
    ) {
      return false;
    } else {
      return true;
    }
  };

  onSubmit = (event) => {
    event.preventDefault();

    if (this.formValidation() && this.state.changes_made) {
      this.setState({
        saving: true,
      });

      const { name, price, type } = event.target.elements;

      const values = {
        name: name.value,
        price: price.value,
        multiple: this.state.type,
      };
      this.props.onSubmit(values);
    } else {
      this.setState({ show_validation: true });
    }
  };

  typeOptionValue = () => {
    if (this.props.supplement.multiple) {
      return 0;
    } else return 1;
  };

  render() {
    const type_options = [
      { value: true, label: "Visitor can add product multiple times" },
      { value: false, label: "Visitor can add only once" },
    ];
    return (
      <Form onSubmit={this.onSubmit}>
        <Container className="admin-form-container">
          <Form.Group controlId="supplementName">
            <Form.Label>Name</Form.Label>
            <Form.Control
              name="name"
              onChange={this.onChange}
              defaultValue={
                this.props.supplement ? this.props.supplement.name : null
              }
            />
            <Form.Control.Feedback
              type="invalid"
              style={this.state.show_validation ? { display: "inline" } : {}}
            >
              {this.state.name_valid[0] ? "" : this.state.name_valid[1]}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="productPrice">
            <Form.Label>Price</Form.Label>
            <Form.Control
              name="price"
              onChange={this.onChange}
              defaultValue={
                this.props.supplement ? this.props.supplement.price : null
              }
            />
            <Form.Control.Feedback
              type="invalid"
              style={this.state.show_validation ? { display: "inline" } : {}}
            >
              {this.state.price_valid[0] ? "" : this.state.price_valid[1]}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Type</Form.Label>
            <Select
              name="type"
              defaultValue={
                this.props.supplement
                  ? type_options[this.typeOptionValue()]
                  : null
              }
              onChange={this.onTypeChange}
              options={type_options}
            />
            <Form.Control.Feedback
              type="invalid"
              style={this.state.show_validation ? { display: "inline" } : {}}
            >
              {this.state.type_valid[0] ? "" : this.state.type_valid[1]}
            </Form.Control.Feedback>
          </Form.Group>

          <Button
            variant="primary"
            type="submit"
            disabled={this.state.saving ? true : false}
          >
            {this.state.saving ? (
              <span>
                <FontAwesomeIcon className="mr-3" icon={faSpinner} spin />
                Saving
              </span>
            ) : (
              `Save`
            )}
          </Button>
        </Container>
      </Form>
    );
  }
}

export default SupplementForm;
