/*

This is a component that will return a list of data based on a Firebase collection.

The list will have the following features that can all be configured:
- Show/hide certain data from collection in list.
- Rename data header
- Pagination
- Sorting
- Searching/filter on search query
- Filter per header for predefined values (booleans, lists?)

Limitation for now will be that only (flat level) data fields of type:
- String;
- Number;
- Date;
- Boolean;
will be supported.

Ideally later support can be added for more in-depth data fields like:
- Array;
- Objects;
- Reference;

You will also need to define what the options are per list item, the following can be set:
- onClick? -> [name, function]
- optionsList? -> [{Name: , Function: ,} ,{..} ,{..}] **THIS MIGHT BE TRICKY..**

List of Props:
dataName || map {single: "value", plural, "value"} || Map of the data to be displayed single and plural. Used for reference..
collectionPath || string || the string that is the 'path' to the Firestore collection. E.g. "orders" or "orders/1312412/orderdetails"
Fields? || [ {field}, .., {field} ] || A list of fields that should be included. See below of more info how to define the fields. If left empty it will just stringyfi all values.
Pagination? || [bool] || Will this data list have pagination. Default false.
Searchable? || [bool] || If set to true the datalist will have a searchfields that filters data bases on the search query (only works on String and Number fields)
DefaultSort? || [string, string] || fieldname, optional if not provided it will default to ID. Formatting ["fieldname", "asc"] (second value can be asc or desc)
ReSortable? || bool || if set to true, this list can be re-sorted by the end user (drag&drop)

Fields:
Map
{ 
fieldname: string, (the fieldname that should be rendered. E.g. `title`)
type: [string, number, timestamp, bool, array, map] > required prop, that sets what type of data will be passed.
size?: string , optional field to set the size of the field (set's the width of the content) options are [xxs, xs,s,m,l,xl, xxl]. Defaults to "m".
label?: string, (provide a value for the header label, default "fieldname")
filter?: [{value: string , label: string }],  (values that can be filtered upon, if empty or null no filter)
values?: [string, .., string], (optional, allows to define sub values for arrays or maps. E.g. ["1", "2"] (arrays) and ["firstname", "lastname"] (map). Empty array or null will take main value of field.
hidden?: bool (values will not be rendered, but can be used for defaultSort and search. default "false")
}

*/

import React from "react";
import { FirestoreCollection } from "react-firestore";

import { Container, Card } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortUp, faSortDown } from "@fortawesome/free-solid-svg-icons";

import Error from "../../views/misc/Error";

class DataList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      error: [false, ""],
      filters: [
        ["type", "pick up"],
        ["orderStatus", "open"],
      ],
      sorting: null,
      defaultSorting: null,
    };
  }

  componentDidMount() {
    let newState = this.state;
    //Checking if required props have been defined..
    if (this.props.collection === null) {
      newState.error = [
        true,
        "No collection has been defined. This is required.",
      ];
    }

    if (this.props.defaultSort) {
      newState.defaultSorting = this.props.defaultSort;
    }

    this.setState(newState);
  }

  addFilterValue = (field, value) => {
    let newFilters = this.state.filters;
    newFilters.push([field, value]);
    this.setState({
      filters: newFilters,
    });
  };

  removeFilterValue = (field, value) => {
    const currentFilters = this.state.filters;
    const newFilters = [];
    if (value === null) {
      //remove all filters for this field.
      newFilters = currentFilters.filter((filter) => filter[0] != field);
    } else {
      //remove only this filter for this field.
      let filterIndex = currentFilters.findIndex(
        (filter) => filter[0] == field && filter[1] == value
      );
      newFilters = currentFilters.splice(filterIndex, 1);
    }
    this.setState({
      filters: newFilters,
    });
  };

  setSorting = (fieldname) => {
    let currentSorting = [];

    if (this.state.sorting != null) {
      currentSorting = this.state.sorting;
    }

    if (fieldname === currentSorting[0]) {
      if (currentSorting[1] === "asc") {
        currentSorting[1] = "desc";
      } else {
        currentSorting[1] = "asc";
      }
    } else {
      currentSorting[0] = fieldname;
      currentSorting[1] = "asc";
    }

    this.setState({
      sorting: currentSorting,
    });
  };

  compareValues(key, order = "asc") {
    // nice function that allows sorting of arrays of objects, either text or number based and ascending or descending results.
    // source: https://www.sitepoint.com/sort-an-array-of-objects-in-javascript/#:~:text=Basic%20Array%20Sorting,in%20Unicode%20code%20point%20order.
    return function innerSort(a, b) {
      if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
        // property doesn't exist on either object
        return 0;
      }

      const varA = typeof a[key] === "string" ? a[key].toUpperCase() : a[key];
      const varB = typeof b[key] === "string" ? b[key].toUpperCase() : b[key];

      let comparison = 0;
      if (varA > varB) {
        comparison = 1;
      } else if (varA < varB) {
        comparison = -1;
      }
      return order === "desc" ? comparison * -1 : comparison;
    };
  }

  filterData = (data) => {
    console.log(data);
    console.log(this.state.filters);

    let newData = data;
    let filters = this.state.filters;

    filters.map((filter) => {
      console.log(filter);
      let filteredData = newData.filter((object) => {
        console.log(object);
        console.log(object[filter[0]]);
        console.log(filter[1]);
        if (object[filter[0]] == filter[1]) {
          return object;
        }
      });
      newData = filteredData;
    });

    console.log(newData);

    return (
      <ListContainer
        data={newData.sort(
          this.compareValues(
            this.state.sorting
              ? (this.state.sorting[0], this.state.sorting[1])
              : this.state.defaultSorting
              ? (this.state.defaultSorting[0], this.state.defaultSorting[1])
              : ("id", "asc")
          )
        )}
        fields={this.props.fields}
        sorting={
          this.state.sorting
            ? this.state.sorting
            : this.state.defaultSorting
            ? this.state.defaultSorting
            : ["id", "asc"]
        }
        setSorting={this.setSorting}
      />
    );
  };

  render() {
    return this.state.error[0] ? (
      console.log(this.state.error[1])
    ) : (
      <FirestoreCollection
        path={this.props.collectionPath}
        sort={
          this.state.sorting
            ? `${this.state.sorting[0]}:${this.state.sorting[1]}`
            : this.state.defaultSorting
            ? `${this.state.defaultSorting[0]}:${this.state.defaultSorting[1]}`
            : null
        }
      >
        {({ error, isLoading, data }) => {
          if (error) {
            return <Error error={error} />;
          }

          if (isLoading) {
            return <p>loading..</p>;
          }

          if (data.length === 0) {
            return <p>{`No ${this.props.dataName.plural} yet!`}</p>;
          }

          return this.filterData(data);
        }}
      </FirestoreCollection>
    );
  }
}

class ListContainer extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Container className={`dataList`}>
        <ListHeader
          data={this.props.data}
          fields={this.props.fields}
          sorting={this.props.sorting}
          setSorting={this.props.setSorting}
        />
        <ListBody data={this.props.data} fields={this.props.fields} />
      </Container>
    );
  }
}

class ListHeader extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Card className={`listHeader my-2`}>
        <Card.Body className={`py-2 d-flex flex-row flex-wrap listHeader`}>
          {this.props.fields.map((field, index) =>
            !field.hidden ? (
              <ListHeaderField
                fieldname={field.fieldname}
                setSorting={this.props.setSorting}
                label={field.label}
                size={field.size}
                sorting={
                  this.props.sorting[0] === field.fieldname
                    ? this.props.sorting
                    : null
                }
              />
            ) : null
          )}
        </Card.Body>
      </Card>
    );
  }
}

class ListHeaderField extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      size: "m",
    };
  }

  componentDidMount() {
    //set field size class
    if (this.props.size != undefined) {
      this.setState({ size: this.props.size });
    }
    if (this.props.sorting != null) {
    }
  }

  render() {
    return (
      <div className={`p-2 ${`size-` + this.state.size}`}>
        <span
          className="label mr-2"
          onClick={() => this.props.setSorting(this.props.fieldname)}
        >
          {this.props.label}
        </span>
        <span className="sorting">
          {this.props.sorting != null ? (
            this.props.sorting[1] === "asc" ? (
              <FontAwesomeIcon icon={faSortDown} />
            ) : (
              <FontAwesomeIcon icon={faSortUp} />
            )
          ) : null}
        </span>
      </div>
    );
  }
}

class ListBody extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return this.props.data.map((object) => (
      <ListItem object={object} fields={this.props.fields} />
    ));
  }
}

class ListItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      values: {},
    };
  }

  componentDidMount() {}

  render() {
    return (
      <Card className={`listItem my-2`}>
        <Card.Body className={`py-2 d-flex flex-row`}>
          {this.props.fields.map((field, index) => {
            let fieldName = field["fieldname"];
            if (!field.hidden) {
              return (
                <ListItemField
                  data={this.props.object[fieldName]}
                  type={field.type}
                  values={field.values}
                  size={field.size}
                />
              );
            } else {
              return null;
            }
          })}
        </Card.Body>
      </Card>
    );
  }
}

class ListItemField extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      size: "m",
      value: "",
    };
  }

  componentDidMount() {
    //set field size class
    if (this.props.size != undefined) {
      this.setState({ size: this.props.size });
    }

    this.processValueType(this.props.data, this.props.type, this.props.values);
  }

  processValueType = (data, type, values) => {
    let resultString;

    switch (type) {
      case `string`: {
        resultString = data.toString();
        break;
      }
      case `number`: {
        resultString = data.toString();
        break;
      }
      case `bool`: {
        resultString = data.toString();
        break;
      }
      case `array`: {
        resultString = data.toString();
        break;
      }
      case `map`: {
        resultString = "";
        values.map((value) => (resultString += `${data[value].toString()} `));
        break;
      }
      case `timestamp`: {
        resultString = new Date(data.seconds * 1000).toLocaleDateString();
        break;
      }
      default: {
        resultString = data.toString();
        break;
      }
    }

    this.setState({
      value: resultString,
    });
  };

  render() {
    return (
      <div className={`listItemField p-2 ${`size-` + this.state.size}`}>
        {this.state.value}
      </div>
    );
  }
}

class ListFooter extends React.Component {}

export default DataList;
