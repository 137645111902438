import React from "react";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus, faCheck } from "@fortawesome/free-solid-svg-icons";

class ProductSupplement extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      counter: 0,
      sumPrice: 0,
      singleItems: [],
    };
  }

  singleItemUpdate = (supplementId, supplementPrice) => {
    let existingItemIndex = this.state.singleItems.findIndex(
      (item) => item == supplementId
    );
    let count;
    let price;
    let existingItems;

    let btn = document.getElementById("btn-" + supplementId);

    if (existingItemIndex >= 0) {
      count = this.state.counter - 1;
      price = Number(supplementPrice) * -1;
      existingItems = this.state.singleItems;
      existingItems.splice(existingItemIndex, 1);
      btn.classList.remove("active");
    } else if (existingItemIndex === -1) {
      count = this.state.counter + 1;
      price = Number(supplementPrice);
      existingItems = this.state.singleItems;
      existingItems.push(supplementId);
      btn.classList.add("active");
    }
    this.setState({
      counter: count,
      sumPrice: price,
      singleItems: existingItems,
    });
    this.props.updateCount(supplementId, count, price);
  };

  addCount = (supplementId, supplementPrice) => {
    const count = this.state.counter + 1;
    const price = Number(supplementPrice);
    this.setState({ counter: count, sumPrice: price });
    this.props.updateCount(supplementId, count, price);
  };

  minusCount = (supplementId, supplementPrice) => {
    const count = this.state.counter - 1;
    const price = Number(supplementPrice) * -1;
    if (count >= 0) {
      this.setState({ counter: count, sumPrice: price });
      this.props.updateCount(supplementId, count, price);
    }
  };

  render() {
    return (
      <Card className="public-webshop-modal-supplement">
        <Card.Body>
          <Row>
            <Col md={9} xs={8}>
              <Row>
                <Col md={8} xs={8}>
                  {this.props.supplement.name}
                </Col>
                <Col md={4} xs={4} className="text-right">
                  <div className="public-webshop-modal-price">
                    €{" "}
                    {(
                      Math.round(this.props.supplement.price * 100) / 100
                    ).toFixed(2)}
                  </div>
                </Col>
              </Row>
            </Col>

            <Col md={3} xs={4} className="text-right">
              {this.props.supplement.multiple ? (
                <>
                  <Button
                    className="public-webshop-modal-supplement-btn"
                    variant="outline-danger"
                    size="sm"
                    onClick={() =>
                      this.minusCount(
                        this.props.supplement.id,
                        this.props.supplement.price
                      )
                    }
                  >
                    <FontAwesomeIcon icon={faMinus} />
                  </Button>
                  <span className="px-2">{this.state.counter}</span>
                  <Button
                    className="public-webshop-modal-supplement-btn"
                    variant="outline-success"
                    size="sm"
                    onClick={() =>
                      this.addCount(
                        this.props.supplement.id,
                        this.props.supplement.price
                      )
                    }
                  >
                    <FontAwesomeIcon icon={faPlus} />
                  </Button>
                </>
              ) : (
                <Button
                  className="public-webshop-modal-supplement-checkbox"
                  variant="outline-success"
                  id={"btn-" + this.props.supplement.id}
                  size="sm"
                  onClick={() =>
                    this.singleItemUpdate(
                      this.props.supplement.id,
                      this.props.supplement.price
                    )
                  }
                >
                  <FontAwesomeIcon icon={faCheck} />
                </Button>
              )}
            </Col>
          </Row>
        </Card.Body>
      </Card>
    );
  }
}

export default ProductSupplement;
