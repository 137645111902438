import React from "react";
import FirebaseAuth from "../misc/FirebaseAuth";
import logIn from "../../actions/logIn";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGoogle } from "@fortawesome/free-brands-svg-icons";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

import Error from "../misc/Error";

const Login = () => {
  return (
    <FirebaseAuth>
      {({ isLoading, error, auth }) => {
        if (isLoading) {
          return (
            <div id="admin">
              <Container className="admin-content">
                <Row>
                  <Col md={12} className="text-center mt-5">
                    <FontAwesomeIcon size="6x" icon={faSpinner} spin />
                  </Col>
                </Row>
              </Container>
            </div>
          );
        }

        if (error) {
          return <Error error={error} />;
        }

        if (!auth) {
          return (
            <div id="admin">
              <Container className="admin-content">
                <Row>
                  <Col md={12} className="text-center">
                    <Button onClick={logIn}>
                      <FontAwesomeIcon icon={faGoogle} /> Log in with Google
                    </Button>
                  </Col>
                </Row>
              </Container>
            </div>
          );
        }

        return (
          <div id="admin">
            <Container className="admin-content">
              <Row>
                <Col md={12} className="text-center">
                  already logged in..
                </Col>
              </Row>
            </Container>
          </div>
        );
      }}
    </FirebaseAuth>
  );
};

export default Login;
