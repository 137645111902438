import Firebase from "firebase/app";
import ReactGA from "react-ga";

const setActivation = (module, value) => {
  ReactGA.event({
    category: module,
    action: `Publish ${module}`,
  });

  var updateValue = {};
  updateValue[module] = value;

  return Firebase.firestore()
    .collection("config")
    .doc("public")
    .update(
      //`${module}` : value,
      { webshop: value }
      //updateValue
    )
    .catch((error) => {
      alert(`Whoops, couldn't publish the webshop: ${error.message}`);
    });
};

export default setActivation;
