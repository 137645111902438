import React from "react";
import { FirestoreCollection } from "react-firestore";

import { connect } from "react-redux";
import { removeFromCart } from "../../../actions/shoppingCartActions";

import { LinkContainer } from "react-router-bootstrap";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faTrash } from "@fortawesome/free-solid-svg-icons";

import Error from "../../../views/misc/Error";
import Container from "react-bootstrap/Container";

class ShoppingCartDialog extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Modal
        scrollable={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName="public-webshop-cart-modal"
        show={this.props.ShoppingCartDialog}
        onHide={this.props.hideCart}
      >
        <Modal.Header>
          <Container>
            <Row>
              <Col>
                <Modal.Title className="public-webshop-modal-title">
                  Jouw bestelling
                </Modal.Title>
              </Col>
              <Col className="text-right pr-0">
                <Button variant="light" onClick={() => this.props.hideCart()}>
                  <FontAwesomeIcon icon={faTimes} />
                </Button>
              </Col>
            </Row>
          </Container>
        </Modal.Header>
        <Modal.Body className="public-webshop-cart-modal-body overflow-auto">
          <FirestoreCollection path={"webshop_products"}>
            {({ error, isLoading, data }) => {
              if (error) {
                return <Error error={error} />;
              }

              if (isLoading) {
                return <p>loading...</p>;
              }

              if (data.length === 0) {
                return <p>No products yet!</p>;
              }

              return (
                <OrderOverview
                  products={data}
                  selectedItems={this.props.selectedItems}
                  remove={this.props.removeFromCart}
                />
              );
            }}
          </FirestoreCollection>
        </Modal.Body>
        <Modal.Footer>
          <span class="align-middle font-weight-bold mr-3">
            {this.props.totalItems > 0
              ? "Totaal €" + this.props.totalPrice.toFixed(2)
              : "Geen items in winkelmand"}
          </span>
          {this.props.totalItems > 0 ? (
            <LinkContainer to={"/checkout"}>
              <Button onClick={this.props.hideCart}>Bestellen</Button>
            </LinkContainer>
          ) : null}
        </Modal.Footer>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    selectedItems: state.shoppingCart.selectedItems,
    totalItems: state.shoppingCart.totalItems,
    totalPrice: state.shoppingCart.totalPrice,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    removeFromCart: (orderItemId, reducePrice) => {
      dispatch(removeFromCart(orderItemId, reducePrice));
    },
  };
};

class OrderOverview extends React.Component {
  returnOrderItem(item, allSupplements) {
    let allProducts = this.props.products;
    let product = allProducts.find((product) => product.id === item.id);
    let supplements = [];

    if (allSupplements.length > 0) {
      for (let i = 0; i < item.supplements.length; i++) {
        supplements.push(
          allSupplements.find(
            (supplement) => supplement.id === item.supplements[i].id
          )
        );
        supplements[i].count = item.supplements[i].count;
      }
    }

    let orderItemPrice = this.calculateOrderItemPrice(product, supplements);

    return (
      <Row className="mt-5 public-webshop-cart-orderview-prodcuct">
        <Col xs={8}>
          <h5>{product.name}</h5>
          {supplements.length > 0
            ? supplements.map((supplement) => (
                <Container>
                  <Row className="mt-2 public-webshop-cart-orderview-supplement">
                    <Col xs="auto">{supplement.count + " x"}</Col>
                    <Col>
                      {supplement.name}
                      <div className="public-webshop-modal-price ml-3">
                        €{" "}
                        {(Math.round(supplement.price * 100) / 100).toFixed(2)}
                      </div>
                    </Col>
                  </Row>
                </Container>
              ))
            : null}
        </Col>
        <Col>
          <Row>
            <Col xs={8} className="px-0">
              <h5 className="text-right font-weight-bold">
                {"€ " + orderItemPrice.toFixed(2)}
              </h5>
            </Col>
            <Col xs={4} className="text-right pr-0">
              <Button
                size="sm"
                variant="light"
                onClick={() =>
                  this.props.remove(item.orderItemId, orderItemPrice.toFixed(2))
                }
              >
                <FontAwesomeIcon icon={faTrash} />
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }

  calculateOrderItemPrice(product, supplements) {
    let price = Number(product.price);

    if (supplements.length > 0) {
      for (let i = 0; i < supplements.length; i++) {
        price += Number(supplements[i].price) * Number(supplements[i].count);
      }
    }

    return price;
  }

  render() {
    return (
      <FirestoreCollection path={"webshop_supplements"}>
        {({ error, isLoading, data }) => {
          if (error) {
            return <Error error={error} />;
          }

          if (isLoading) {
            return <p>loading...</p>;
          }

          return (
            <Container className="mt-3">
              {this.props.selectedItems.map((selecteditem) =>
                this.returnOrderItem(selecteditem, data)
              )}
            </Container>
          );
        }}
      </FirestoreCollection>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ShoppingCartDialog);
