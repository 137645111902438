import React from "react";
import { Route, Link } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";

const ProfilePicture = ({ auth }) => (
  <Link to="/admin/account">
    <img
      src={auth.photoURL}
      alt={auth.displayName}
      width="38"
      height="38"
      className="rounded-circle profile-pic"
    />
  </Link>
);

export default ProfilePicture;
