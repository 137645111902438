import React from "react";
import { FirestoreCollection } from "react-firestore";

import PublicWrapper from "../../../components/public/PublicWrapper";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Image1 from "../../../assets/static-webpages/about/floris-nout.jpg";
import Image2 from "../../../assets/static-webpages/about/buiten.jpg";

import Error from "../../misc/Error";

const About = () => (
  <PublicWrapper>
    <div id="about" className="page-background">
      <Container className="website-content">
        <Row className="mt-1 mt-md-5 mr-0">
          <Col md="4" xs="12" className="text-center">
            <img src={Image1} className="image" />
            <img src={Image2} className="image" />
          </Col>
          <Col md="8" xs="12" className="">
            <p>
              Haak lekker aan bij het gezelligste visrestaurant van Amsterdam.
              Een toegankelijk en eigentijds restaurant met de meest
              uiteenlopende visgerecht.
            </p>
            <p>
              Op het menu vind je gecertificeerde en lokaal gevangen vis met
              smaak bereid. overwegend visgerechtjes maar ook vlees en
              vegetarische opties.
            </p>
            <p>
              In combinatie met onze ruime keuze aan cocktails, wijnen en bieren
              en een grote gezellige bar midden in de zaak, maakt dat Visbar
              Beet bij uitstek een visrestaurant in Amsterdam waar je ook voor
              een borrel graag komt.
            </p>

            <p>
              Wil je met een groep komen eten of heb je speciale wensen? Vraag
              dan gerust naar de mogelijkheden!
            </p>

            <p>
              <strong>Vis moet zwemmen!</strong>
            </p>

            <p>
              Bij Visbar Beet hebben wij een mooie selectie van uiteenlopende
              wijnen die perfect samen gaan met onze gerechten. Om onze gasten
              optimaal te kunnen laten genieten van onze mooiste wijnen, hebben
              wij er voor gekozen om de duurdere wijnen met een vaste opslag aan
              te bieden zodat ook deze betaalbaar blijven.
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  </PublicWrapper>
);

export default About;
