// the main file in our front-end app
// create-react-app expects a file in src/index.js and loads everything from here

import Firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import React from "react";
import ReactDOM from "react-dom";
import ReactGA from "react-ga";

import App from "./views/App";

import checkoutReducer from "./components/reducers/checkoutReducer";
import shoppingCartReducer from "./components/reducers/shoppingCartReducer";
//import adminCategoriesReducer from "./components/reducers/adminCategoriesReducer";
import { Provider } from "react-redux";
import { createStore, combineReducers } from "redux";

//import "bootstrap/dist/css/bootstrap.min.css";

console.log("create-react-app env:", process.env.NODE_ENV);
console.log("visbarbeet project:", process.env.REACT_APP_ENV);

// connect our app to firebase
const firbaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE,
};
Firebase.initializeApp(firbaseConfig);

var storage = Firebase.storage();

// Google Analytics
// https://github.com/react-ga/react-ga#api
ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID);

// Sentry
// https://docs.sentry.io/clients/javascript/integrations/react/
window.Raven.config(process.env.REACT_APP_SENTRY_RAVEN_TRACKING_URL).install();

const reducers = combineReducers({
  shoppingCart: shoppingCartReducer,
  checkout: checkoutReducer,
  //adminCategories: adminCategoriesReducer,
});

//Create redux store for the shoppingcart
const store = createStore(reducers);

// render the App component to our document root with React
ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);

export { storage as default };
