import React from "react";

import AdminWrapper from "../../../components/admin/AdminWrapper";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

import { LinkContainer } from "react-router-bootstrap";
import FirebaseAuth from "../../misc/FirebaseAuth";
import Error from "../../misc/Error";
import logIn from "../../../actions/logIn";
import createPage from "../../../actions/pages/createPage";
import PageForm from "./PageForm";

const PageNew = ({ history }) => (
  <AdminWrapper>
    <Row className="admin-page-header">
      <Col md={10} className="admin-page-title">
        <h1>New page</h1>
      </Col>
      <Col md={2} className="admin-action-buttons"></Col>
    </Row>
    <Row>
      <Col md={12}>
        <FirebaseAuth>
          {({ isLoading, error, auth }) => {
            if (error) {
              return <Error error={error} />;
            }

            if (isLoading) {
              return <div>loading...</div>;
            }

            if (!auth) {
              return (
                <div>
                  <p>You must be logged in to add pages</p>
                  <button onClick={logIn}>log in</button>
                </div>
              );
            }

            return (
              <PageForm
                onSubmit={(values) =>
                  createPage(values).then((page) =>
                    history.push(`/admin/pages/`)
                  )
                }
              />
            );
          }}
        </FirebaseAuth>
      </Col>
    </Row>
  </AdminWrapper>
);

export default PageNew;
