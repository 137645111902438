import Firebase from "firebase/app";
import ReactGA from "react-ga";

import { prepareDocForUpdate } from "../helpers/firestoreHelpers";

const updatePage = (pageId, values) => {
  ReactGA.event({
    category: "Page",
    action: "Update page"
  });

  return Firebase.firestore()
    .collection("pages")
    .doc(pageId)
    .update(prepareDocForUpdate(values))
    .catch(error => {
      alert(`Whoops, couldn't edit the page: ${error.message}`);
    });
};

export default updatePage;
