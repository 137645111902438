import Firebase from "firebase/app";
import ReactGA from "react-ga";

import { prepareDocForUpdate } from "../../helpers/firestoreHelpers";

const updateProduct = (productId, values) => {
  ReactGA.event({
    category: "Webshop - Product",
    action: "Update product"
  });

  var category_refence = Firebase.firestore()
    .collection("webshop_categories")
    .doc(values.category);
  values.category = category_refence;

  return Firebase.firestore()
    .collection("webshop_products")
    .doc(productId)
    .update(prepareDocForUpdate(values))
    .catch(error => {
      alert(`Whoops, couldn't edit the product: ${error.message}`);
    });
};

export default updateProduct;
