import React from "react";
import updateGeneralConfig from "../../../actions/config/general/updateGeneralConfig";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Badge from "react-bootstrap/Badge";

import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Emojis } from "../../../components/admin/misc/Emojis";

import Select from "react-select";
import { faThemeisle } from "@fortawesome/free-brands-svg-icons";

class GeneralConfig extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      changes: false,
      changesSaved: false,
      editorState: EditorState.createEmpty(),
      editorHasFocus: false,
      showValidation: false,

      showEmailAddress: null,
      showPhoneNumber: null,
      showAddress: null,

      companyName: this.props.companyName,
      companyDescription: this.props.companyDescription,
      emailAddress: this.props.emailAddress,
      phoneNumber: this.props.phoneNumber,
      address: this.props.address,

      validation: {
        companyNameValue: [true, ""],
        companyDescription: [true, ""],
        emailAddressValue: [true, ""],
        emailAddressLink: [true, ""],
        phoneNumberValue: [true, ""],
        phoneNumberLink: [true, ""],
        addressValue: [true, ""],
        addressLink: [true, ""],
      },
    };
  }

  componentDidMount() {
    if (this.props.companyDescription) {
      this.setState({
        editorState: EditorState.createWithContent(
          convertFromRaw(this.props.companyDescription)
        ),
      });
    }
  }

  checkBoxChanged = (event) => {
    console.log(event.target.name);

    switch (event.target.name) {
      case "showEmailAddress": {
        let newValue = this.state.emailAddress;
        newValue[0] = !newValue[0];
        this.setState({
          emailAddress: newValue,
        });
        break;
      }
      case "showPhoneNumber": {
        let newValue = this.state.phoneNumber;
        newValue[0] = !newValue[0];
        this.setState({
          phoneNumber: newValue,
        });
        break;
      }
      case "showAddress": {
        let newValue = this.state.address;
        newValue[0] = !newValue[0];
        this.setState({
          address: newValue,
        });
        break;
      }
      default:
        break;
    }

    this.setState({
      changes: true,
      changesSaved: false,
    });
  };

  onChange = (event) => {
    this.fieldValitation(
      event.target.name,
      event.target.id,
      event.target.value,
      event.target.value.length
    );

    this.updateValue(event.target.name, event.target.id, event.target.value);

    this.setState({
      changes: true,
      changesSaved: false,
      showValidation: false,
    });
  };

  updateValue(fieldname, fieldID, fieldvalue) {
    const newValue = this.state[fieldname];

    if (fieldname != "companyName") {
      if (fieldID.substr(-4) === "Link") {
        newValue[2] = fieldvalue;
      } else {
        newValue[1] = fieldvalue;
      }
      this.setState({
        [fieldname]: newValue,
      });
    } else {
      this.setState({
        companyName: fieldvalue,
      });
    }
  }

  onEditorChange = (editorState) => {
    this.setState({
      editorState: editorState,
      changes: true,
      showValidation: false,
      companyDescription: convertToRaw(
        this.state.editorState.getCurrentContent()
      ),
    });
  };

  fieldValitation = (fieldname, fieldID, fieldvalue, fieldvalue_length) => {
    const validation = this.state.validation;

    if (
      fieldID === "companyNameValue" ||
      fieldID === "emailAddressValue" ||
      fieldID === "phoneNumberValue" ||
      fieldID === "addressValue"
    ) {
      if (fieldvalue_length <= 3) {
        validation[fieldID] = [
          false,
          "Needs to be at least 3 characters long.",
        ];
      } else if (fieldvalue_length > 48) {
        validation[fieldID] = [
          false,
          "That's too long, keep it under 48 characters..",
        ];
      } else {
        validation[fieldID] = [true, ""];
      }
    } else if (fieldID === "emailAddressLink") {
      if (fieldvalue_length <= 5) {
        validation[fieldID] = [false, `Must be at least 5 characters long.`];
      } else if (fieldvalue_length > 128) {
        validation[fieldID] = [false, `Cannot exceed 128 characters`];
      } else if (
        /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(fieldvalue) === false
      ) {
        validation[fieldID] = [false, `Invalid email address.`];
      } else {
        validation[fieldID] = [true, ""];
      }
    } else if (fieldID === "phoneNumberLink") {
      if (fieldvalue_length <= 5) {
        validation[fieldID] = [false, `Must be at least 5 characters long.`];
      } else if (fieldvalue_length > 128) {
        validation[fieldID] = [false, `Cannot exceed 128 characters`];
      } else if (
        /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$}/.test(fieldvalue) ===
        false
      ) {
        validation[fieldID] = [false, `Invalid phone number.`];
      } else {
        validation[fieldID] = [true, ""];
      }
    } else if (fieldID === "addressLink") {
      if (fieldvalue_length <= 5) {
        validation[fieldID] = [false, `Must be at least 5 characters long.`];
      } else if (fieldvalue_length > 256) {
        validation[fieldID] = [false, `Cannot exceed 256 characters`];
      } else if (
        /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/.test(
          fieldvalue
        ) === false
      ) {
        validation[fieldID] = [false, `Invalid url format.`];
      } else {
        validation[fieldID] = [true, ""];
      }
    }

    this.setState({
      validation: validation,
    });
  };

  validateEditor = () => {
    return new Promise((resolve) => {
      const validation = this.state.validation;
      if (this.state.editorState.getCurrentContent().hasText()) {
        validation.companyDescription = [true, ""];
      } else {
        validation.companyDescription = [
          false,
          "Please provide a value for description",
        ];
      }
      this.setState({
        validation: validation,
      });
      if (
        this.state.validation.companyDescription ===
        validation.companyDescription
      ) {
        resolve();
      }
    });
  };

  validationStatus = () => {
    for (const [key, value] of Object.entries(this.state.validation)) {
      if (value != null) {
        if (value[0] === false) {
          return false;
        }
      }
    }
    return true;
  };

  formValidation = () => {
    return new Promise((resolve) => {
      this.validateEditor().then(() => {
        if (this.validationStatus() === true) {
          resolve(true);
        } else {
          this.setState({
            showValidation: true,
          });
          resolve(false);
        }
      });
    });
  };

  onSubmit = (event) => {
    event.preventDefault();

    this.formValidation().then((isValid) => {
      if (isValid) {
        const values = {
          companyName: this.state.companyName,
          companyDescription: this.state.companyDescription,
          emailAddress: this.state.emailAddress,
          phoneNumber: this.state.phoneNumber,
          address: this.state.address,
        };
        updateGeneralConfig(values).then(() => {
          this.setState({
            changes: false,
            changesSaved: true,
          });
        });
      }
    });
  };

  render() {
    const emojis = Emojis;

    return (
      <Card className="my-3">
        <Card.Body>
          <Row>
            <Col>
              <h5>General</h5>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col>
              <Form onSubmit={this.onSubmit}>
                <Form.Row className="mb-0">
                  <Col xs="12">
                    <Form.Label>Business name</Form.Label>
                  </Col>
                </Form.Row>
                <Form.Row className="mb-3">
                  <Col xs="12">
                    <Form.Control
                      type="text"
                      name="companyName"
                      id="companyNameValue"
                      placeholder="Company name"
                      defaultValue={this.props.companyName}
                      onChange={this.onChange}
                    />
                    <Form.Control.Feedback
                      type="invalid"
                      style={
                        this.state.showValidation ? { display: "inline" } : {}
                      }
                    >
                      {this.state.validation.companyNameValue[0]
                        ? ""
                        : this.state.validation.companyNameValue[1]}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Row>
                <Form.Row className="mb-0">
                  <Col xs="12">
                    <Form.Label>Description</Form.Label>
                  </Col>
                </Form.Row>
                <Form.Row className="mb-5">
                  <Col xs="12">
                    <Editor
                      editorState={this.state.editorState}
                      toolbarClassName="editor-toolbar"
                      wrapperClassName="editor-wrapper"
                      editorClassName={
                        this.state.hasFocus
                          ? "form-control-focus"
                          : "form-control"
                      }
                      toolbar={{
                        options: ["inline", "emoji", "history"],
                        inline: {
                          options: ["bold", "italic", "underline"],
                        },
                        emoji: {
                          emojis: emojis,
                        },
                      }}
                      onEditorStateChange={this.onEditorChange}
                      onFocus={() => this.setState({ editorHasFocus: true })}
                      onBlur={() => this.setState({ editorHasFocus: false })}
                      stripPastedStyles={true}
                    />
                    <Form.Control.Feedback
                      type="invalid"
                      style={
                        this.state.showValidation ? { display: "inline" } : {}
                      }
                    >
                      {this.state.validation.companyDescription[0]
                        ? ""
                        : this.state.validation.companyDescription[1]}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Row>

                <Form.Row className="mb-0">
                  <Col xs="12">
                    <Form.Label>Email address</Form.Label>
                  </Col>
                </Form.Row>
                <Form.Row className="pl-3 mb-3">
                  <Col xs="auto d-flex pt-2">
                    <Form.Check
                      type="checkbox"
                      name="showEmailAddress"
                      defaultChecked={this.state.emailAddress[0]}
                      onChange={this.checkBoxChanged}
                    />
                  </Col>

                  <Col>
                    <InputGroup>
                      <InputGroup.Prepend>
                        <InputGroup.Text id="inputGroupPrepend">
                          Label
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <Form.Control
                        type="text"
                        name="emailAddress"
                        id="emailAddressValue"
                        placeholder="The text to display on the website."
                        disabled={!this.state.emailAddress[0]}
                        defaultValue={this.state.emailAddress[1]}
                        onChange={this.onChange}
                      />

                      <Form.Control.Feedback
                        type="invalid"
                        style={
                          this.state.showValidation ? { display: "inline" } : {}
                        }
                      >
                        {this.state.validation.emailAddressValue[0]
                          ? ""
                          : this.state.validation.emailAddressValue[1]}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Col>
                  <Col>
                    <InputGroup>
                      <InputGroup.Prepend>
                        <InputGroup.Text id="inputGroupPrepend">
                          Link
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <Form.Control
                        type="text"
                        name="emailAddress"
                        id="emailAddressLink"
                        placeholder="The actual email address."
                        disabled={!this.state.emailAddress[0]}
                        defaultValue={this.state.emailAddress[2]}
                        onChange={this.onChange}
                      />
                      <Form.Control.Feedback
                        type="invalid"
                        style={
                          this.state.showValidation ? { display: "inline" } : {}
                        }
                      >
                        {this.state.validation.emailAddressLink[0]
                          ? ""
                          : this.state.validation.emailAddressLink[1]}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Col>
                </Form.Row>

                <Form.Row className="mb-0">
                  <Col xs="12">
                    <Form.Label>Phone number</Form.Label>
                  </Col>
                </Form.Row>
                <Form.Row className="pl-3 mb-3">
                  <Col xs="auto d-flex pt-2">
                    <Form.Check
                      type="checkbox"
                      name="showPhoneNumber"
                      defaultChecked={this.state.phoneNumber[0]}
                      onChange={this.checkBoxChanged}
                    />
                  </Col>

                  <Col>
                    <InputGroup>
                      <InputGroup.Prepend>
                        <InputGroup.Text id="inputGroupPrepend">
                          Label
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <Form.Control
                        type="text"
                        name="phoneNumber"
                        id="phoneNumberValue"
                        placeholder="The text to display on the website."
                        disabled={!this.state.phoneNumber[0]}
                        defaultValue={this.state.phoneNumber[1]}
                        onChange={this.onChange}
                      />
                      <Form.Control.Feedback
                        type="invalid"
                        style={
                          this.state.showValidation ? { display: "inline" } : {}
                        }
                      >
                        {this.state.validation.phoneNumberValue[0]
                          ? ""
                          : this.state.validation.phoneNumberValue[1]}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Col>
                  <Col>
                    <InputGroup>
                      <InputGroup.Prepend>
                        <InputGroup.Text id="inputGroupPrepend">
                          Link
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <Form.Control
                        type="text"
                        name="phoneNumber"
                        id="phoneNumberLink"
                        placeholder="The actual (full) phone number (e.g. +316 1234 5678)."
                        disabled={!this.state.phoneNumber[0]}
                        defaultValue={this.state.phoneNumber[2]}
                        onChange={this.onChange}
                      />
                      <Form.Control.Feedback
                        type="invalid"
                        style={
                          this.state.showValidation ? { display: "inline" } : {}
                        }
                      >
                        {this.state.validation.phoneNumberLink[0]
                          ? ""
                          : this.state.validation.phoneNumberLink[1]}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Col>
                </Form.Row>

                <Form.Row className="mb-0">
                  <Col xs="12">
                    <Form.Label>Address</Form.Label>
                  </Col>
                </Form.Row>

                <Form.Row className="pl-3 mb-3">
                  <Col xs="auto d-flex pt-2">
                    <Form.Check
                      type="checkbox"
                      name="showAddress"
                      defaultChecked={this.state.address[0]}
                      onChange={this.checkBoxChanged}
                    />
                  </Col>

                  <Col>
                    <InputGroup>
                      <InputGroup.Prepend>
                        <InputGroup.Text id="inputGroupPrepend">
                          Label
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <Form.Control
                        type="text"
                        name="address"
                        id="addressValue"
                        placeholder="The text to display on the website."
                        disabled={!this.state.address[0]}
                        defaultValue={this.state.address[1]}
                        onChange={this.onChange}
                      />
                      <Form.Control.Feedback
                        type="invalid"
                        style={
                          this.state.showValidation ? { display: "inline" } : {}
                        }
                      >
                        {this.state.validation.addressValue[0]
                          ? ""
                          : this.state.validation.addressValue[1]}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Col>
                  <Col>
                    <InputGroup>
                      <InputGroup.Prepend>
                        <InputGroup.Text id="inputGroupPrepend">
                          Link
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <Form.Control
                        type="text"
                        name="address"
                        id="addressLink"
                        placeholder="E.g. Google Maps page."
                        disabled={!this.state.address[0]}
                        defaultValue={this.state.address[2]}
                        onChange={this.onChange}
                      />
                      <Form.Control.Feedback
                        type="invalid"
                        style={
                          this.state.showValidation ? { display: "inline" } : {}
                        }
                      >
                        {this.state.validation.addressLink[0]
                          ? ""
                          : this.state.validation.addressLink[1]}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Col>
                </Form.Row>
                <Form.Row>
                  <Col className="text-right">
                    {this.state.changes ? (
                      <small className="mx-3 text-secondary">
                        Changes not yet saved
                      </small>
                    ) : null}
                    {this.state.changesSaved ? (
                      <small className="mx-3 text-success">
                        Changes saved!
                      </small>
                    ) : null}
                    <Button variant="primary" type="submit">
                      Save
                    </Button>
                  </Col>
                </Form.Row>
              </Form>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    );
  }
}

export default GeneralConfig;
