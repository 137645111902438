import Firebase from "firebase/app";
import ReactGA from "react-ga";
import slugify from "slugify";

import { prepareDocForCreate } from "../../helpers/firestoreHelpers";

const createCategory = async (values) => {
  ReactGA.event({
    category: "Webshop - Categories",
    action: "Create category",
  });

  values.slug = slugify(values.title, { lower: true });

  const categoriesCollection = Firebase.firestore().collection(
    "webshop_categories"
  );

  await categoriesCollection.get().then((querySnapshot) => {
    values.order = querySnapshot.size;
  });

  try {
    await categoriesCollection.add(prepareDocForCreate(values));
    return values;
  } catch (error) {
    alert(`Whoops, couldn't create the page: ${error.message}`);
  }
};

export default createCategory;
