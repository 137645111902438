import React from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { LinkContainer } from "react-router-bootstrap";

import Container from "react-bootstrap/Container";

import Row from "react-bootstrap/Col";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Dropdown from "react-bootstrap/Dropdown";
import Badge from "react-bootstrap/Badge";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationTriangle,
  faBoxOpen,
  faEllipsisH,
  faPencilAlt,
  faTrashAlt,
  faEye,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";

import Error from "../../../misc/Error";

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <Button
    size={`sm`}
    variant={`light`}
    href=""
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
    <FontAwesomeIcon icon={faEllipsisH} />
  </Button>
));

class ProductListItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showDeleteConfirmation: false,
    };
  }

  componentDidMount() {
    this.setState({
      productStatus: this.props.published,
    });
  }

  render() {
    return (
      <>
        <Col xs={"auto"} md={8} className="align-self-center">
          {this.props.name}
        </Col>

        <Col xs={2} md={1} className="align-self-center">
          {this.props.stocked ? (
            this.props.stock < 1 ? (
              <span className="text-danger">
                <FontAwesomeIcon icon={faBoxOpen} /> {this.props.stock}{" "}
                <FontAwesomeIcon icon={faExclamationTriangle} />
              </span>
            ) : (
              <span className="">
                <FontAwesomeIcon icon={faBoxOpen} /> {this.props.stock}
              </span>
            )
          ) : (
            <span className="text-secondary"></span>
          )}
        </Col>

        <Col className="text-right align-self-center">
          <span className="ml-5">
            {this.props.published ? (
              <Badge variant="success">Published</Badge>
            ) : (
              <Badge variant="danger">Hidden</Badge>
            )}
          </span>

          <span className="ml-5">
            <Dropdown className="d-inline">
              <Dropdown.Toggle
                as={CustomToggle}
                id="dropdown-custom-components"
              ></Dropdown.Toggle>
              <Dropdown.Menu>
                <LinkContainer
                  to={`/admin/webshop/products/${this.props.slug}/edit`}
                >
                  <Dropdown.Item>
                    <FontAwesomeIcon icon={faPencilAlt} /> Edit
                  </Dropdown.Item>
                </LinkContainer>

                <Dropdown.Item onClick={this.props.changeStatus}>
                  {this.props.published ? (
                    <span>
                      <FontAwesomeIcon icon={faEyeSlash} /> Hide
                    </span>
                  ) : (
                    <span>
                      <FontAwesomeIcon icon={faEye} /> Publish
                    </span>
                  )}
                </Dropdown.Item>

                <Dropdown.Item onClick={this.props.showDeleteModal}>
                  <FontAwesomeIcon icon={faTrashAlt} /> Delete
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </span>
        </Col>
      </>
    );
  }
}

export default ProductListItem;
