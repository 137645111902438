import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import updateProductsOrder from "../../../../actions/webshop/product/updateProductsOrder";
import updateProductStatus from "../../../../actions/webshop/product/updateProductStatus";

import DeleteConfirmationModal from "../../../../components/admin/DeleteConfirmationModal";
import deleteProduct from "../../../../actions/webshop/product/deleteProduct";

import ProductListItem from "./ProductListItem";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import Fade from "react-bootstrap/Fade";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGripLines, faSpinner } from "@fortawesome/free-solid-svg-icons";

class ProductList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      productsOrder: [],
      newOrder: null,
      orderChanged: false,
      savingChanges: false,
      alert: [false, "info", "", true],
      toBeDeletedProduct: null,
    };
  }

  componentDidMount() {
    const productsOrder = [];

    this.props.products.forEach((product) => {
      let res = {};
      res.id = product.id;
      res.name = product.name;
      res.published = product.published;
      res.stocked = product.stocked;
      res.stock = product.stock;
      res.order = product.order;
      res.slug = product.slug;
      res.categoryTitle = product.categoryTitle;
      productsOrder.push(res);
    });

    this.setState({
      productsOrder: productsOrder,
      showDeleteConfirmation: false,
    });
  }

  onDragEnd = (result) => {
    const { destination, source, draggableId } = result;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const productsNewOrder = Array.from(this.state.productsOrder);

    let updatedProduct = this.state.productsOrder.find(
      (product) => product.id === draggableId
    );

    productsNewOrder.splice(source.index, 1);
    productsNewOrder.splice(destination.index, 0, updatedProduct);

    productsNewOrder.forEach((product, index) => (product.order = index));

    this.setState({
      productsOrder: productsNewOrder,
      orderChanged: true,
    });
  };

  saveOrderChanges = () => {
    this.setState({
      savingChanges: true,
    });

    updateProductsOrder(this.state.productsOrder).then((result) => {
      this.setState({
        savingChanges: false,
        orderChanged: false,
        alert: [true, "success", result, true],
      });

      setTimeout(() => {
        this.hideAlert();
      }, 2000);
    });
  };

  changeStatus = (id, newStatus) => {
    updateProductStatus(id, {
      published: newStatus,
    });

    const products = this.state.productsOrder;

    products[
      products.findIndex((product) => product.id === id)
    ].published = newStatus;

    this.setState({
      productsOrder: products,
      alert: [true, "success", "Product status was updated.", true],
    });

    setTimeout(() => {
      this.hideAlert();
    }, 2000);
  };

  hideAlert = () => {
    this.setState({
      alert: [false, "info", "", true],
    });
  };

  showDeleteConfirmation = (id, name) => {
    this.setState({
      showDeleteConfirmation: true,
      toBeDeletedProduct: [id, name],
    });
  };

  hideDeleteConfirmation = () => {
    this.setState({
      showDeleteConfirmation: false,
      toBeDeletedProduct: null,
    });
  };

  handeleDeleteProduct = (toBeDeletedProductId) => {
    const products = this.state.productsOrder;

    products.splice(
      products.findIndex((product) => product.id === toBeDeletedProductId),
      1
    );

    this.setState({
      alert: [true, "success", "Product was deleted", true],
    });

    setTimeout(() => {
      this.hideAlert();
    }, 2000);

    this.hideDeleteConfirmation();
  };

  render() {
    return (
      <Container className="mt-3">
        <div className="w-100 d-flex justify-content-center">
          <Fade in={this.state.alert[0]} timeout={2000} unmountOnExit>
            <Alert
              className="admin-alert"
              show={this.state.alert[0]}
              variant={this.state.alert[1]}
              dismissible={this.state.alert[3]}
              onClose={() => this.hideAlert()}
            >
              {this.state.alert[2]}
            </Alert>
          </Fade>
        </div>
        <Row>
          <Col></Col>
          <Col className="text-right">
            <Button
              className={`list-save-btn`}
              variant={!this.state.orderChanged ? `light` : `success`}
              disabled={!this.state.orderChanged || this.state.savingChanges}
              onClick={() => this.saveOrderChanges()}
            >
              {this.state.savingChanges ? (
                <FontAwesomeIcon icon={faSpinner} spin />
              ) : (
                `Save`
              )}
            </Button>
          </Col>
        </Row>

        <Row>
          {this.state.savingChanges ? (
            <Col className="text-center">
              <p>Saving..</p>
            </Col>
          ) : (
            <Col>
              <DragDropContext onDragEnd={this.onDragEnd}>
                <Droppable
                  droppableId={`product-list-${this.props.category.toLowerCase()}`}
                >
                  {(provided, snapshot) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      {this.state.productsOrder.map((product, index) => (
                        <Draggable
                          key={product.id}
                          draggableId={product.id}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <div
                              index={index}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                            >
                              <Card
                                className={`admin-list-item my-2 ${
                                  snapshot.isDragging ? `dragging` : ``
                                }`}
                              >
                                <Card.Body>
                                  <Row>
                                    <Col
                                      className="list-grip align-self-center"
                                      md={"auto"}
                                      {...provided.dragHandleProps}
                                    >
                                      <FontAwesomeIcon
                                        icon={faGripLines}
                                        className="list-grip-icon"
                                      />
                                    </Col>
                                    <ProductListItem
                                      id={product.id}
                                      name={product.name}
                                      category={product.categoryTitle}
                                      stocked={product.stocked}
                                      stock={product.stock}
                                      changeStatus={() =>
                                        this.changeStatus(
                                          product.id,
                                          !product.published
                                        )
                                      }
                                      published={product.published}
                                      slug={product.slug}
                                      showDeleteModal={() =>
                                        this.showDeleteConfirmation(
                                          product.id,
                                          product.name
                                        )
                                      }
                                    />
                                  </Row>
                                </Card.Body>
                              </Card>
                            </div>
                          )}
                        </Draggable>
                      ))}

                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </Col>
          )}
        </Row>

        <DeleteConfirmationModal
          object={`product`}
          name={
            this.state.toBeDeletedProduct !== null
              ? this.state.toBeDeletedProduct[1]
              : null
          } // get title from state
          hide={() => this.hideDeleteConfirmation()}
          show={this.state.showDeleteConfirmation}
          delete={
            () =>
              deleteProduct(
                this.state.toBeDeletedProduct !== null
                  ? this.state.toBeDeletedProduct[0]
                  : null
              ).then(
                this.handeleDeleteProduct(this.state.toBeDeletedProduct[0])
              ) // get the id from the state as well
          }
        />
      </Container>
    );
  }
}

export default ProductList;
