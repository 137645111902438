import React from "react";
import { FirestoreCollection } from "react-firestore";

import { LinkContainer } from "react-router-bootstrap";

import WebshopPageContainer from "../../../../components/admin/webshop/WebshopPageContainer";
import ProductList from "./ProductList";

import Table from "react-bootstrap/Table";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";

import Error from "../../../misc/Error";

function compareValues(key, order = "asc") {
  // nice function that allows sorting of arrays of objects, either text or number based and ascending or descending results.
  // source: https://www.sitepoint.com/sort-an-array-of-objects-in-javascript/#:~:text=Basic%20Array%20Sorting,in%20Unicode%20code%20point%20order.
  return function innerSort(a, b) {
    if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
      // property doesn't exist on either object
      return 0;
    }

    const varA = typeof a[key] === "string" ? a[key].toUpperCase() : a[key];
    const varB = typeof b[key] === "string" ? b[key].toUpperCase() : b[key];

    let comparison = 0;
    if (varA > varB) {
      comparison = 1;
    } else if (varA < varB) {
      comparison = -1;
    }
    return order === "desc" ? comparison * -1 : comparison;
  };
}

const actionButtons = (
  <LinkContainer to="products/new">
    <Button>New Product</Button>
  </LinkContainer>
);

const ProductCategoriesList = () => (
  <WebshopPageContainer title={`Products`} buttons={actionButtons}>
    <FirestoreCollection path={"webshop_categories"} sort={"order"}>
      {({ error, isLoading, data }) => {
        if (error) {
          return <Error error={error} />;
        }

        if (isLoading) {
          return <p>loading...</p>;
        }

        if (data.length === 0) {
          return <p>No categories yet!</p>;
        }

        return (
          <Col md={12}>
            <Tabs
              defaultActiveKey={data[0].id}
              id="uncontrolled-tab-example"
              className="admin-tabs"
            >
              {data.map((category) => (
                <Tab
                  key={category.id}
                  eventKey={category.id}
                  title={category.title}
                >
                  <FirestoreCollection
                    path={"webshop_products"}
                    filter={["categoryTitle", "==", category.title]}
                  >
                    {({ error, isLoading, data }) => {
                      if (error) {
                        return <Error error={error} />;
                      }

                      if (isLoading) {
                        return <p>loading...</p>;
                      }

                      if (data.length === 0) {
                        return <p>No products yet!</p>;
                      }

                      return (
                        <ProductList
                          products={data.sort(compareValues("order"))}
                          category={category.title}
                        />
                      );
                    }}
                  </FirestoreCollection>
                </Tab>
              ))}
            </Tabs>
          </Col>
        );
      }}
    </FirestoreCollection>
  </WebshopPageContainer>
);

export default ProductCategoriesList;
