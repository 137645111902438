import React from "react";
import { FirestoreCollection } from "react-firestore";

import Error from "../../../views/misc/Error";

import { connect } from "react-redux";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Spinner from "react-bootstrap/Spinner";
import ListGroup from "react-bootstrap/ListGroup";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faTrash } from "@fortawesome/free-solid-svg-icons";

class OrderList extends React.Component {
  constructor(props) {
    super(props);
  }

  calcTotalPrice = (delivery) => {
    if (delivery) {
      return this.props.totalPrice + 5;
    } else {
      return this.props.totalPrice;
    }
  };

  render() {
    return (
      <Col md={4} className="mb-3">
        <Card className="public-checkout-orderlist">
          <Card.Header className="font-weight-bold">
            Jouw bestelling
          </Card.Header>
          <Card.Body className="p-0">
            <FirestoreCollection path={"webshop_products"}>
              {({ error, isLoading, data }) => {
                if (error) {
                  return <Error error={error} />;
                }

                if (isLoading) {
                  return (
                    <div className="loading-container">
                      <Spinner animation="border" className="loading-icon" />
                    </div>
                  );
                }

                if (data.length === 0) {
                  return <p>Yikes! Something went wrong. Please reload..</p>;
                }

                return (
                  <OrderOverviewList
                    products={data}
                    selectedItems={this.props.selectedItems}
                  />
                );
              }}
            </FirestoreCollection>
          </Card.Body>
          <Card.Footer className="sumTotal py-3">
            {this.props.delivery ? (
              <Row>
                <Col>
                  <Row className="ml-1">Bezorgkosten</Row>
                </Col>
                <Col md={4} className="text-right price">
                  {`€5.00`}
                </Col>
              </Row>
            ) : null}

            <Row>
              <Col>
                <Row className="ml-1">Totaal</Row>
              </Col>
              <Col md={4} className="text-right price">
                {`€${this.calcTotalPrice(this.props.delivery).toFixed(2)}`}
              </Col>
            </Row>
          </Card.Footer>
        </Card>
      </Col>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    selectedItems: state.shoppingCart.selectedItems,
    totalPrice: state.shoppingCart.totalPrice,
    delivery: state.checkout.delivery,
  };
};

const mapDispatchToProps = (dispatch) => {};

class OrderOverviewList extends React.Component {
  returnOrderItem(item, allSupplements) {
    let allProducts = this.props.products;
    let product = allProducts.find((product) => product.id === item.id);
    let supplements = [];

    for (let i = 0; i < item.supplements.length; i++) {
      supplements.push(
        allSupplements.find(
          (supplement) => supplement.id === item.supplements[i].id
        )
      );
      supplements[i].count = item.supplements[i].count;
    }

    let orderItemPrice = this.calculateOrderItemPrice(product, supplements);

    return (
      <ListGroup.Item>
        <Row>
          <Col>
            <Row className="ml-1 product">{product.name}</Row>

            {supplements.length > 0
              ? supplements.map((supplement) => (
                  <Row className="ml-3 supplement">{supplement.name}</Row>
                ))
              : null}
          </Col>
          <Col md={4} className="text-right price">
            {`€${orderItemPrice.toFixed(2)}`}
          </Col>
        </Row>
      </ListGroup.Item>
    );
  }

  calculateOrderItemPrice(product, supplements) {
    let price = Number(product.price);

    if (supplements.length > 0) {
      for (let i = 0; i < supplements.length; i++) {
        price += Number(supplements[i].price) * Number(supplements[i].count);
      }
    }

    return price;
  }

  render() {
    return (
      <FirestoreCollection path={"webshop_supplements"}>
        {({ error, isLoading, data }) => {
          if (error) {
            return <Error error={error} />;
          }

          if (isLoading) {
            return <p>loading...</p>;
          }

          return (
            <ListGroup variant="flush">
              {this.props.selectedItems.length > 0 ? (
                this.props.selectedItems.map((selecteditem) =>
                  this.returnOrderItem(selecteditem, data)
                )
              ) : (
                <ListGroup.Item>
                  Nog geen producten in je bestelling..
                </ListGroup.Item>
              )}
            </ListGroup>
          );
        }}
      </FirestoreCollection>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderList);
