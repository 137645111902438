import React from "react";
import { FirestoreCollection } from "react-firestore";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Error from "../../../misc/Error";

class CategoryForm extends React.Component {
  state = {
    title_valid: [true, ""],
    changes_made: false,
    show_validation: false,
    saving: false,
  };

  componentDidMount() {
    if (this.props.context === "create") {
      this.setState({
        title_valid: [false, "Please provide a title"],
      });
    }
    this.setState({
      saving: false,
    });
  }

  onChange = (event) => {
    this.fieldValitation(event.target.name, event.target.value.length);
    this.setState({ changes_made: true, show_validation: false });
  };

  fieldValitation = (fieldname, fieldvalue_length) => {
    switch (fieldname) {
      case "title":
        if (fieldvalue_length < 2) {
          this.setState({
            title_valid: [
              false,
              "Title needs to be at least 2 characters long.",
            ],
          });
          break;
        } else if (fieldvalue_length > 24) {
          this.setState({
            title_valid: [
              false,
              "That's too long for a title, keep it under 24 characters..",
            ],
          });
          break;
        } else {
          this.setState({
            title_valid: [true, ""],
          });
          break;
        }
      default:
        break;
    }
  };

  formValidation = () => {
    if (this.state.title_valid[0] == false) {
      return false;
    } else {
      return true;
    }
  };

  onSubmit = (event) => {
    event.preventDefault();

    if (this.formValidation()) {
      this.setState({
        saving: true,
      });
      const { title } = event.target.elements;
      const values = {
        title: title.value,
      };
      this.props.onSubmit(values);
    } else {
      this.setState({ show_validation: true });
    }
  };

  render() {
    return (
      <Form onSubmit={this.onSubmit}>
        <Container className="admin-form-container">
          <Form.Group controlId="pageTitle">
            <Form.Label>Category title</Form.Label>
            <Form.Control
              name="title"
              onChange={this.onChange}
              defaultValue={
                this.props.category ? this.props.category.title : null
              }
            />
            <Form.Control.Feedback
              type="invalid"
              style={this.state.show_validation ? { display: "inline" } : {}}
            >
              {this.state.title_valid[0] ? "" : this.state.title_valid[1]}
            </Form.Control.Feedback>
          </Form.Group>
          <Button
            variant="primary"
            type="submit"
            disabled={this.state.saving ? true : false}
          >
            {this.state.saving ? (
              <span>
                <FontAwesomeIcon className="mr-3" icon={faSpinner} spin />
                Saving
              </span>
            ) : (
              `Save`
            )}
          </Button>
        </Container>
      </Form>
    );
  }
}

export default CategoryForm;
