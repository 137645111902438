import React from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Button from "react-bootstrap/Button";

import logIn from "../../actions/logIn";
import FirebaseAuth from "../misc/FirebaseAuth";
import Error from "../misc/Error";
import Profile from "./Profile";
import ProfilePicture from "./ProfilePicture";

const Account = () => (
  <div id="admin">
    <Container className="admin-content">
      <Row>
        <Col md={12}>
          <FirebaseAuth>
            {({ isLoading, error, auth }) => {
              if (isLoading) {
                return <p>loading...</p>;
              }

              if (error) {
                return <Error error={error} />;
              }

              if (!auth) {
                return (
                  <div>
                    <p>Log in to see your account</p>
                    <Button onClick={logIn}>Log in</Button>
                  </div>
                );
              }

              return (
                <div>
                  <Profile auth={auth} />
                </div>
              );
            }}
          </FirebaseAuth>
        </Col>
      </Row>
    </Container>
  </div>
);

export default Account;
