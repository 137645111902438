import Firebase from "firebase/app";
import ReactGA from "react-ga";

async function canDelete(supplement_id) {
  const products = await Firebase.firestore()
    .collection("webshop_products")
    .get();

  let supplementUsed = [];

  products.forEach((doc) => {
    let supplements = doc.data().supplements;

    if (supplements === null) {
      supplementUsed.push(false);
    } else {
      if (supplements.length === 0) {
        supplementUsed.push(false);
      } else {
        supplementUsed.push(
          supplements.every((supplement) => supplement.value === supplement_id)
        );
      }
    }
  });

  const result = supplementUsed.every((supplement) => supplement === false);
  console.log("final!", result);
  return result;
}

const deleteSupplement = (supplement_id) => {
  ReactGA.event({
    supplement: "Webshop - Supplements",
    action: "Delete supplement",
  });

  //should return a promise..

  return new Promise((resolve, reject) => {
    canDelete(supplement_id).then((result) => {
      if (result) {
        Firebase.firestore()
          .collection("webshop_supplements")
          .doc(supplement_id)
          .delete()
          .then((res) => {
            resolve("Supplement was deleted.");
          })
          .catch((error) => {
            console.log(error);
            reject("Oops! Supplement was not deleted, please try again.");
          });
      } else {
        reject("Cannot delete supplement that is attached to product.");
      }
    });
  });
};

export default deleteSupplement;
