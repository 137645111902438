import Firebase from "firebase/app";
import ReactGA from "react-ga";

const updateGeneralConfig = (values) => {
  ReactGA.event({
    category: `Config`,
    action: `Update general config`,
  });

  return Firebase.firestore()
    .collection("config")
    .doc("general")
    .update(values)
    .catch((error) => {
      alert(`Whoops, couldn't update the config: ${error.message}`);
    });
};

export default updateGeneralConfig;
