import React from "react";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

class CheckoutAddressForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      shippingAddress: false,
      showValidation: false,
      firstname: null,
      lastname: null,
      email: null,
      phone: null,
      invoiceStreet: null,
      invoiceHouseNumber: null,
      invoiceHouseNumberSupplement: null,
      invoicePostcode: null,
      invoiceCity: null,
      shippingStreet: null,
      shippingHouseNumber: null,
      shippingHouseNumberSupplement: null,
      shippingPostcode: null,
      shippingCity: null,
    };
  }

  componentDidMount() {
    if (this.props.client || this.props.invoiceAddress) {
      if (this.props.client) {
        this.setState({
          firstname: [this.props.client.firstname, true, ""],
          lastname: [this.props.client.lastname, true, ""],
          email: [this.props.client.email, true, ""],
          phone: [this.props.client.phone, true, ""],
        });
      }
      if (this.props.invoiceAddress) {
        this.setState({
          invoicePostcode: [this.props.invoiceAddress.postcode, true, ""],
          invoiceHouseNumber: [this.props.invoiceAddress.number, true, ""],
          invoiceHouseNumberSupplement: [
            this.props.invoiceAddress.numberAddon,
            true,
            "",
          ],
        });
      }
      if (this.props.shippingAddress) {
        this.setState({
          shippingPostcode: [this.props.shippingAddress.postcode, true, ""],
          shippingHouseNumber: [this.props.shippingAddress.number, true, ""],
          shippingHouseNumberSupplement: [
            this.props.shippingAddress.numberAddon,
            true,
            "",
          ],
        });
      }
      if (
        this.props.invoiceAddress.postcode !==
          this.props.shippingAddress.postcode ||
        this.props.invoiceAddress.number !== this.props.shippingAddress.number
      ) {
        this.setState({
          shippingAddress: true,
        });
      }
    } else {
      this.setState({
        firstname: ["", false, "Voornaam is verplicht."],
        lastname: ["", false, "Achternaam is verplicht."],
        email: ["", false, "Emailadres is verplicht"],
        phone: ["", false, "Telefoonnummer is verplicht"],
        invoicePostcode: ["", false, "Postcode is verplicht."],
        invoiceHouseNumber: ["", false, "Huisnummer is verplicht."],
        invoiceHouseNumberSupplement: ["", true, ""],
        shippingPostcode: ["", false, "Postcode is verplicht."],
        shippingHouseNumber: ["", false, "Huisnummer is verplicht."],
        shippingHouseNumberSupplement: ["", true, ""],
      });
    }
  }

  // Want to refactor this to 'not' set the state. But just return the address values for street and city in the "Resove()"
  // And then the onSubmit function can update the state with those values.

  getAddresses = () => {
    return new Promise((resolve, reject) => {
      console.log("get Addresses");
      let success = false;
      let invoiceAddress = [];
      let shippingAddress = [];

      if (this.state.shippingAddress) {
        // get the address details for invoice
        this.getFullAddress(
          this.state.invoicePostcode[0],
          this.state.invoiceHouseNumber[0]
        ).then((address) => {
          if (address === false) {
            shippingAddress[0] = false;
          } else if (address.street && address.city) {
            invoiceAddress[0] = address.street;
            invoiceAddress[1] = address.city;
          }
        });
        this.getFullAddress(
          this.state.shippingPostcode[0],
          this.state.shippingHouseNumber[0]
          // now do the same for Shipping address
        )
          .then((address) => {
            if (address === false) {
              shippingAddress[0] = false;
            } else if (address.street && address.city) {
              shippingAddress[0] = address.street;
              shippingAddress[1] = address.city;
              success = true;
            }
          })
          .then(() => {
            resolve({
              invoiceaddress: invoiceAddress,
              shippingaddress: shippingAddress,
            });
          });
        // if only invoiceAddress, run API request only once..
      } else {
        console.log("no shipping address");

        this.getFullAddress(
          this.state.invoicePostcode[0],
          this.state.invoiceHouseNumber[0]
        )
          .then((address) => {
            if (address === false) {
              shippingAddress[0] = false;
              invoiceAddress[0] = false;
            } else if (address.street && address.city) {
              invoiceAddress[0] = address.street;
              invoiceAddress[1] = address.city;
              shippingAddress[0] = address.street;
              shippingAddress[1] = address.city;
              this.setState({
                shippingHouseNumber: this.state.invoiceHouseNumber,
                shippingHouseNumberSupplement: this.state
                  .invoiceHouseNumberSupplement,
                shippingPostcode: this.state.invoicePostcode,
              });
              success = true;
            }
          })
          .then(() => {
            resolve({
              invoiceaddress: invoiceAddress,
              shippingaddress: shippingAddress,
            });
          });
      }
    });
  };

  getFullAddress = (postcode, housenumber) => {
    console.log("get full addres", postcode);
    return new Promise((resolve, reject) => {
      const postcodeApiKey = process.env.REACT_APP_POSTCODE_API_KEY;
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${postcodeApiKey}`);
      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(
        `https://postcode.tech/api/v1/postcode?postcode=${postcode}&number=${housenumber}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result) {
            if (result.message === "No result for this combination.") {
              resolve(false);
            } else {
              resolve({ street: result.street, city: result.city });
            }
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  onChange = (event) => {
    this.validateField(event.target.name, event.target.value);
    this.setState({
      showValidation: false,
    });
  };

  onSubmit = (event) => {
    event.preventDefault();

    console.log("on submit");

    let validForm = this.validateForm();

    if (validForm) {
      this.setState({
        showValidation: false,
      });

      //from here I need to use the new function "GetAddresses" which is a returns a Promise.
      this.getAddresses().then((res) => {
        if (
          res.invoiceaddress[0] === false ||
          res.shippingaddress[0] === false
        ) {
          //invalide invoiceaddress or shippingaddres, return error for that address
          if (res.shippingaddress[0] === false) {
            this.setState({
              shippingPostcode: [
                "",
                false,
                "Geen geldige postcode & huisnummer combinatie",
              ],
            });
          }
          if (res.invoiceaddress[0] === false) {
            this.setState({
              invoicePostcode: [
                "",
                false,
                "Geen geldige postcode & huisnummer combinatie",
              ],
            });
          }
          this.setState({
            showValidation: true,
          });
        } else {
          //both shipping- and shippingaddress have values for street and city and are thus valid.

          if (this.state.showValidation === false) {
            let client = {
              firstname: this.state.firstname[0],
              lastname: this.state.lastname[0],
              email: this.state.email[0],
              phone: this.state.phone[0],
            };
            let invoiceAddress = {
              street: res.invoiceaddress[0],
              number: this.state.invoiceHouseNumber[0],
              numberAddon: this.state.invoiceHouseNumberSupplement[0],
              postcode: this.state.invoicePostcode[0],
              city: res.invoiceaddress[1],
            };
            let shippingAddress = {
              street: res.shippingaddress[0],
              number: this.state.shippingHouseNumber[0],
              numberAddon: this.state.shippingHouseNumberSupplement[0],
              postcode: this.state.shippingPostcode[0],
              city: res.shippingaddress[1],
            };
            this.props.submit(client, invoiceAddress, shippingAddress);
          }
        }
      });
    } else {
      this.setState({
        showValidation: true,
      });
    }
  };

  validateField = (field, value) => {
    let valid = null;
    let validationMessage = "";

    switch (field) {
      case `firstname`: {
        if (value.length <= 1) {
          valid = false;
          validationMessage = `Voornaam moet tenminste 1 karakter zijn.`;
        } else if (value.length > 52) {
          valid = false;
          validationMessage = `Voornaam mag niet langer dan 52 karakters zijn.`;
        } else {
          valid = true;
        }
        this.setState({
          firstname: [value, valid, validationMessage],
        });
        break;
      }
      case `lastname`: {
        if (value.length <= 0) {
          valid = false;
          validationMessage = `Achternaam moet tenminste 1 karakters zijn.`;
        } else if (value.length > 52) {
          valid = false;
          validationMessage = `Achernaam mag niet langer zijn dan 52 karakters`;
        } else {
          valid = true;
        }
        this.setState({
          lastname: [value, valid, validationMessage],
        });
        break;
      }
      case `email`: {
        let confirmEmail = document.getElementById(`validateEmail`).value;

        if (value.length <= 5) {
          valid = false;
          validationMessage = `Email moet tenminste 5 karakters zijn.`;
        } else if (value.length > 128) {
          valid = false;
          validationMessage = `Email mag niet langer zijn dan 128 karakters`;
        } else if (
          /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(
            value
          ) === false
        ) {
          valid = false;
          validationMessage = `Geen valide email adres.`;
        } else if (value !== confirmEmail) {
          valid = false;
          validationMessage = `Email addressen komen niet overeen.`;
        } else {
          valid = true;
        }
        this.setState({
          email: [value, valid, validationMessage],
        });
        break;
      }
      case `phone`: {
        if (value == "") {
          valid = false;
          validationMessage = `Telefoonnummer is verplicht.`;
        } else if (
          /^((\+|00(\s|\s?\-\s?)?)31(\s|\s?\-\s?)?(\(0\)[\-\s]?)?|0)[1-9]((\s|\s?\-\s?)?[0-9])((\s|\s?-\s?)?[0-9])((\s|\s?-\s?)?[0-9])\s?[0-9]\s?[0-9]\s?[0-9]\s?[0-9]\s?[0-9]$/.test(
            value
          ) === false
        ) {
          valid = false;
          validationMessage = `Vul een geldig telefoon nummer in.`;
        } else {
          valid = true;
        }
        this.setState({
          phone: [value, valid, validationMessage],
        });
        break;
      }
      case `invoicePostcode`: {
        //Check if postcode is well formed
        if (/^\d{4}\s?\w{2}$/.test(value) === false) {
          valid = false;
          validationMessage = `Voer een geldige postcode in (b.v. 1000AB).`;
        } else {
          //check if order is going to be of type delivery
          if (this.props.delivery && !this.state.shippingAddress) {
            let code = Number(value.substring(0, 4));

            if (1000 <= code && code <= 1099) {
              valid = true;
              console.log("Postcode = Amsterdam");
            } else {
              /* Delivery in Eindhoven and Nuenen 
            else if (5600 <= code && code <= 5658) {
              valid = true;
              console.log("Postcode = Eindhoven");
            } else if (5670 <= code && code <= 5674) {
              valid = true;
              console.log("Postcode = Nuenen");
            } 
            */
              valid = false;
              //validationMessage = `Alleen bezorging in Amsterdam, Eindhoven en Nuenen.`;
              validationMessage = `Alleen bezorging in Amsterdam.`;
            }
          } else {
            valid = true;
          }
        }

        this.setState({
          invoicePostcode: [value, valid, validationMessage],
        });
        break;
      }
      case `invoiceHouseNumber`: {
        if (value.length < 1) {
          valid = false;
          validationMessage = `Geldig huisnummer nodig.`;
        } else if (isNaN(value) === true) {
          valid = false;
          validationMessage = `Huisnummer kan alleen getal zijn.`;
        } else {
          valid = true;
        }
        this.setState({
          invoiceHouseNumber: [value, valid, validationMessage],
        });
        break;
      }
      case "invoiceHouseNumberSupplement": {
        this.setState({
          invoiceHouseNumberSupplement: [value, true, ""],
        });
      }
      case `shippingPostcode`: {
        if (/^\d{4}\s?\w{2}$/.test(value) === false) {
          valid = false;
          validationMessage = `Voer een geldige postcode in (b.v. 1000AB).`;
        } else {
          let code = Number(value.substring(0, 4));

          if (1000 <= code && code <= 1099) {
            valid = true;
            console.log("Postcode = Amsterdam");
          } else {
            /* Delivery in Eindhoven and Nuenen 
          else if (5600 <= code && code <= 5658) {
            valid = true;
            console.log("Postcode = Eindhoven");
          } else if (5670 <= code && code <= 5674) {
            valid = true;
            console.log("Postcode = Nuenen");
          } 
          */
            valid = false;
            //validationMessage = `Alleen bezorging in Amsterdam, Eindhoven en Nuenen.`;
            validationMessage = `Alleen bezorging in Amsterdam.`;
          }
        }
        this.setState({
          shippingPostcode: [value, valid, validationMessage],
        });
        break;
      }
      case `shippingHouseNumber`: {
        if (value.length < 1) {
          valid = false;
          validationMessage = `Geldig huisnummer nodig.`;
        } else if (isNaN(value) === true) {
          valid = false;
          validationMessage = `Huisnummer kan alleen getal zijn.`;
        } else {
          valid = true;
        }
        this.setState({
          shippingHouseNumber: [value, valid, validationMessage],
        });
        break;
      }

      case "shippingHouseNumberSupplement": {
        this.setState({
          shippingHouseNumberSupplement: [value, true, ""],
        });
      }
    }
  };

  validateForm = () => {
    if (this.state.shippingAddress === false) {
      if (
        this.state.firstname[1] === true &&
        this.state.lastname[1] === true &&
        this.state.email[1] === true &&
        this.state.phone[1] === true &&
        this.state.invoiceHouseNumber[1] === true &&
        this.state.invoicePostcode[1] === true
      ) {
        return true;
      } else return false;
    } else {
      if (
        this.state.firstname[1] === true &&
        this.state.lastname[1] === true &&
        this.state.email[1] === true &&
        this.state.phone[1] === true &&
        this.state.invoiceHouseNumber[1] === true &&
        this.state.invoicePostcode[1] === true &&
        this.state.shippingHouseNumber[1] === true &&
        this.state.shippingPostcode[1] === true
      ) {
        return true;
      } else {
        return false;
      }
    }
  };

  changeShippingAddressState = () => {
    if (this.state.shippingAddress) {
      this.setState({
        shippingAddress: false,
      });
    } else {
      document.getElementsByName("shippingPostcode").value = "";
      document.getElementsByName("shippingHousenumber").value = "";
      document.getElementsByName("shippingHousnumberSupp").value = "";
      this.setState({
        shippingAddress: true,
        shippingPostcode: ["", false, "Postcode is verplicht."],
        shippingHouseNumber: ["", false, "Huisnummer is verplicht."],
        shippingHouseNumberSupplement: ["", true, ""],
      });
    }
  };

  render() {
    return (
      <Form onSubmit={this.onSubmit}>
        <Card.Body>
          <Row>
            <Col md={6}>
              <Form.Group controlId="firstname">
                <Form.Label>Voornaam</Form.Label>
                <Form.Control
                  name="firstname"
                  placeholder="Voornaam"
                  defaultValue={
                    this.props.client ? this.props.client.firstname : null
                  }
                  onChange={this.onChange}
                />
                <Form.Control.Feedback
                  type="invalid"
                  style={this.state.showValidation ? { display: "inline" } : {}}
                >
                  {this.state.firstname ? this.state.firstname[2] : ""}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="lastname">
                <Form.Label>Achternaam</Form.Label>
                <Form.Control
                  name="lastname"
                  placeholder="Achternaam"
                  defaultValue={
                    this.props.client ? this.props.client.lastname : null
                  }
                  onChange={this.onChange}
                />
                <Form.Control.Feedback
                  type="invalid"
                  style={this.state.showValidation ? { display: "inline" } : {}}
                >
                  {this.state.lastname ? this.state.lastname[2] : ""}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group>
                <Form.Label>Email adres</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Email adres"
                  defaultValue={
                    this.props.client ? this.props.client.email : null
                  }
                  id="validateEmail"
                />
                <Form.Control.Feedback
                  type="invalid"
                  style={this.state.showValidation ? { display: "inline" } : {}}
                >
                  {this.state.email ? this.state.email[2] : ""}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="email-confirm">
                <Form.Label>Bevestig email adres</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  placeholder="Email adres"
                  defaultValue={
                    this.props.client ? this.props.client.email : null
                  }
                  onChange={this.onChange}
                />
                <Form.Control.Feedback
                  type="invalid"
                  style={this.state.showValidation ? { display: "inline" } : {}}
                >
                  {this.state.email ? this.state.email[2] : ""}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group controlId="phone">
                <Form.Label>Telefoonnummer</Form.Label>
                <Form.Control
                  name="phone"
                  type="tel"
                  placeholder="Telefoonnummer"
                  defaultValue={
                    this.props.client ? this.props.client.phone : null
                  }
                  onChange={this.onChange}
                />
                <Form.Control.Feedback
                  type="invalid"
                  style={this.state.showValidation ? { display: "inline" } : {}}
                >
                  {this.state.phone ? this.state.phone[2] : ""}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={3} sm={4}>
              <Form.Group controlId="postcode">
                <Form.Label>Postcode</Form.Label>
                <Form.Control
                  name="invoicePostcode"
                  type="text"
                  placeholder="Postcode"
                  defaultValue={
                    this.props.invoiceAddress
                      ? this.props.invoiceAddress.postcode
                      : null
                  }
                  onChange={this.onChange}
                />
                <Form.Control.Feedback
                  type="invalid"
                  style={this.state.showValidation ? { display: "inline" } : {}}
                >
                  {this.state.invoicePostcode
                    ? this.state.invoicePostcode[2]
                    : ""}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={3} sm={4}>
              <Form.Group controlId="streetnumber">
                <Form.Label>Huisnummer</Form.Label>
                <Form.Control
                  name="invoiceHouseNumber"
                  type="text"
                  placeholder="Huisnummer"
                  defaultValue={
                    this.props.invoiceAddress
                      ? this.props.invoiceAddress.number
                      : null
                  }
                  onChange={this.onChange}
                />
                <Form.Control.Feedback
                  type="invalid"
                  style={this.state.showValidation ? { display: "inline" } : {}}
                >
                  {this.state.invoiceHouseNumber
                    ? this.state.invoiceHouseNumber[2]
                    : ""}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={3} sm={4}>
              <Form.Group controlId="streetnumber-addon">
                <Form.Label>
                  Toevoeging
                  <span className="text-muted optional ml-2 align-bottom">
                    Optioneel
                  </span>
                </Form.Label>
                <Form.Control
                  name="invoiceHouseNumberSupplement"
                  type="text"
                  placeholder="Toevoeging"
                  defaultValue={
                    this.props.invoiceAddress
                      ? this.props.invoiceAddress.numberAddon
                      : null
                  }
                  onChange={this.onChange}
                />
                <Form.Control.Feedback
                  type="invalid"
                  style={this.state.showValidation ? { display: "inline" } : {}}
                >
                  {this.state.invoiceHouseNumberSupplement
                    ? this.state.invoiceHouseNumberSupplement[2]
                    : ""}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          {this.props.delivery ? (
            <Row>
              <Col>
                <Form.Group controlId="addressduplicates">
                  <Form.Check
                    type="checkbox"
                    onChange={this.changeShippingAddressState}
                    checked={this.state.shippingAddress}
                    label="Afwijkend bezorgadres"
                  />
                </Form.Group>
              </Col>
            </Row>
          ) : null}
          {this.state.shippingAddress ? (
            <>
              <Row>
                <Col md={3} sm={4}>
                  <Form.Group controlId="delivery-postcode">
                    <Form.Label>Postcode</Form.Label>
                    <Form.Control
                      name="shippingPostcode"
                      type="text"
                      placeholder="Postcode"
                      defaultValue={
                        this.props.shippingAddress
                          ? this.props.shippingAddress.postcode
                          : null
                      }
                      onChange={this.onChange}
                    />

                    <Form.Control.Feedback
                      type="invalid"
                      style={
                        this.state.showValidation ? { display: "inline" } : {}
                      }
                    >
                      {this.state.shippingPostcode
                        ? this.state.shippingPostcode[2]
                        : ""}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={3} sm={4}>
                  <Form.Group controlId="delivery-streetnumber">
                    <Form.Label>Huisnummer</Form.Label>
                    <Form.Control
                      name="shippingHouseNumber"
                      type="text"
                      placeholder="Huisnummer"
                      defaultValue={
                        this.props.shippingAddress
                          ? this.props.shippingAddress.number
                          : null
                      }
                      onChange={this.onChange}
                    />
                    <Form.Control.Feedback
                      type="invalid"
                      style={
                        this.state.showValidation ? { display: "inline" } : {}
                      }
                    >
                      {this.state.shippingHouseNumber
                        ? this.state.shippingHouseNumber[2]
                        : ""}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={3} sm={4}>
                  <Form.Group controlId="delivery-streetnumber-addon">
                    <Form.Label>
                      Toevoeging
                      <span className="text-muted optional ml-2 align-bottom">
                        Optioneel
                      </span>
                    </Form.Label>
                    <Form.Control
                      name="shippingHouseNumberSupplement"
                      type="text"
                      placeholder="Toevoeging"
                      defaultValue={
                        this.props.shippingAddress
                          ? this.props.shippingAddress.numberAddon
                          : null
                      }
                      onChange={this.onChange}
                    />
                    <Form.Control.Feedback
                      type="invalid"
                      style={
                        this.state.showValidation ? { display: "inline" } : {}
                      }
                    >
                      {this.state.shippingHouseNumberSupplement
                        ? this.state.shippingHouseNumberSupplement[2]
                        : ""}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
            </>
          ) : null}
        </Card.Body>
        <Card.Footer className="checkout-footer">
          <Row>
            <Col className="text-left">
              <Button variant="light" onClick={this.props.back}>
                Vorige
              </Button>
            </Col>
            <Col className="text-right">
              <Button type="submit">Volgende</Button>
            </Col>
          </Row>
        </Card.Footer>
      </Form>
    );
  }
}

export default CheckoutAddressForm;
