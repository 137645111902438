import Firebase from "firebase/app";
import {
  FirestoreProvider,
  FirestoreCollection,
  FirestoreDocument,
} from "react-firestore";
import React from "react";
import ReactGA from "react-ga";
import { HashRouter, BrowserRouter, Route } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";

import CookieConsent from "react-cookie-consent";

import ErrorBoundary from "./misc/ErrorBoundary";
import Routes from "./Routes";
import Navigation from "../components/Navigation";

const App = () => (
  <FirestoreProvider firebase={Firebase}>
    <HashRouter>
      <ErrorBoundary>
        <FirestoreDocument path={"config/public"}>
          {({ error, isLoading, data }) => {
            if (isLoading) {
              return null;
            }
            if (error) {
              return null;
            }

            var publicConfig = data;

            return (
              <FirestoreDocument path={"config/admin"}>
                {({ error, isLoading, data }) => {
                  if (isLoading) {
                    return null;
                  }
                  if (error) {
                    return null;
                  }

                  var adminConfig = data;

                  return (
                    <>
                      <Route path='/' component={ScrollToTop} />
                      <Route path='/' component={Analytics} />
                      {/*<Navigation admin={adminConfig} public={publicConfig} /> */}
                      <CookieConsent
                        location='top'
                        cookieName='visbarbeet'
                        buttonText='Akkoord'
                        style={{
                          "text-align": "right",
                          background: "rgba(33, 37, 41, 0.95)",
                          "z-index": "2000",
                        }}
                      >
                        {
                          "Onze website maakt gebruik van cookies. Meer informatie over onze cookies "
                        }
                        <LinkContainer to='cookies'>
                          <a className='text-light '>lees je hier.</a>
                        </LinkContainer>
                      </CookieConsent>
                      <Routes admin={adminConfig} public={publicConfig} />
                    </>
                  );
                }}
              </FirestoreDocument>
            );
          }}
        </FirestoreDocument>
      </ErrorBoundary>
    </HashRouter>
  </FirestoreProvider>
);

// scroll to top on route change
// https://github.com/ReactTraining/react-router/blob/master/packages/react-router-dom/docs/guides/scroll-restoration.md#scroll-to-top
class ScrollToTop extends React.Component {
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0);
    }
  }
  render() {
    return null;
  }
}

// Track Google Analytics page view for every route
// https://github.com/react-ga/react-ga/issues/122#issuecomment-319546248
const Analytics = ({ location }) => {
  const page = location.pathname;
  ReactGA.set({ page });
  ReactGA.pageview(page);
  return null;
};

export default App;
