import React from "react";
import { FirestoreDocument } from "react-firestore";

import WebshopConfig from "./WebshopConfig";
import GeneralConfig from "./GeneralConfig";

import AdminWrapper from "../../../components/admin/AdminWrapper";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";

class ConfigPage extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <AdminWrapper>
        <Row className="admin-page-header">
          <Col md={10} className="admin-page-title">
            <h1>Website configuration</h1>
          </Col>
          <Col md={2} className="admin-action-buttons"></Col>
        </Row>
        <Row>
          <Col md={12}>
            <FirestoreDocument path={"config/general"}>
              {({ error, isLoading, data }) => {
                if (isLoading) {
                  return null;
                }
                if (error) {
                  return null;
                }

                return (
                  <GeneralConfig
                    companyName={data.companyName}
                    companyDescription={data.companyDescription}
                    address={data.address}
                    emailAddress={data.emailAddress}
                    phoneNumber={data.phoneNumber}
                  />
                );
              }}
            </FirestoreDocument>

            <FirestoreDocument path={"config/admin"}>
              {({ error, isLoading, data }) => {
                if (isLoading) {
                  return null;
                }
                if (error) {
                  return null;
                }

                return (
                  <>
                    {data.pages ? (
                      <Card className="my-3">
                        <Card.Body>
                          <h5>Pages</h5>
                          <p className="font-italic">no settings yet..</p>
                        </Card.Body>
                      </Card>
                    ) : null}

                    {data.webshop ? <WebshopConfig /> : null}

                    {data.reservations ? (
                      <Card className="my-3">
                        <Card.Body>
                          <h5>Reservations</h5>
                          <p className="font-italic">no settings yet..</p>
                        </Card.Body>
                      </Card>
                    ) : null}
                  </>
                );
              }}
            </FirestoreDocument>
          </Col>
        </Row>
      </AdminWrapper>
    );
  }
}

export default ConfigPage;
