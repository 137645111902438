import React from "react";
import { FirestoreDocument } from "react-firestore";

import setActivation from "../../../actions/config/webshop/setActivation";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Badge from "react-bootstrap/Badge";

import Select from "react-select";

class WebshopConfig extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      webshopActive: null,
      webshopChanges: false,
      webshopChangesSaved: false,
    };
  }

  websiteActivationChange = (currentStatus) => {
    let newStatus;

    if (this.state.webshopActive == null) {
      newStatus = !currentStatus;
    } else {
      newStatus = !this.state.webshopActive;
    }

    this.setState({
      webshopActive: newStatus,
      webshopChanges: true,
      webshopChangesSaved: false,
    });
  };

  submitWebsiteChanges = () => {
    setActivation("webshop", this.state.webshopActive);
    this.setState({
      webshopChanges: false,
      webshopChangesSaved: true,
    });
  };
  render() {
    return (
      <FirestoreDocument path={"config/public"}>
        {({ error, isLoading, data }) => {
          if (isLoading) {
            return null;
          }
          if (error) {
            return null;
          }
          return (
            <Card className="my-3">
              <Card.Body>
                <Row>
                  <Col>
                    <h5>Webshop</h5>
                  </Col>
                  <Col className="text-right">
                    <Form.Check
                      defaultChecked={
                        this.state.webshopChanges
                          ? this.state.webshopActive
                          : data.webshop
                      }
                      checked={
                        this.state.webshopChanges
                          ? this.state.webshopActive
                          : data.webshop
                      }
                      type="switch"
                      id="webhop-switch"
                      onChange={() =>
                        this.websiteActivationChange(data.webshop)
                      }
                      //Flawed logic below.. doesn't cover it..
                      label={
                        this.state.webshopChanges ? (
                          //If changes to status have been made in UX..
                          this.state.webshopActive ? (
                            <Badge variant="primary">Activate</Badge>
                          ) : (
                            <Badge variant="primary">Deactivate</Badge>
                          )
                        ) : //This is default state, based on data from server. Before changes to status were made.
                        data.webshop ? (
                          <Badge variant="success">Activated</Badge>
                        ) : (
                          <Badge variant="danger">Inactive</Badge>
                        )
                      }
                    />
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col>
                    <Form>
                      <Form.Row className="mb-2">
                        <Col>
                          <h6>Pick up and delivery</h6>
                        </Col>
                      </Form.Row>
                      <Form.Row className="px-2">
                        <Form.Group as={Col} id="pickup-activated">
                          <Form.Check type="checkbox" label="Pick up" />
                        </Form.Group>
                      </Form.Row>
                      <Form.Row className="px-2">
                        <Form.Group as={Col} id="pickup-activated">
                          <Form.Check type="checkbox" label="Delivery" />
                        </Form.Group>
                      </Form.Row>
                    </Form>
                  </Col>
                </Row>
                <Row>
                  <Col className="text-right">
                    {this.state.webshopChanges ? (
                      <small className="mx-3 text-secondary">
                        Changes not yet saved
                      </small>
                    ) : null}
                    {this.state.webshopChangesSaved ? (
                      <small className="mx-3 text-success">
                        Changes saved!
                      </small>
                    ) : null}
                    <Button onClick={() => this.submitWebsiteChanges()}>
                      Save
                    </Button>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          );
        }}
      </FirestoreDocument>
    );
  }
}

export default WebshopConfig;
